/* eslint-disable */
// @ts-ignore
import * as Long from "long";
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export enum TimeSettingType {
  TST_UNKNOWN = 0,
  TST_TIME_SPAN = 1,
  TST_SINCE_RELATIVE_POINT_IN_TIME = 2,
  TST_SINCE_ABSOLUTE_POINT_IN_TIME = 3,
  UNRECOGNIZED = -1,
}

export function timeSettingTypeFromJSON(object: any): TimeSettingType {
  switch (object) {
    case 0:
    case "TST_UNKNOWN":
      return TimeSettingType.TST_UNKNOWN;
    case 1:
    case "TST_TIME_SPAN":
      return TimeSettingType.TST_TIME_SPAN;
    case 2:
    case "TST_SINCE_RELATIVE_POINT_IN_TIME":
      return TimeSettingType.TST_SINCE_RELATIVE_POINT_IN_TIME;
    case 3:
    case "TST_SINCE_ABSOLUTE_POINT_IN_TIME":
      return TimeSettingType.TST_SINCE_ABSOLUTE_POINT_IN_TIME;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TimeSettingType.UNRECOGNIZED;
  }
}

export function timeSettingTypeToJSON(object: TimeSettingType): string {
  switch (object) {
    case TimeSettingType.TST_UNKNOWN:
      return "TST_UNKNOWN";
    case TimeSettingType.TST_TIME_SPAN:
      return "TST_TIME_SPAN";
    case TimeSettingType.TST_SINCE_RELATIVE_POINT_IN_TIME:
      return "TST_SINCE_RELATIVE_POINT_IN_TIME";
    case TimeSettingType.TST_SINCE_ABSOLUTE_POINT_IN_TIME:
      return "TST_SINCE_ABSOLUTE_POINT_IN_TIME";
    case TimeSettingType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface TimeSetting {
  type?: TimeSettingType | undefined;
  span?: DurationContainer | undefined;
  relativePointInTime?: RelativePointInTimeSettings | undefined;
  absolutePointInTime?: number | undefined;
}

export interface DurationContainer {
  days?: number | undefined;
  hours?: number | undefined;
  minutes?: number | undefined;
  seconds?: number | undefined;
}

export interface RelativePointInTimeSettings {
  /** Defines what type of relative point in time we're referring to */
  type?:
    | RelativePointInTimeSettings_RelativePointInTimeType
    | undefined;
  /** For any type, can specify an offset (e.g. -1 for last month, 2 for two months ahead) */
  offset?:
    | number
    | undefined;
  /** For types that have numbered days/periods (e.g. 1st of month, 3rd quarter) */
  number?:
    | number
    | undefined;
  /** Whether to use start or end of the period */
  useStartOfPeriod?: boolean | undefined;
}

export enum RelativePointInTimeSettings_RelativePointInTimeType {
  RPIT_UNKNOWN = 0,
  /**
   * RPIT_DAY_OF_MONTH - Examples using offset and number:
   * - First day of current month: type=DAY_OF_MONTH, number=1, offset=0
   * - Last month's 15th: type=DAY_OF_MONTH, number=15, offset=-1
   */
  RPIT_DAY_OF_MONTH = 1,
  /**
   * RPIT_DAY_OF_WEEK - Examples:
   * - This Monday: type=DAY_OF_WEEK, number=1, offset=0
   * - Last Friday: type=DAY_OF_WEEK, number=5, offset=-1
   */
  RPIT_DAY_OF_WEEK = 2,
  /**
   * RPIT_MONTH - Examples:
   * - Start of current month: type=MONTH, useStartOfPeriod=true, offset=0
   * - End of next month: type=MONTH, useStartOfPeriod=false, offset=1
   */
  RPIT_MONTH = 3,
  /**
   * RPIT_WEEK - Examples:
   * - Start of current week: type=WEEK, useStartOfPeriod=true, offset=0
   * - End of last week: type=WEEK, useStartOfPeriod=false, offset=-1
   */
  RPIT_WEEK = 4,
  /**
   * RPIT_QUARTER - Examples:
   * - Start of Q1: type=QUARTER, number=1, useStartOfPeriod=true
   * - End of last Q4: type=QUARTER, number=4, offset=-1, useStartOfPeriod=false
   */
  RPIT_QUARTER = 5,
  /**
   * RPIT_YEAR - Examples:
   * - Start of current year: type=YEAR, useStartOfPeriod=true, offset=0
   * - End of next year: type=YEAR, useStartOfPeriod=false, offset=1
   */
  RPIT_YEAR = 6,
  UNRECOGNIZED = -1,
}

export function relativePointInTimeSettings_RelativePointInTimeTypeFromJSON(
  object: any,
): RelativePointInTimeSettings_RelativePointInTimeType {
  switch (object) {
    case 0:
    case "RPIT_UNKNOWN":
      return RelativePointInTimeSettings_RelativePointInTimeType.RPIT_UNKNOWN;
    case 1:
    case "RPIT_DAY_OF_MONTH":
      return RelativePointInTimeSettings_RelativePointInTimeType.RPIT_DAY_OF_MONTH;
    case 2:
    case "RPIT_DAY_OF_WEEK":
      return RelativePointInTimeSettings_RelativePointInTimeType.RPIT_DAY_OF_WEEK;
    case 3:
    case "RPIT_MONTH":
      return RelativePointInTimeSettings_RelativePointInTimeType.RPIT_MONTH;
    case 4:
    case "RPIT_WEEK":
      return RelativePointInTimeSettings_RelativePointInTimeType.RPIT_WEEK;
    case 5:
    case "RPIT_QUARTER":
      return RelativePointInTimeSettings_RelativePointInTimeType.RPIT_QUARTER;
    case 6:
    case "RPIT_YEAR":
      return RelativePointInTimeSettings_RelativePointInTimeType.RPIT_YEAR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RelativePointInTimeSettings_RelativePointInTimeType.UNRECOGNIZED;
  }
}

export function relativePointInTimeSettings_RelativePointInTimeTypeToJSON(
  object: RelativePointInTimeSettings_RelativePointInTimeType,
): string {
  switch (object) {
    case RelativePointInTimeSettings_RelativePointInTimeType.RPIT_UNKNOWN:
      return "RPIT_UNKNOWN";
    case RelativePointInTimeSettings_RelativePointInTimeType.RPIT_DAY_OF_MONTH:
      return "RPIT_DAY_OF_MONTH";
    case RelativePointInTimeSettings_RelativePointInTimeType.RPIT_DAY_OF_WEEK:
      return "RPIT_DAY_OF_WEEK";
    case RelativePointInTimeSettings_RelativePointInTimeType.RPIT_MONTH:
      return "RPIT_MONTH";
    case RelativePointInTimeSettings_RelativePointInTimeType.RPIT_WEEK:
      return "RPIT_WEEK";
    case RelativePointInTimeSettings_RelativePointInTimeType.RPIT_QUARTER:
      return "RPIT_QUARTER";
    case RelativePointInTimeSettings_RelativePointInTimeType.RPIT_YEAR:
      return "RPIT_YEAR";
    case RelativePointInTimeSettings_RelativePointInTimeType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseTimeSetting(): TimeSetting {
  return { type: undefined, span: undefined, relativePointInTime: undefined, absolutePointInTime: undefined };
}

export const TimeSetting = {
  encode(message: TimeSetting, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== undefined) {
      writer.uint32(8).int32(message.type);
    }
    if (message.span !== undefined) {
      DurationContainer.encode(message.span, writer.uint32(18).fork()).ldelim();
    }
    if (message.relativePointInTime !== undefined) {
      RelativePointInTimeSettings.encode(message.relativePointInTime, writer.uint32(26).fork()).ldelim();
    }
    if (message.absolutePointInTime !== undefined) {
      writer.uint32(32).int64(message.absolutePointInTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TimeSetting {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimeSetting();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.span = DurationContainer.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.relativePointInTime = RelativePointInTimeSettings.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.absolutePointInTime = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TimeSetting {
    return {
      type: isSet(object.type) ? timeSettingTypeFromJSON(object.type) : undefined,
      span: isSet(object.span) ? DurationContainer.fromJSON(object.span) : undefined,
      relativePointInTime: isSet(object.relativePointInTime)
        ? RelativePointInTimeSettings.fromJSON(object.relativePointInTime)
        : undefined,
      absolutePointInTime: isSet(object.absolutePointInTime) ? Number(object.absolutePointInTime) : undefined,
    };
  },

  toJSON(message: TimeSetting): unknown {
    const obj: any = {};
    message.type !== undefined &&
      (obj.type = message.type !== undefined ? timeSettingTypeToJSON(message.type) : undefined);
    message.span !== undefined && (obj.span = message.span ? DurationContainer.toJSON(message.span) : undefined);
    message.relativePointInTime !== undefined && (obj.relativePointInTime = message.relativePointInTime
      ? RelativePointInTimeSettings.toJSON(message.relativePointInTime)
      : undefined);
    message.absolutePointInTime !== undefined && (obj.absolutePointInTime = Math.round(message.absolutePointInTime));
    return obj;
  },

  create<I extends Exact<DeepPartial<TimeSetting>, I>>(base?: I): TimeSetting {
    return TimeSetting.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TimeSetting>, I>>(object: I): TimeSetting {
    const message = createBaseTimeSetting();
    message.type = object.type ?? undefined;
    message.span = (object.span !== undefined && object.span !== null)
      ? DurationContainer.fromPartial(object.span)
      : undefined;
    message.relativePointInTime = (object.relativePointInTime !== undefined && object.relativePointInTime !== null)
      ? RelativePointInTimeSettings.fromPartial(object.relativePointInTime)
      : undefined;
    message.absolutePointInTime = object.absolutePointInTime ?? undefined;
    return message;
  },
};

function createBaseDurationContainer(): DurationContainer {
  return { days: undefined, hours: undefined, minutes: undefined, seconds: undefined };
}

export const DurationContainer = {
  encode(message: DurationContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.days !== undefined) {
      writer.uint32(8).int32(message.days);
    }
    if (message.hours !== undefined) {
      writer.uint32(16).int32(message.hours);
    }
    if (message.minutes !== undefined) {
      writer.uint32(24).int32(message.minutes);
    }
    if (message.seconds !== undefined) {
      writer.uint32(32).int32(message.seconds);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DurationContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDurationContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.days = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hours = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.minutes = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.seconds = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DurationContainer {
    return {
      days: isSet(object.days) ? Number(object.days) : undefined,
      hours: isSet(object.hours) ? Number(object.hours) : undefined,
      minutes: isSet(object.minutes) ? Number(object.minutes) : undefined,
      seconds: isSet(object.seconds) ? Number(object.seconds) : undefined,
    };
  },

  toJSON(message: DurationContainer): unknown {
    const obj: any = {};
    message.days !== undefined && (obj.days = Math.round(message.days));
    message.hours !== undefined && (obj.hours = Math.round(message.hours));
    message.minutes !== undefined && (obj.minutes = Math.round(message.minutes));
    message.seconds !== undefined && (obj.seconds = Math.round(message.seconds));
    return obj;
  },

  create<I extends Exact<DeepPartial<DurationContainer>, I>>(base?: I): DurationContainer {
    return DurationContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DurationContainer>, I>>(object: I): DurationContainer {
    const message = createBaseDurationContainer();
    message.days = object.days ?? undefined;
    message.hours = object.hours ?? undefined;
    message.minutes = object.minutes ?? undefined;
    message.seconds = object.seconds ?? undefined;
    return message;
  },
};

function createBaseRelativePointInTimeSettings(): RelativePointInTimeSettings {
  return { type: undefined, offset: undefined, number: undefined, useStartOfPeriod: undefined };
}

export const RelativePointInTimeSettings = {
  encode(message: RelativePointInTimeSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== undefined) {
      writer.uint32(8).int32(message.type);
    }
    if (message.offset !== undefined) {
      writer.uint32(16).int32(message.offset);
    }
    if (message.number !== undefined) {
      writer.uint32(24).int32(message.number);
    }
    if (message.useStartOfPeriod !== undefined) {
      writer.uint32(32).bool(message.useStartOfPeriod);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RelativePointInTimeSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRelativePointInTimeSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.offset = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.number = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.useStartOfPeriod = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RelativePointInTimeSettings {
    return {
      type: isSet(object.type) ? relativePointInTimeSettings_RelativePointInTimeTypeFromJSON(object.type) : undefined,
      offset: isSet(object.offset) ? Number(object.offset) : undefined,
      number: isSet(object.number) ? Number(object.number) : undefined,
      useStartOfPeriod: isSet(object.useStartOfPeriod) ? Boolean(object.useStartOfPeriod) : undefined,
    };
  },

  toJSON(message: RelativePointInTimeSettings): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = message.type !== undefined
      ? relativePointInTimeSettings_RelativePointInTimeTypeToJSON(message.type)
      : undefined);
    message.offset !== undefined && (obj.offset = Math.round(message.offset));
    message.number !== undefined && (obj.number = Math.round(message.number));
    message.useStartOfPeriod !== undefined && (obj.useStartOfPeriod = message.useStartOfPeriod);
    return obj;
  },

  create<I extends Exact<DeepPartial<RelativePointInTimeSettings>, I>>(base?: I): RelativePointInTimeSettings {
    return RelativePointInTimeSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RelativePointInTimeSettings>, I>>(object: I): RelativePointInTimeSettings {
    const message = createBaseRelativePointInTimeSettings();
    message.type = object.type ?? undefined;
    message.offset = object.offset ?? undefined;
    message.number = object.number ?? undefined;
    message.useStartOfPeriod = object.useStartOfPeriod ?? undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

// If you get a compile-error about 'Constructor<Long> and ... have no overlap',
// add '--ts_proto_opt=esModuleInterop=true' as a flag when calling 'protoc'.
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
