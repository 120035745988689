/* eslint-disable */
// @ts-ignore
import * as Long from "long";
import * as _m0 from "protobufjs/minimal";
import { Struct } from "./google/protobuf/struct";
import { Timestamp } from "./google/protobuf/timestamp";
import { Note } from "./lead-note";
import {
  Address,
  DateContainer,
  EnrollmentCodeContainer,
  Gender,
  genderFromJSON,
  genderToJSON,
  IdentifierContainer,
  IdentifierRepeatable,
  PermanentTagsContainer,
  ReferenceContainer,
  SipInformationContainer,
  Source,
  sourceFromJSON,
  sourceToJSON,
  TagsContainer,
  UserIdContainer,
} from "./misc";
import { AccessContainer } from "./user";

export const protobufPackage = "";

export enum MarketType {
  NO_MARKET_TYPE = 0,
  MEDICARE = 1,
  UNRECOGNIZED = -1,
}

export function marketTypeFromJSON(object: any): MarketType {
  switch (object) {
    case 0:
    case "NO_MARKET_TYPE":
      return MarketType.NO_MARKET_TYPE;
    case 1:
    case "MEDICARE":
      return MarketType.MEDICARE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MarketType.UNRECOGNIZED;
  }
}

export function marketTypeToJSON(object: MarketType): string {
  switch (object) {
    case MarketType.NO_MARKET_TYPE:
      return "NO_MARKET_TYPE";
    case MarketType.MEDICARE:
      return "MEDICARE";
    case MarketType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum LeadType {
  NO_LEAD_TYPE = 0,
  DIRECT_MAIL = 1,
  UNRECOGNIZED = -1,
}

export function leadTypeFromJSON(object: any): LeadType {
  switch (object) {
    case 0:
    case "NO_LEAD_TYPE":
      return LeadType.NO_LEAD_TYPE;
    case 1:
    case "DIRECT_MAIL":
      return LeadType.DIRECT_MAIL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LeadType.UNRECOGNIZED;
  }
}

export function leadTypeToJSON(object: LeadType): string {
  switch (object) {
    case LeadType.NO_LEAD_TYPE:
      return "NO_LEAD_TYPE";
    case LeadType.DIRECT_MAIL:
      return "DIRECT_MAIL";
    case LeadType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum LeadSource {
  NO_LEAD_SOURCE = 0,
  LS_DIALER_QUEUE = 1,
  LS_DIALER_MANUAL_OUTBOUND = 2,
  UNRECOGNIZED = -1,
}

export function leadSourceFromJSON(object: any): LeadSource {
  switch (object) {
    case 0:
    case "NO_LEAD_SOURCE":
      return LeadSource.NO_LEAD_SOURCE;
    case 1:
    case "LS_DIALER_QUEUE":
      return LeadSource.LS_DIALER_QUEUE;
    case 2:
    case "LS_DIALER_MANUAL_OUTBOUND":
      return LeadSource.LS_DIALER_MANUAL_OUTBOUND;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LeadSource.UNRECOGNIZED;
  }
}

export function leadSourceToJSON(object: LeadSource): string {
  switch (object) {
    case LeadSource.NO_LEAD_SOURCE:
      return "NO_LEAD_SOURCE";
    case LeadSource.LS_DIALER_QUEUE:
      return "LS_DIALER_QUEUE";
    case LeadSource.LS_DIALER_MANUAL_OUTBOUND:
      return "LS_DIALER_MANUAL_OUTBOUND";
    case LeadSource.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Consumer {
  id?: string | undefined;
  firstName?: string | undefined;
  middleName?: string | undefined;
  lastName?: string | undefined;
  nameSuffix?: string | undefined;
  email?: string | undefined;
  phone?: string | undefined;
  address?: Address | undefined;
  birthDate?: DateContainer | undefined;
  ssn?: string | undefined;
  gender?: Gender | undefined;
  okToEmail?: boolean | undefined;
  profileImage?: string | undefined;
  leadDate?: string | undefined;
  marketType?: MarketType | undefined;
  leadType?: LeadType | undefined;
  agency?: Consumer_Agency | undefined;
  campaign?: Consumer_Campaign | undefined;
  dialer?: Consumer_Dialer | undefined;
  leadList?: Consumer_LeadList | undefined;
  leadDateTimestamp?: number | undefined;
  medicare?: Consumer_Medicare | undefined;
  leadSource?: LeadSource | undefined;
  leadSourceDetails?: LeadSourceDetails | undefined;
  veteranStatus?: Consumer_VeteranStatus | undefined;
  workStatus?:
    | Consumer_WorkStatus
    | undefined;
  /** deprecated */
  benefits?:
    | { [key: string]: any }
    | undefined;
  /** repeated Benefit benefits = 28; */
  language?: string | undefined;
  createdAt?: number | undefined;
  timezone?: string | undefined;
  identifiers?: IdentifierContainer | undefined;
  source?: Source | undefined;
  sourceCustom?: string | undefined;
  phoneInvalid?: boolean | undefined;
  notes?: Note | undefined;
  marketingSource?: Consumer_MarketingSource | undefined;
  referralSource?: Consumer_ReferralSource | undefined;
  tags?: TagsContainer | undefined;
  customFields?: { [key: string]: any } | undefined;
  permanentTags?: PermanentTagsContainer | undefined;
  permanentTagsQueue?: PermanentTagsContainer | undefined;
  permanentTagsTeam?: PermanentTagsContainer | undefined;
  sipInformation?: SipInformationContainer | undefined;
  access?: AccessContainer | undefined;
  leadListMember?:
    | LeadListMember
    | undefined;
  /**
   * deprecated
   *
   * @deprecated
   */
  dateOfBirth?: Date | undefined;
}

export enum Consumer_VeteranStatus {
  VS_NO_VETERAN_STATUS = 0,
  VS_NO_VETERAN = 1,
  VS_VETERAN = 2,
  VS_SPOUSE = 3,
  VS_DEPENDENT = 4,
  UNRECOGNIZED = -1,
}

export function consumer_VeteranStatusFromJSON(object: any): Consumer_VeteranStatus {
  switch (object) {
    case 0:
    case "VS_NO_VETERAN_STATUS":
      return Consumer_VeteranStatus.VS_NO_VETERAN_STATUS;
    case 1:
    case "VS_NO_VETERAN":
      return Consumer_VeteranStatus.VS_NO_VETERAN;
    case 2:
    case "VS_VETERAN":
      return Consumer_VeteranStatus.VS_VETERAN;
    case 3:
    case "VS_SPOUSE":
      return Consumer_VeteranStatus.VS_SPOUSE;
    case 4:
    case "VS_DEPENDENT":
      return Consumer_VeteranStatus.VS_DEPENDENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Consumer_VeteranStatus.UNRECOGNIZED;
  }
}

export function consumer_VeteranStatusToJSON(object: Consumer_VeteranStatus): string {
  switch (object) {
    case Consumer_VeteranStatus.VS_NO_VETERAN_STATUS:
      return "VS_NO_VETERAN_STATUS";
    case Consumer_VeteranStatus.VS_NO_VETERAN:
      return "VS_NO_VETERAN";
    case Consumer_VeteranStatus.VS_VETERAN:
      return "VS_VETERAN";
    case Consumer_VeteranStatus.VS_SPOUSE:
      return "VS_SPOUSE";
    case Consumer_VeteranStatus.VS_DEPENDENT:
      return "VS_DEPENDENT";
    case Consumer_VeteranStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Consumer_WorkStatus {
  WS_NO_WORK_STATUS = 0,
  WS_EMPLOYED = 1,
  WS_UNEMPLOYED = 2,
  WS_RETIRED = 3,
  WS_DISABLED = 4,
  UNRECOGNIZED = -1,
}

export function consumer_WorkStatusFromJSON(object: any): Consumer_WorkStatus {
  switch (object) {
    case 0:
    case "WS_NO_WORK_STATUS":
      return Consumer_WorkStatus.WS_NO_WORK_STATUS;
    case 1:
    case "WS_EMPLOYED":
      return Consumer_WorkStatus.WS_EMPLOYED;
    case 2:
    case "WS_UNEMPLOYED":
      return Consumer_WorkStatus.WS_UNEMPLOYED;
    case 3:
    case "WS_RETIRED":
      return Consumer_WorkStatus.WS_RETIRED;
    case 4:
    case "WS_DISABLED":
      return Consumer_WorkStatus.WS_DISABLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Consumer_WorkStatus.UNRECOGNIZED;
  }
}

export function consumer_WorkStatusToJSON(object: Consumer_WorkStatus): string {
  switch (object) {
    case Consumer_WorkStatus.WS_NO_WORK_STATUS:
      return "WS_NO_WORK_STATUS";
    case Consumer_WorkStatus.WS_EMPLOYED:
      return "WS_EMPLOYED";
    case Consumer_WorkStatus.WS_UNEMPLOYED:
      return "WS_UNEMPLOYED";
    case Consumer_WorkStatus.WS_RETIRED:
      return "WS_RETIRED";
    case Consumer_WorkStatus.WS_DISABLED:
      return "WS_DISABLED";
    case Consumer_WorkStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Consumer_MarketingSource {
  source?: string | undefined;
  utmParameters?: string | undefined;
  landingPage?: string | undefined;
}

export interface Consumer_ReferralSource {
  source?: string | undefined;
}

export interface Consumer_Dialer {
  resultCategory?: Consumer_Dialer_ResultCategory | undefined;
  result?: Consumer_Dialer_Result | undefined;
  resultCategoryAgent?: Consumer_Dialer_ResultCategory | undefined;
  resultAgent?: Consumer_Dialer_Result | undefined;
  lastCallTry?: number | undefined;
  lastCallConnected?: number | undefined;
  lastCallEnded?: number | undefined;
  lastAddedToHopper?: number | undefined;
  lastCallState?: Consumer_Dialer_CallState | undefined;
  from?: string | undefined;
  totalCalls?: number | undefined;
  totalCallsInboundAnswered?: number | undefined;
  lastCallInboundAnswered?: number | undefined;
  totalCallsInboundBillable?: number | undefined;
  lastCallInboundBillable?: number | undefined;
  totalCallsInbound?: number | undefined;
  lastCallInbound?: number | undefined;
  totalCallsOutbound?: number | undefined;
  lastCallOutbound?: number | undefined;
  enrollmentCode?: EnrollmentCodeContainer | undefined;
  incoming?: Consumer_Dialer_IncomingContainer | undefined;
  tempLead?: Consumer_Dialer_TempLead | undefined;
  initialQueue?: ReferenceContainer | undefined;
}

export enum Consumer_Dialer_ResultCategory {
  NO_STATUS_CATEGORY = 0,
  SUCCESS = 1,
  RECYCLE = 2,
  TRASH = 3,
  UNRECOGNIZED = -1,
}

export function consumer_Dialer_ResultCategoryFromJSON(object: any): Consumer_Dialer_ResultCategory {
  switch (object) {
    case 0:
    case "NO_STATUS_CATEGORY":
      return Consumer_Dialer_ResultCategory.NO_STATUS_CATEGORY;
    case 1:
    case "SUCCESS":
      return Consumer_Dialer_ResultCategory.SUCCESS;
    case 2:
    case "RECYCLE":
      return Consumer_Dialer_ResultCategory.RECYCLE;
    case 3:
    case "TRASH":
      return Consumer_Dialer_ResultCategory.TRASH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Consumer_Dialer_ResultCategory.UNRECOGNIZED;
  }
}

export function consumer_Dialer_ResultCategoryToJSON(object: Consumer_Dialer_ResultCategory): string {
  switch (object) {
    case Consumer_Dialer_ResultCategory.NO_STATUS_CATEGORY:
      return "NO_STATUS_CATEGORY";
    case Consumer_Dialer_ResultCategory.SUCCESS:
      return "SUCCESS";
    case Consumer_Dialer_ResultCategory.RECYCLE:
      return "RECYCLE";
    case Consumer_Dialer_ResultCategory.TRASH:
      return "TRASH";
    case Consumer_Dialer_ResultCategory.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Consumer_Dialer_Result {
  NO_STATUS = 0,
  S_AGENT_TO_AGENT = 1,
  S_APP_SUBMITTED = 2,
  S_APP_SUBMITTED_DOUBLE_SALE = 3,
  S_EXISTING_CLIENT_CALLBACK = 4,
  S_APPOINTMENT_BOOKED = 5,
  R_CALL_BACK = 11,
  R_CLIENT_HANGUP = 12,
  R_CLIENT_HANGUP_DURING_TRANSFER = 13,
  R_CLIENT_UNWILLING_SWITCH = 14,
  R_MISSED_OPPORTUNITY = 15,
  R_DROPPED_CALL = 16,
  R_ANSWERING_MACHINE = 17,
  R_NOT_AVAILABLE = 18,
  R_NO_SEP = 19,
  R_NOT_LICENSED_STATE = 20,
  R_PRE_AEP_APPOINTMENT = 21,
  R_NON_QUALIFIED_TRANSFER = 22,
  R_NO_TRANSFER_AGENT_AVAILABLE = 23,
  R_ON_BEST_PLAN = 24,
  R_DOES_NOT_MAKE_HEALTHCARE_DECISIONS = 25,
  T_CLIENT_NO_A_B = 31,
  T_CLIENT_REFUSES_GIVE_INFO = 32,
  T_DO_NOT_CALL = 33,
  T_LANGUAGE_BARRIER = 34,
  T_MEDICARE_SUPPLEMENT_PLAN = 35,
  T_NOT_DECISION_MAKER = 36,
  T_RETIREMENT_PLAN = 37,
  T_EXISTING_CLIENT = 38,
  T_NOT_INTERESTED = 39,
  T_UNDER_65 = 40,
  T_WRONG_NUMBER = 41,
  UNRECOGNIZED = -1,
}

export function consumer_Dialer_ResultFromJSON(object: any): Consumer_Dialer_Result {
  switch (object) {
    case 0:
    case "NO_STATUS":
      return Consumer_Dialer_Result.NO_STATUS;
    case 1:
    case "S_AGENT_TO_AGENT":
      return Consumer_Dialer_Result.S_AGENT_TO_AGENT;
    case 2:
    case "S_APP_SUBMITTED":
      return Consumer_Dialer_Result.S_APP_SUBMITTED;
    case 3:
    case "S_APP_SUBMITTED_DOUBLE_SALE":
      return Consumer_Dialer_Result.S_APP_SUBMITTED_DOUBLE_SALE;
    case 4:
    case "S_EXISTING_CLIENT_CALLBACK":
      return Consumer_Dialer_Result.S_EXISTING_CLIENT_CALLBACK;
    case 5:
    case "S_APPOINTMENT_BOOKED":
      return Consumer_Dialer_Result.S_APPOINTMENT_BOOKED;
    case 11:
    case "R_CALL_BACK":
      return Consumer_Dialer_Result.R_CALL_BACK;
    case 12:
    case "R_CLIENT_HANGUP":
      return Consumer_Dialer_Result.R_CLIENT_HANGUP;
    case 13:
    case "R_CLIENT_HANGUP_DURING_TRANSFER":
      return Consumer_Dialer_Result.R_CLIENT_HANGUP_DURING_TRANSFER;
    case 14:
    case "R_CLIENT_UNWILLING_SWITCH":
      return Consumer_Dialer_Result.R_CLIENT_UNWILLING_SWITCH;
    case 15:
    case "R_MISSED_OPPORTUNITY":
      return Consumer_Dialer_Result.R_MISSED_OPPORTUNITY;
    case 16:
    case "R_DROPPED_CALL":
      return Consumer_Dialer_Result.R_DROPPED_CALL;
    case 17:
    case "R_ANSWERING_MACHINE":
      return Consumer_Dialer_Result.R_ANSWERING_MACHINE;
    case 18:
    case "R_NOT_AVAILABLE":
      return Consumer_Dialer_Result.R_NOT_AVAILABLE;
    case 19:
    case "R_NO_SEP":
      return Consumer_Dialer_Result.R_NO_SEP;
    case 20:
    case "R_NOT_LICENSED_STATE":
      return Consumer_Dialer_Result.R_NOT_LICENSED_STATE;
    case 21:
    case "R_PRE_AEP_APPOINTMENT":
      return Consumer_Dialer_Result.R_PRE_AEP_APPOINTMENT;
    case 22:
    case "R_NON_QUALIFIED_TRANSFER":
      return Consumer_Dialer_Result.R_NON_QUALIFIED_TRANSFER;
    case 23:
    case "R_NO_TRANSFER_AGENT_AVAILABLE":
      return Consumer_Dialer_Result.R_NO_TRANSFER_AGENT_AVAILABLE;
    case 24:
    case "R_ON_BEST_PLAN":
      return Consumer_Dialer_Result.R_ON_BEST_PLAN;
    case 25:
    case "R_DOES_NOT_MAKE_HEALTHCARE_DECISIONS":
      return Consumer_Dialer_Result.R_DOES_NOT_MAKE_HEALTHCARE_DECISIONS;
    case 31:
    case "T_CLIENT_NO_A_B":
      return Consumer_Dialer_Result.T_CLIENT_NO_A_B;
    case 32:
    case "T_CLIENT_REFUSES_GIVE_INFO":
      return Consumer_Dialer_Result.T_CLIENT_REFUSES_GIVE_INFO;
    case 33:
    case "T_DO_NOT_CALL":
      return Consumer_Dialer_Result.T_DO_NOT_CALL;
    case 34:
    case "T_LANGUAGE_BARRIER":
      return Consumer_Dialer_Result.T_LANGUAGE_BARRIER;
    case 35:
    case "T_MEDICARE_SUPPLEMENT_PLAN":
      return Consumer_Dialer_Result.T_MEDICARE_SUPPLEMENT_PLAN;
    case 36:
    case "T_NOT_DECISION_MAKER":
      return Consumer_Dialer_Result.T_NOT_DECISION_MAKER;
    case 37:
    case "T_RETIREMENT_PLAN":
      return Consumer_Dialer_Result.T_RETIREMENT_PLAN;
    case 38:
    case "T_EXISTING_CLIENT":
      return Consumer_Dialer_Result.T_EXISTING_CLIENT;
    case 39:
    case "T_NOT_INTERESTED":
      return Consumer_Dialer_Result.T_NOT_INTERESTED;
    case 40:
    case "T_UNDER_65":
      return Consumer_Dialer_Result.T_UNDER_65;
    case 41:
    case "T_WRONG_NUMBER":
      return Consumer_Dialer_Result.T_WRONG_NUMBER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Consumer_Dialer_Result.UNRECOGNIZED;
  }
}

export function consumer_Dialer_ResultToJSON(object: Consumer_Dialer_Result): string {
  switch (object) {
    case Consumer_Dialer_Result.NO_STATUS:
      return "NO_STATUS";
    case Consumer_Dialer_Result.S_AGENT_TO_AGENT:
      return "S_AGENT_TO_AGENT";
    case Consumer_Dialer_Result.S_APP_SUBMITTED:
      return "S_APP_SUBMITTED";
    case Consumer_Dialer_Result.S_APP_SUBMITTED_DOUBLE_SALE:
      return "S_APP_SUBMITTED_DOUBLE_SALE";
    case Consumer_Dialer_Result.S_EXISTING_CLIENT_CALLBACK:
      return "S_EXISTING_CLIENT_CALLBACK";
    case Consumer_Dialer_Result.S_APPOINTMENT_BOOKED:
      return "S_APPOINTMENT_BOOKED";
    case Consumer_Dialer_Result.R_CALL_BACK:
      return "R_CALL_BACK";
    case Consumer_Dialer_Result.R_CLIENT_HANGUP:
      return "R_CLIENT_HANGUP";
    case Consumer_Dialer_Result.R_CLIENT_HANGUP_DURING_TRANSFER:
      return "R_CLIENT_HANGUP_DURING_TRANSFER";
    case Consumer_Dialer_Result.R_CLIENT_UNWILLING_SWITCH:
      return "R_CLIENT_UNWILLING_SWITCH";
    case Consumer_Dialer_Result.R_MISSED_OPPORTUNITY:
      return "R_MISSED_OPPORTUNITY";
    case Consumer_Dialer_Result.R_DROPPED_CALL:
      return "R_DROPPED_CALL";
    case Consumer_Dialer_Result.R_ANSWERING_MACHINE:
      return "R_ANSWERING_MACHINE";
    case Consumer_Dialer_Result.R_NOT_AVAILABLE:
      return "R_NOT_AVAILABLE";
    case Consumer_Dialer_Result.R_NO_SEP:
      return "R_NO_SEP";
    case Consumer_Dialer_Result.R_NOT_LICENSED_STATE:
      return "R_NOT_LICENSED_STATE";
    case Consumer_Dialer_Result.R_PRE_AEP_APPOINTMENT:
      return "R_PRE_AEP_APPOINTMENT";
    case Consumer_Dialer_Result.R_NON_QUALIFIED_TRANSFER:
      return "R_NON_QUALIFIED_TRANSFER";
    case Consumer_Dialer_Result.R_NO_TRANSFER_AGENT_AVAILABLE:
      return "R_NO_TRANSFER_AGENT_AVAILABLE";
    case Consumer_Dialer_Result.R_ON_BEST_PLAN:
      return "R_ON_BEST_PLAN";
    case Consumer_Dialer_Result.R_DOES_NOT_MAKE_HEALTHCARE_DECISIONS:
      return "R_DOES_NOT_MAKE_HEALTHCARE_DECISIONS";
    case Consumer_Dialer_Result.T_CLIENT_NO_A_B:
      return "T_CLIENT_NO_A_B";
    case Consumer_Dialer_Result.T_CLIENT_REFUSES_GIVE_INFO:
      return "T_CLIENT_REFUSES_GIVE_INFO";
    case Consumer_Dialer_Result.T_DO_NOT_CALL:
      return "T_DO_NOT_CALL";
    case Consumer_Dialer_Result.T_LANGUAGE_BARRIER:
      return "T_LANGUAGE_BARRIER";
    case Consumer_Dialer_Result.T_MEDICARE_SUPPLEMENT_PLAN:
      return "T_MEDICARE_SUPPLEMENT_PLAN";
    case Consumer_Dialer_Result.T_NOT_DECISION_MAKER:
      return "T_NOT_DECISION_MAKER";
    case Consumer_Dialer_Result.T_RETIREMENT_PLAN:
      return "T_RETIREMENT_PLAN";
    case Consumer_Dialer_Result.T_EXISTING_CLIENT:
      return "T_EXISTING_CLIENT";
    case Consumer_Dialer_Result.T_NOT_INTERESTED:
      return "T_NOT_INTERESTED";
    case Consumer_Dialer_Result.T_UNDER_65:
      return "T_UNDER_65";
    case Consumer_Dialer_Result.T_WRONG_NUMBER:
      return "T_WRONG_NUMBER";
    case Consumer_Dialer_Result.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Consumer_Dialer_CallState {
  NO_CALL_STATE = 0,
  RINGING = 1,
  CONNECTED = 2,
  FINISHED = 3,
  IN_HOPPER = 4,
  ANSWERING_MACHINE = 5,
  UNRECOGNIZED = -1,
}

export function consumer_Dialer_CallStateFromJSON(object: any): Consumer_Dialer_CallState {
  switch (object) {
    case 0:
    case "NO_CALL_STATE":
      return Consumer_Dialer_CallState.NO_CALL_STATE;
    case 1:
    case "RINGING":
      return Consumer_Dialer_CallState.RINGING;
    case 2:
    case "CONNECTED":
      return Consumer_Dialer_CallState.CONNECTED;
    case 3:
    case "FINISHED":
      return Consumer_Dialer_CallState.FINISHED;
    case 4:
    case "IN_HOPPER":
      return Consumer_Dialer_CallState.IN_HOPPER;
    case 5:
    case "ANSWERING_MACHINE":
      return Consumer_Dialer_CallState.ANSWERING_MACHINE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Consumer_Dialer_CallState.UNRECOGNIZED;
  }
}

export function consumer_Dialer_CallStateToJSON(object: Consumer_Dialer_CallState): string {
  switch (object) {
    case Consumer_Dialer_CallState.NO_CALL_STATE:
      return "NO_CALL_STATE";
    case Consumer_Dialer_CallState.RINGING:
      return "RINGING";
    case Consumer_Dialer_CallState.CONNECTED:
      return "CONNECTED";
    case Consumer_Dialer_CallState.FINISHED:
      return "FINISHED";
    case Consumer_Dialer_CallState.IN_HOPPER:
      return "IN_HOPPER";
    case Consumer_Dialer_CallState.ANSWERING_MACHINE:
      return "ANSWERING_MACHINE";
    case Consumer_Dialer_CallState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Consumer_Dialer_TempLead {
  isTempLead?: boolean | undefined;
  queue?: ReferenceContainer | undefined;
  createdAt?: number | undefined;
}

export interface Consumer_Dialer_IncomingContainer {
  participants: { [key: string]: Consumer_Dialer_IncomingContainer_Participant };
  incomingStep1?: ConsumerDialerFlowStep | undefined;
  flow?: Consumer_Dialer_IncomingContainer_DialerCallFlow | undefined;
  related?: Consumer_Dialer_IncomingContainer_RelatedContainer | undefined;
}

export interface Consumer_Dialer_IncomingContainer_ParticipantsEntry {
  key: string;
  value: Consumer_Dialer_IncomingContainer_Participant | undefined;
}

export interface Consumer_Dialer_IncomingContainer_RelatedContainer {
  calls: Consumer_Dialer_IncomingContainer_RelatedCall[];
}

export interface Consumer_Dialer_IncomingContainer_RelatedCall {
  uuid?: string | undefined;
  lead?: UserIdContainer | undefined;
  agency?: UserIdContainer | undefined;
  queue?: UserIdContainer | undefined;
  isParent?: boolean | undefined;
  isChild?: boolean | undefined;
}

export interface Consumer_Dialer_IncomingContainer_DialerCallFlow {
  isInitial?: boolean | undefined;
  initialId?: string | undefined;
  isPassthrough?: boolean | undefined;
  passthroughId?: string | undefined;
  isFinal?: boolean | undefined;
  finalId?: string | undefined;
  list: ConsumerDialerFlowStep[];
}

export interface Consumer_Dialer_IncomingContainer_Participant {
  role?: string | undefined;
  calledTime?: number | undefined;
  joinedTime?: number | undefined;
  leftTime?: number | undefined;
  declinedTime?: number | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  profileImage?: string | undefined;
  type?: ConsumerDialerFlowStep_TargetType | undefined;
  callControlId?: string | undefined;
}

export interface Consumer_Agency {
  id?: string | undefined;
  name?: string | undefined;
}

export interface Consumer_Campaign {
  id?: string | undefined;
  name?: string | undefined;
}

export interface Consumer_LeadList {
  id?: string | undefined;
  name?: string | undefined;
}

export interface Consumer_Medicare {
  number?: string | undefined;
}

export interface Benefit {
  id: string;
  name: string;
  active?: boolean | undefined;
}

export interface LeadSourceDetails {
  agent?: UserIdContainer | undefined;
  queue?: ReferenceContainer | undefined;
}

export interface ConsumerDialerFlowStep {
  queue?: UserIdContainer | undefined;
  agency?: UserIdContainer | undefined;
  type?: ConsumerDialerFlowStep_TargetType | undefined;
  crossAgencyUser?: boolean | undefined;
}

export enum ConsumerDialerFlowStep_TargetType {
  UNKNOWN_TARGET_TYPE = 0,
  LEAD = 1,
  AGENT = 2,
  AGENCY = 3,
  SUPERVISOR = 4,
  EXTERNAL_NUMBER = 5,
  TT_QUEUE = 6,
  EXTERNAL_QUEUE = 7,
  UNRECOGNIZED = -1,
}

export function consumerDialerFlowStep_TargetTypeFromJSON(object: any): ConsumerDialerFlowStep_TargetType {
  switch (object) {
    case 0:
    case "UNKNOWN_TARGET_TYPE":
      return ConsumerDialerFlowStep_TargetType.UNKNOWN_TARGET_TYPE;
    case 1:
    case "LEAD":
      return ConsumerDialerFlowStep_TargetType.LEAD;
    case 2:
    case "AGENT":
      return ConsumerDialerFlowStep_TargetType.AGENT;
    case 3:
    case "AGENCY":
      return ConsumerDialerFlowStep_TargetType.AGENCY;
    case 4:
    case "SUPERVISOR":
      return ConsumerDialerFlowStep_TargetType.SUPERVISOR;
    case 5:
    case "EXTERNAL_NUMBER":
      return ConsumerDialerFlowStep_TargetType.EXTERNAL_NUMBER;
    case 6:
    case "TT_QUEUE":
      return ConsumerDialerFlowStep_TargetType.TT_QUEUE;
    case 7:
    case "EXTERNAL_QUEUE":
      return ConsumerDialerFlowStep_TargetType.EXTERNAL_QUEUE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ConsumerDialerFlowStep_TargetType.UNRECOGNIZED;
  }
}

export function consumerDialerFlowStep_TargetTypeToJSON(object: ConsumerDialerFlowStep_TargetType): string {
  switch (object) {
    case ConsumerDialerFlowStep_TargetType.UNKNOWN_TARGET_TYPE:
      return "UNKNOWN_TARGET_TYPE";
    case ConsumerDialerFlowStep_TargetType.LEAD:
      return "LEAD";
    case ConsumerDialerFlowStep_TargetType.AGENT:
      return "AGENT";
    case ConsumerDialerFlowStep_TargetType.AGENCY:
      return "AGENCY";
    case ConsumerDialerFlowStep_TargetType.SUPERVISOR:
      return "SUPERVISOR";
    case ConsumerDialerFlowStep_TargetType.EXTERNAL_NUMBER:
      return "EXTERNAL_NUMBER";
    case ConsumerDialerFlowStep_TargetType.TT_QUEUE:
      return "TT_QUEUE";
    case ConsumerDialerFlowStep_TargetType.EXTERNAL_QUEUE:
      return "EXTERNAL_QUEUE";
    case ConsumerDialerFlowStep_TargetType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface LeadListMember {
  lists: LeadListMember_LeadListMemberItem[];
}

export interface LeadListMember_LeadListMemberItem {
  list?: ReferenceContainer | undefined;
  createdAt?: number | undefined;
}

export interface LeadPhoneAssignments {
  leadList?: IdentifierRepeatable | undefined;
}

function createBaseConsumer(): Consumer {
  return {
    id: undefined,
    firstName: undefined,
    middleName: undefined,
    lastName: undefined,
    nameSuffix: undefined,
    email: undefined,
    phone: undefined,
    address: undefined,
    birthDate: undefined,
    ssn: undefined,
    gender: undefined,
    okToEmail: undefined,
    profileImage: undefined,
    leadDate: undefined,
    marketType: undefined,
    leadType: undefined,
    agency: undefined,
    campaign: undefined,
    dialer: undefined,
    leadList: undefined,
    leadDateTimestamp: undefined,
    medicare: undefined,
    leadSource: undefined,
    leadSourceDetails: undefined,
    veteranStatus: undefined,
    workStatus: undefined,
    benefits: undefined,
    language: undefined,
    createdAt: undefined,
    timezone: undefined,
    identifiers: undefined,
    source: undefined,
    sourceCustom: undefined,
    phoneInvalid: undefined,
    notes: undefined,
    marketingSource: undefined,
    referralSource: undefined,
    tags: undefined,
    customFields: undefined,
    permanentTags: undefined,
    permanentTagsQueue: undefined,
    permanentTagsTeam: undefined,
    sipInformation: undefined,
    access: undefined,
    leadListMember: undefined,
    dateOfBirth: undefined,
  };
}

export const Consumer = {
  encode(message: Consumer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.firstName !== undefined) {
      writer.uint32(18).string(message.firstName);
    }
    if (message.middleName !== undefined) {
      writer.uint32(26).string(message.middleName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(34).string(message.lastName);
    }
    if (message.nameSuffix !== undefined) {
      writer.uint32(42).string(message.nameSuffix);
    }
    if (message.email !== undefined) {
      writer.uint32(50).string(message.email);
    }
    if (message.phone !== undefined) {
      writer.uint32(58).string(message.phone);
    }
    if (message.address !== undefined) {
      Address.encode(message.address, writer.uint32(66).fork()).ldelim();
    }
    if (message.birthDate !== undefined) {
      DateContainer.encode(message.birthDate, writer.uint32(74).fork()).ldelim();
    }
    if (message.ssn !== undefined) {
      writer.uint32(90).string(message.ssn);
    }
    if (message.gender !== undefined) {
      writer.uint32(96).int32(message.gender);
    }
    if (message.okToEmail !== undefined) {
      writer.uint32(104).bool(message.okToEmail);
    }
    if (message.profileImage !== undefined) {
      writer.uint32(114).string(message.profileImage);
    }
    if (message.leadDate !== undefined) {
      writer.uint32(122).string(message.leadDate);
    }
    if (message.marketType !== undefined) {
      writer.uint32(128).int32(message.marketType);
    }
    if (message.leadType !== undefined) {
      writer.uint32(136).int32(message.leadType);
    }
    if (message.agency !== undefined) {
      Consumer_Agency.encode(message.agency, writer.uint32(146).fork()).ldelim();
    }
    if (message.campaign !== undefined) {
      Consumer_Campaign.encode(message.campaign, writer.uint32(154).fork()).ldelim();
    }
    if (message.dialer !== undefined) {
      Consumer_Dialer.encode(message.dialer, writer.uint32(162).fork()).ldelim();
    }
    if (message.leadList !== undefined) {
      Consumer_LeadList.encode(message.leadList, writer.uint32(170).fork()).ldelim();
    }
    if (message.leadDateTimestamp !== undefined) {
      writer.uint32(176).int64(message.leadDateTimestamp);
    }
    if (message.medicare !== undefined) {
      Consumer_Medicare.encode(message.medicare, writer.uint32(186).fork()).ldelim();
    }
    if (message.leadSource !== undefined) {
      writer.uint32(192).int32(message.leadSource);
    }
    if (message.leadSourceDetails !== undefined) {
      LeadSourceDetails.encode(message.leadSourceDetails, writer.uint32(202).fork()).ldelim();
    }
    if (message.veteranStatus !== undefined) {
      writer.uint32(208).int32(message.veteranStatus);
    }
    if (message.workStatus !== undefined) {
      writer.uint32(216).int32(message.workStatus);
    }
    if (message.benefits !== undefined) {
      Struct.encode(Struct.wrap(message.benefits), writer.uint32(226).fork()).ldelim();
    }
    if (message.language !== undefined) {
      writer.uint32(234).string(message.language);
    }
    if (message.createdAt !== undefined) {
      writer.uint32(240).int32(message.createdAt);
    }
    if (message.timezone !== undefined) {
      writer.uint32(250).string(message.timezone);
    }
    if (message.identifiers !== undefined) {
      IdentifierContainer.encode(message.identifiers, writer.uint32(258).fork()).ldelim();
    }
    if (message.source !== undefined) {
      writer.uint32(264).int32(message.source);
    }
    if (message.sourceCustom !== undefined) {
      writer.uint32(274).string(message.sourceCustom);
    }
    if (message.phoneInvalid !== undefined) {
      writer.uint32(280).bool(message.phoneInvalid);
    }
    if (message.notes !== undefined) {
      Note.encode(message.notes, writer.uint32(290).fork()).ldelim();
    }
    if (message.marketingSource !== undefined) {
      Consumer_MarketingSource.encode(message.marketingSource, writer.uint32(298).fork()).ldelim();
    }
    if (message.referralSource !== undefined) {
      Consumer_ReferralSource.encode(message.referralSource, writer.uint32(306).fork()).ldelim();
    }
    if (message.tags !== undefined) {
      TagsContainer.encode(message.tags, writer.uint32(314).fork()).ldelim();
    }
    if (message.customFields !== undefined) {
      Struct.encode(Struct.wrap(message.customFields), writer.uint32(322).fork()).ldelim();
    }
    if (message.permanentTags !== undefined) {
      PermanentTagsContainer.encode(message.permanentTags, writer.uint32(330).fork()).ldelim();
    }
    if (message.permanentTagsQueue !== undefined) {
      PermanentTagsContainer.encode(message.permanentTagsQueue, writer.uint32(338).fork()).ldelim();
    }
    if (message.permanentTagsTeam !== undefined) {
      PermanentTagsContainer.encode(message.permanentTagsTeam, writer.uint32(346).fork()).ldelim();
    }
    if (message.sipInformation !== undefined) {
      SipInformationContainer.encode(message.sipInformation, writer.uint32(354).fork()).ldelim();
    }
    if (message.access !== undefined) {
      AccessContainer.encode(message.access, writer.uint32(362).fork()).ldelim();
    }
    if (message.leadListMember !== undefined) {
      LeadListMember.encode(message.leadListMember, writer.uint32(370).fork()).ldelim();
    }
    if (message.dateOfBirth !== undefined) {
      Timestamp.encode(toTimestamp(message.dateOfBirth), writer.uint32(722).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.middleName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.nameSuffix = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.email = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.phone = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.address = Address.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.birthDate = DateContainer.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.ssn = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.gender = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.okToEmail = reader.bool();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.profileImage = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.leadDate = reader.string();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.marketType = reader.int32() as any;
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.leadType = reader.int32() as any;
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.agency = Consumer_Agency.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.campaign = Consumer_Campaign.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.dialer = Consumer_Dialer.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.leadList = Consumer_LeadList.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.leadDateTimestamp = longToNumber(reader.int64() as Long);
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.medicare = Consumer_Medicare.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.leadSource = reader.int32() as any;
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.leadSourceDetails = LeadSourceDetails.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.veteranStatus = reader.int32() as any;
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.workStatus = reader.int32() as any;
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.benefits = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.language = reader.string();
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.createdAt = reader.int32();
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.timezone = reader.string();
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.identifiers = IdentifierContainer.decode(reader, reader.uint32());
          continue;
        case 33:
          if (tag !== 264) {
            break;
          }

          message.source = reader.int32() as any;
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.sourceCustom = reader.string();
          continue;
        case 35:
          if (tag !== 280) {
            break;
          }

          message.phoneInvalid = reader.bool();
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.notes = Note.decode(reader, reader.uint32());
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.marketingSource = Consumer_MarketingSource.decode(reader, reader.uint32());
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.referralSource = Consumer_ReferralSource.decode(reader, reader.uint32());
          continue;
        case 39:
          if (tag !== 314) {
            break;
          }

          message.tags = TagsContainer.decode(reader, reader.uint32());
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.customFields = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.permanentTags = PermanentTagsContainer.decode(reader, reader.uint32());
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.permanentTagsQueue = PermanentTagsContainer.decode(reader, reader.uint32());
          continue;
        case 43:
          if (tag !== 346) {
            break;
          }

          message.permanentTagsTeam = PermanentTagsContainer.decode(reader, reader.uint32());
          continue;
        case 44:
          if (tag !== 354) {
            break;
          }

          message.sipInformation = SipInformationContainer.decode(reader, reader.uint32());
          continue;
        case 45:
          if (tag !== 362) {
            break;
          }

          message.access = AccessContainer.decode(reader, reader.uint32());
          continue;
        case 46:
          if (tag !== 370) {
            break;
          }

          message.leadListMember = LeadListMember.decode(reader, reader.uint32());
          continue;
        case 90:
          if (tag !== 722) {
            break;
          }

          message.dateOfBirth = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      middleName: isSet(object.middleName) ? String(object.middleName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      nameSuffix: isSet(object.nameSuffix) ? String(object.nameSuffix) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      phone: isSet(object.phone) ? String(object.phone) : undefined,
      address: isSet(object.address) ? Address.fromJSON(object.address) : undefined,
      birthDate: isSet(object.birthDate) ? DateContainer.fromJSON(object.birthDate) : undefined,
      ssn: isSet(object.ssn) ? String(object.ssn) : undefined,
      gender: isSet(object.gender) ? genderFromJSON(object.gender) : undefined,
      okToEmail: isSet(object.okToEmail) ? Boolean(object.okToEmail) : undefined,
      profileImage: isSet(object.profileImage) ? String(object.profileImage) : undefined,
      leadDate: isSet(object.leadDate) ? String(object.leadDate) : undefined,
      marketType: isSet(object.marketType) ? marketTypeFromJSON(object.marketType) : undefined,
      leadType: isSet(object.leadType) ? leadTypeFromJSON(object.leadType) : undefined,
      agency: isSet(object.agency) ? Consumer_Agency.fromJSON(object.agency) : undefined,
      campaign: isSet(object.campaign) ? Consumer_Campaign.fromJSON(object.campaign) : undefined,
      dialer: isSet(object.dialer) ? Consumer_Dialer.fromJSON(object.dialer) : undefined,
      leadList: isSet(object.leadList) ? Consumer_LeadList.fromJSON(object.leadList) : undefined,
      leadDateTimestamp: isSet(object.leadDateTimestamp) ? Number(object.leadDateTimestamp) : undefined,
      medicare: isSet(object.medicare) ? Consumer_Medicare.fromJSON(object.medicare) : undefined,
      leadSource: isSet(object.leadSource) ? leadSourceFromJSON(object.leadSource) : undefined,
      leadSourceDetails: isSet(object.leadSourceDetails)
        ? LeadSourceDetails.fromJSON(object.leadSourceDetails)
        : undefined,
      veteranStatus: isSet(object.veteranStatus) ? consumer_VeteranStatusFromJSON(object.veteranStatus) : undefined,
      workStatus: isSet(object.workStatus) ? consumer_WorkStatusFromJSON(object.workStatus) : undefined,
      benefits: isObject(object.benefits) ? object.benefits : undefined,
      language: isSet(object.language) ? String(object.language) : undefined,
      createdAt: isSet(object.createdAt) ? Number(object.createdAt) : undefined,
      timezone: isSet(object.timezone) ? String(object.timezone) : undefined,
      identifiers: isSet(object.identifiers) ? IdentifierContainer.fromJSON(object.identifiers) : undefined,
      source: isSet(object.source) ? sourceFromJSON(object.source) : undefined,
      sourceCustom: isSet(object.sourceCustom) ? String(object.sourceCustom) : undefined,
      phoneInvalid: isSet(object.phoneInvalid) ? Boolean(object.phoneInvalid) : undefined,
      notes: isSet(object.notes) ? Note.fromJSON(object.notes) : undefined,
      marketingSource: isSet(object.marketingSource)
        ? Consumer_MarketingSource.fromJSON(object.marketingSource)
        : undefined,
      referralSource: isSet(object.referralSource)
        ? Consumer_ReferralSource.fromJSON(object.referralSource)
        : undefined,
      tags: isSet(object.tags) ? TagsContainer.fromJSON(object.tags) : undefined,
      customFields: isObject(object.customFields) ? object.customFields : undefined,
      permanentTags: isSet(object.permanentTags) ? PermanentTagsContainer.fromJSON(object.permanentTags) : undefined,
      permanentTagsQueue: isSet(object.permanentTagsQueue)
        ? PermanentTagsContainer.fromJSON(object.permanentTagsQueue)
        : undefined,
      permanentTagsTeam: isSet(object.permanentTagsTeam)
        ? PermanentTagsContainer.fromJSON(object.permanentTagsTeam)
        : undefined,
      sipInformation: isSet(object.sipInformation)
        ? SipInformationContainer.fromJSON(object.sipInformation)
        : undefined,
      access: isSet(object.access) ? AccessContainer.fromJSON(object.access) : undefined,
      leadListMember: isSet(object.leadListMember) ? LeadListMember.fromJSON(object.leadListMember) : undefined,
      dateOfBirth: isSet(object.dateOfBirth) ? fromJsonTimestamp(object.dateOfBirth) : undefined,
    };
  },

  toJSON(message: Consumer): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.middleName !== undefined && (obj.middleName = message.middleName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.nameSuffix !== undefined && (obj.nameSuffix = message.nameSuffix);
    message.email !== undefined && (obj.email = message.email);
    message.phone !== undefined && (obj.phone = message.phone);
    message.address !== undefined && (obj.address = message.address ? Address.toJSON(message.address) : undefined);
    message.birthDate !== undefined &&
      (obj.birthDate = message.birthDate ? DateContainer.toJSON(message.birthDate) : undefined);
    message.ssn !== undefined && (obj.ssn = message.ssn);
    message.gender !== undefined &&
      (obj.gender = message.gender !== undefined ? genderToJSON(message.gender) : undefined);
    message.okToEmail !== undefined && (obj.okToEmail = message.okToEmail);
    message.profileImage !== undefined && (obj.profileImage = message.profileImage);
    message.leadDate !== undefined && (obj.leadDate = message.leadDate);
    message.marketType !== undefined &&
      (obj.marketType = message.marketType !== undefined ? marketTypeToJSON(message.marketType) : undefined);
    message.leadType !== undefined &&
      (obj.leadType = message.leadType !== undefined ? leadTypeToJSON(message.leadType) : undefined);
    message.agency !== undefined && (obj.agency = message.agency ? Consumer_Agency.toJSON(message.agency) : undefined);
    message.campaign !== undefined &&
      (obj.campaign = message.campaign ? Consumer_Campaign.toJSON(message.campaign) : undefined);
    message.dialer !== undefined && (obj.dialer = message.dialer ? Consumer_Dialer.toJSON(message.dialer) : undefined);
    message.leadList !== undefined &&
      (obj.leadList = message.leadList ? Consumer_LeadList.toJSON(message.leadList) : undefined);
    message.leadDateTimestamp !== undefined && (obj.leadDateTimestamp = Math.round(message.leadDateTimestamp));
    message.medicare !== undefined &&
      (obj.medicare = message.medicare ? Consumer_Medicare.toJSON(message.medicare) : undefined);
    message.leadSource !== undefined &&
      (obj.leadSource = message.leadSource !== undefined ? leadSourceToJSON(message.leadSource) : undefined);
    message.leadSourceDetails !== undefined && (obj.leadSourceDetails = message.leadSourceDetails
      ? LeadSourceDetails.toJSON(message.leadSourceDetails)
      : undefined);
    message.veteranStatus !== undefined && (obj.veteranStatus = message.veteranStatus !== undefined
      ? consumer_VeteranStatusToJSON(message.veteranStatus)
      : undefined);
    message.workStatus !== undefined &&
      (obj.workStatus = message.workStatus !== undefined ? consumer_WorkStatusToJSON(message.workStatus) : undefined);
    message.benefits !== undefined && (obj.benefits = message.benefits);
    message.language !== undefined && (obj.language = message.language);
    message.createdAt !== undefined && (obj.createdAt = Math.round(message.createdAt));
    message.timezone !== undefined && (obj.timezone = message.timezone);
    message.identifiers !== undefined &&
      (obj.identifiers = message.identifiers ? IdentifierContainer.toJSON(message.identifiers) : undefined);
    message.source !== undefined &&
      (obj.source = message.source !== undefined ? sourceToJSON(message.source) : undefined);
    message.sourceCustom !== undefined && (obj.sourceCustom = message.sourceCustom);
    message.phoneInvalid !== undefined && (obj.phoneInvalid = message.phoneInvalid);
    message.notes !== undefined && (obj.notes = message.notes ? Note.toJSON(message.notes) : undefined);
    message.marketingSource !== undefined && (obj.marketingSource = message.marketingSource
      ? Consumer_MarketingSource.toJSON(message.marketingSource)
      : undefined);
    message.referralSource !== undefined &&
      (obj.referralSource = message.referralSource
        ? Consumer_ReferralSource.toJSON(message.referralSource)
        : undefined);
    message.tags !== undefined && (obj.tags = message.tags ? TagsContainer.toJSON(message.tags) : undefined);
    message.customFields !== undefined && (obj.customFields = message.customFields);
    message.permanentTags !== undefined &&
      (obj.permanentTags = message.permanentTags ? PermanentTagsContainer.toJSON(message.permanentTags) : undefined);
    message.permanentTagsQueue !== undefined && (obj.permanentTagsQueue = message.permanentTagsQueue
      ? PermanentTagsContainer.toJSON(message.permanentTagsQueue)
      : undefined);
    message.permanentTagsTeam !== undefined && (obj.permanentTagsTeam = message.permanentTagsTeam
      ? PermanentTagsContainer.toJSON(message.permanentTagsTeam)
      : undefined);
    message.sipInformation !== undefined &&
      (obj.sipInformation = message.sipInformation
        ? SipInformationContainer.toJSON(message.sipInformation)
        : undefined);
    message.access !== undefined && (obj.access = message.access ? AccessContainer.toJSON(message.access) : undefined);
    message.leadListMember !== undefined &&
      (obj.leadListMember = message.leadListMember ? LeadListMember.toJSON(message.leadListMember) : undefined);
    message.dateOfBirth !== undefined && (obj.dateOfBirth = message.dateOfBirth.toISOString());
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer>, I>>(base?: I): Consumer {
    return Consumer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer>, I>>(object: I): Consumer {
    const message = createBaseConsumer();
    message.id = object.id ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.middleName = object.middleName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.nameSuffix = object.nameSuffix ?? undefined;
    message.email = object.email ?? undefined;
    message.phone = object.phone ?? undefined;
    message.address = (object.address !== undefined && object.address !== null)
      ? Address.fromPartial(object.address)
      : undefined;
    message.birthDate = (object.birthDate !== undefined && object.birthDate !== null)
      ? DateContainer.fromPartial(object.birthDate)
      : undefined;
    message.ssn = object.ssn ?? undefined;
    message.gender = object.gender ?? undefined;
    message.okToEmail = object.okToEmail ?? undefined;
    message.profileImage = object.profileImage ?? undefined;
    message.leadDate = object.leadDate ?? undefined;
    message.marketType = object.marketType ?? undefined;
    message.leadType = object.leadType ?? undefined;
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? Consumer_Agency.fromPartial(object.agency)
      : undefined;
    message.campaign = (object.campaign !== undefined && object.campaign !== null)
      ? Consumer_Campaign.fromPartial(object.campaign)
      : undefined;
    message.dialer = (object.dialer !== undefined && object.dialer !== null)
      ? Consumer_Dialer.fromPartial(object.dialer)
      : undefined;
    message.leadList = (object.leadList !== undefined && object.leadList !== null)
      ? Consumer_LeadList.fromPartial(object.leadList)
      : undefined;
    message.leadDateTimestamp = object.leadDateTimestamp ?? undefined;
    message.medicare = (object.medicare !== undefined && object.medicare !== null)
      ? Consumer_Medicare.fromPartial(object.medicare)
      : undefined;
    message.leadSource = object.leadSource ?? undefined;
    message.leadSourceDetails = (object.leadSourceDetails !== undefined && object.leadSourceDetails !== null)
      ? LeadSourceDetails.fromPartial(object.leadSourceDetails)
      : undefined;
    message.veteranStatus = object.veteranStatus ?? undefined;
    message.workStatus = object.workStatus ?? undefined;
    message.benefits = object.benefits ?? undefined;
    message.language = object.language ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.timezone = object.timezone ?? undefined;
    message.identifiers = (object.identifiers !== undefined && object.identifiers !== null)
      ? IdentifierContainer.fromPartial(object.identifiers)
      : undefined;
    message.source = object.source ?? undefined;
    message.sourceCustom = object.sourceCustom ?? undefined;
    message.phoneInvalid = object.phoneInvalid ?? undefined;
    message.notes = (object.notes !== undefined && object.notes !== null) ? Note.fromPartial(object.notes) : undefined;
    message.marketingSource = (object.marketingSource !== undefined && object.marketingSource !== null)
      ? Consumer_MarketingSource.fromPartial(object.marketingSource)
      : undefined;
    message.referralSource = (object.referralSource !== undefined && object.referralSource !== null)
      ? Consumer_ReferralSource.fromPartial(object.referralSource)
      : undefined;
    message.tags = (object.tags !== undefined && object.tags !== null)
      ? TagsContainer.fromPartial(object.tags)
      : undefined;
    message.customFields = object.customFields ?? undefined;
    message.permanentTags = (object.permanentTags !== undefined && object.permanentTags !== null)
      ? PermanentTagsContainer.fromPartial(object.permanentTags)
      : undefined;
    message.permanentTagsQueue = (object.permanentTagsQueue !== undefined && object.permanentTagsQueue !== null)
      ? PermanentTagsContainer.fromPartial(object.permanentTagsQueue)
      : undefined;
    message.permanentTagsTeam = (object.permanentTagsTeam !== undefined && object.permanentTagsTeam !== null)
      ? PermanentTagsContainer.fromPartial(object.permanentTagsTeam)
      : undefined;
    message.sipInformation = (object.sipInformation !== undefined && object.sipInformation !== null)
      ? SipInformationContainer.fromPartial(object.sipInformation)
      : undefined;
    message.access = (object.access !== undefined && object.access !== null)
      ? AccessContainer.fromPartial(object.access)
      : undefined;
    message.leadListMember = (object.leadListMember !== undefined && object.leadListMember !== null)
      ? LeadListMember.fromPartial(object.leadListMember)
      : undefined;
    message.dateOfBirth = object.dateOfBirth ?? undefined;
    return message;
  },
};

function createBaseConsumer_MarketingSource(): Consumer_MarketingSource {
  return { source: undefined, utmParameters: undefined, landingPage: undefined };
}

export const Consumer_MarketingSource = {
  encode(message: Consumer_MarketingSource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.source !== undefined) {
      writer.uint32(10).string(message.source);
    }
    if (message.utmParameters !== undefined) {
      writer.uint32(18).string(message.utmParameters);
    }
    if (message.landingPage !== undefined) {
      writer.uint32(26).string(message.landingPage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer_MarketingSource {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer_MarketingSource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.source = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.utmParameters = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.landingPage = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer_MarketingSource {
    return {
      source: isSet(object.source) ? String(object.source) : undefined,
      utmParameters: isSet(object.utmParameters) ? String(object.utmParameters) : undefined,
      landingPage: isSet(object.landingPage) ? String(object.landingPage) : undefined,
    };
  },

  toJSON(message: Consumer_MarketingSource): unknown {
    const obj: any = {};
    message.source !== undefined && (obj.source = message.source);
    message.utmParameters !== undefined && (obj.utmParameters = message.utmParameters);
    message.landingPage !== undefined && (obj.landingPage = message.landingPage);
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer_MarketingSource>, I>>(base?: I): Consumer_MarketingSource {
    return Consumer_MarketingSource.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer_MarketingSource>, I>>(object: I): Consumer_MarketingSource {
    const message = createBaseConsumer_MarketingSource();
    message.source = object.source ?? undefined;
    message.utmParameters = object.utmParameters ?? undefined;
    message.landingPage = object.landingPage ?? undefined;
    return message;
  },
};

function createBaseConsumer_ReferralSource(): Consumer_ReferralSource {
  return { source: undefined };
}

export const Consumer_ReferralSource = {
  encode(message: Consumer_ReferralSource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.source !== undefined) {
      writer.uint32(10).string(message.source);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer_ReferralSource {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer_ReferralSource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.source = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer_ReferralSource {
    return { source: isSet(object.source) ? String(object.source) : undefined };
  },

  toJSON(message: Consumer_ReferralSource): unknown {
    const obj: any = {};
    message.source !== undefined && (obj.source = message.source);
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer_ReferralSource>, I>>(base?: I): Consumer_ReferralSource {
    return Consumer_ReferralSource.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer_ReferralSource>, I>>(object: I): Consumer_ReferralSource {
    const message = createBaseConsumer_ReferralSource();
    message.source = object.source ?? undefined;
    return message;
  },
};

function createBaseConsumer_Dialer(): Consumer_Dialer {
  return {
    resultCategory: undefined,
    result: undefined,
    resultCategoryAgent: undefined,
    resultAgent: undefined,
    lastCallTry: undefined,
    lastCallConnected: undefined,
    lastCallEnded: undefined,
    lastAddedToHopper: undefined,
    lastCallState: undefined,
    from: undefined,
    totalCalls: undefined,
    totalCallsInboundAnswered: undefined,
    lastCallInboundAnswered: undefined,
    totalCallsInboundBillable: undefined,
    lastCallInboundBillable: undefined,
    totalCallsInbound: undefined,
    lastCallInbound: undefined,
    totalCallsOutbound: undefined,
    lastCallOutbound: undefined,
    enrollmentCode: undefined,
    incoming: undefined,
    tempLead: undefined,
    initialQueue: undefined,
  };
}

export const Consumer_Dialer = {
  encode(message: Consumer_Dialer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.resultCategory !== undefined) {
      writer.uint32(8).int32(message.resultCategory);
    }
    if (message.result !== undefined) {
      writer.uint32(16).int32(message.result);
    }
    if (message.resultCategoryAgent !== undefined) {
      writer.uint32(24).int32(message.resultCategoryAgent);
    }
    if (message.resultAgent !== undefined) {
      writer.uint32(32).int32(message.resultAgent);
    }
    if (message.lastCallTry !== undefined) {
      writer.uint32(40).int64(message.lastCallTry);
    }
    if (message.lastCallConnected !== undefined) {
      writer.uint32(48).int64(message.lastCallConnected);
    }
    if (message.lastCallEnded !== undefined) {
      writer.uint32(56).int64(message.lastCallEnded);
    }
    if (message.lastAddedToHopper !== undefined) {
      writer.uint32(64).int64(message.lastAddedToHopper);
    }
    if (message.lastCallState !== undefined) {
      writer.uint32(72).int32(message.lastCallState);
    }
    if (message.from !== undefined) {
      writer.uint32(82).string(message.from);
    }
    if (message.totalCalls !== undefined) {
      writer.uint32(88).int64(message.totalCalls);
    }
    if (message.totalCallsInboundAnswered !== undefined) {
      writer.uint32(96).int32(message.totalCallsInboundAnswered);
    }
    if (message.lastCallInboundAnswered !== undefined) {
      writer.uint32(104).int64(message.lastCallInboundAnswered);
    }
    if (message.totalCallsInboundBillable !== undefined) {
      writer.uint32(112).int32(message.totalCallsInboundBillable);
    }
    if (message.lastCallInboundBillable !== undefined) {
      writer.uint32(120).int64(message.lastCallInboundBillable);
    }
    if (message.totalCallsInbound !== undefined) {
      writer.uint32(128).int32(message.totalCallsInbound);
    }
    if (message.lastCallInbound !== undefined) {
      writer.uint32(136).int64(message.lastCallInbound);
    }
    if (message.totalCallsOutbound !== undefined) {
      writer.uint32(144).int32(message.totalCallsOutbound);
    }
    if (message.lastCallOutbound !== undefined) {
      writer.uint32(152).int64(message.lastCallOutbound);
    }
    if (message.enrollmentCode !== undefined) {
      EnrollmentCodeContainer.encode(message.enrollmentCode, writer.uint32(162).fork()).ldelim();
    }
    if (message.incoming !== undefined) {
      Consumer_Dialer_IncomingContainer.encode(message.incoming, writer.uint32(170).fork()).ldelim();
    }
    if (message.tempLead !== undefined) {
      Consumer_Dialer_TempLead.encode(message.tempLead, writer.uint32(178).fork()).ldelim();
    }
    if (message.initialQueue !== undefined) {
      ReferenceContainer.encode(message.initialQueue, writer.uint32(186).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer_Dialer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer_Dialer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.resultCategory = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.result = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.resultCategoryAgent = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.resultAgent = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.lastCallTry = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.lastCallConnected = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.lastCallEnded = longToNumber(reader.int64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.lastAddedToHopper = longToNumber(reader.int64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.lastCallState = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.from = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.totalCalls = longToNumber(reader.int64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.totalCallsInboundAnswered = reader.int32();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.lastCallInboundAnswered = longToNumber(reader.int64() as Long);
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.totalCallsInboundBillable = reader.int32();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.lastCallInboundBillable = longToNumber(reader.int64() as Long);
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.totalCallsInbound = reader.int32();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.lastCallInbound = longToNumber(reader.int64() as Long);
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.totalCallsOutbound = reader.int32();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.lastCallOutbound = longToNumber(reader.int64() as Long);
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.enrollmentCode = EnrollmentCodeContainer.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.incoming = Consumer_Dialer_IncomingContainer.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.tempLead = Consumer_Dialer_TempLead.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.initialQueue = ReferenceContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer_Dialer {
    return {
      resultCategory: isSet(object.resultCategory)
        ? consumer_Dialer_ResultCategoryFromJSON(object.resultCategory)
        : undefined,
      result: isSet(object.result) ? consumer_Dialer_ResultFromJSON(object.result) : undefined,
      resultCategoryAgent: isSet(object.resultCategoryAgent)
        ? consumer_Dialer_ResultCategoryFromJSON(object.resultCategoryAgent)
        : undefined,
      resultAgent: isSet(object.resultAgent) ? consumer_Dialer_ResultFromJSON(object.resultAgent) : undefined,
      lastCallTry: isSet(object.lastCallTry) ? Number(object.lastCallTry) : undefined,
      lastCallConnected: isSet(object.lastCallConnected) ? Number(object.lastCallConnected) : undefined,
      lastCallEnded: isSet(object.lastCallEnded) ? Number(object.lastCallEnded) : undefined,
      lastAddedToHopper: isSet(object.lastAddedToHopper) ? Number(object.lastAddedToHopper) : undefined,
      lastCallState: isSet(object.lastCallState) ? consumer_Dialer_CallStateFromJSON(object.lastCallState) : undefined,
      from: isSet(object.from) ? String(object.from) : undefined,
      totalCalls: isSet(object.totalCalls) ? Number(object.totalCalls) : undefined,
      totalCallsInboundAnswered: isSet(object.totalCallsInboundAnswered)
        ? Number(object.totalCallsInboundAnswered)
        : undefined,
      lastCallInboundAnswered: isSet(object.lastCallInboundAnswered)
        ? Number(object.lastCallInboundAnswered)
        : undefined,
      totalCallsInboundBillable: isSet(object.totalCallsInboundBillable)
        ? Number(object.totalCallsInboundBillable)
        : undefined,
      lastCallInboundBillable: isSet(object.lastCallInboundBillable)
        ? Number(object.lastCallInboundBillable)
        : undefined,
      totalCallsInbound: isSet(object.totalCallsInbound) ? Number(object.totalCallsInbound) : undefined,
      lastCallInbound: isSet(object.lastCallInbound) ? Number(object.lastCallInbound) : undefined,
      totalCallsOutbound: isSet(object.totalCallsOutbound) ? Number(object.totalCallsOutbound) : undefined,
      lastCallOutbound: isSet(object.lastCallOutbound) ? Number(object.lastCallOutbound) : undefined,
      enrollmentCode: isSet(object.enrollmentCode)
        ? EnrollmentCodeContainer.fromJSON(object.enrollmentCode)
        : undefined,
      incoming: isSet(object.incoming) ? Consumer_Dialer_IncomingContainer.fromJSON(object.incoming) : undefined,
      tempLead: isSet(object.tempLead) ? Consumer_Dialer_TempLead.fromJSON(object.tempLead) : undefined,
      initialQueue: isSet(object.initialQueue) ? ReferenceContainer.fromJSON(object.initialQueue) : undefined,
    };
  },

  toJSON(message: Consumer_Dialer): unknown {
    const obj: any = {};
    message.resultCategory !== undefined && (obj.resultCategory = message.resultCategory !== undefined
      ? consumer_Dialer_ResultCategoryToJSON(message.resultCategory)
      : undefined);
    message.result !== undefined &&
      (obj.result = message.result !== undefined ? consumer_Dialer_ResultToJSON(message.result) : undefined);
    message.resultCategoryAgent !== undefined && (obj.resultCategoryAgent = message.resultCategoryAgent !== undefined
      ? consumer_Dialer_ResultCategoryToJSON(message.resultCategoryAgent)
      : undefined);
    message.resultAgent !== undefined && (obj.resultAgent = message.resultAgent !== undefined
      ? consumer_Dialer_ResultToJSON(message.resultAgent)
      : undefined);
    message.lastCallTry !== undefined && (obj.lastCallTry = Math.round(message.lastCallTry));
    message.lastCallConnected !== undefined && (obj.lastCallConnected = Math.round(message.lastCallConnected));
    message.lastCallEnded !== undefined && (obj.lastCallEnded = Math.round(message.lastCallEnded));
    message.lastAddedToHopper !== undefined && (obj.lastAddedToHopper = Math.round(message.lastAddedToHopper));
    message.lastCallState !== undefined && (obj.lastCallState = message.lastCallState !== undefined
      ? consumer_Dialer_CallStateToJSON(message.lastCallState)
      : undefined);
    message.from !== undefined && (obj.from = message.from);
    message.totalCalls !== undefined && (obj.totalCalls = Math.round(message.totalCalls));
    message.totalCallsInboundAnswered !== undefined &&
      (obj.totalCallsInboundAnswered = Math.round(message.totalCallsInboundAnswered));
    message.lastCallInboundAnswered !== undefined &&
      (obj.lastCallInboundAnswered = Math.round(message.lastCallInboundAnswered));
    message.totalCallsInboundBillable !== undefined &&
      (obj.totalCallsInboundBillable = Math.round(message.totalCallsInboundBillable));
    message.lastCallInboundBillable !== undefined &&
      (obj.lastCallInboundBillable = Math.round(message.lastCallInboundBillable));
    message.totalCallsInbound !== undefined && (obj.totalCallsInbound = Math.round(message.totalCallsInbound));
    message.lastCallInbound !== undefined && (obj.lastCallInbound = Math.round(message.lastCallInbound));
    message.totalCallsOutbound !== undefined && (obj.totalCallsOutbound = Math.round(message.totalCallsOutbound));
    message.lastCallOutbound !== undefined && (obj.lastCallOutbound = Math.round(message.lastCallOutbound));
    message.enrollmentCode !== undefined &&
      (obj.enrollmentCode = message.enrollmentCode
        ? EnrollmentCodeContainer.toJSON(message.enrollmentCode)
        : undefined);
    message.incoming !== undefined &&
      (obj.incoming = message.incoming ? Consumer_Dialer_IncomingContainer.toJSON(message.incoming) : undefined);
    message.tempLead !== undefined &&
      (obj.tempLead = message.tempLead ? Consumer_Dialer_TempLead.toJSON(message.tempLead) : undefined);
    message.initialQueue !== undefined &&
      (obj.initialQueue = message.initialQueue ? ReferenceContainer.toJSON(message.initialQueue) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer_Dialer>, I>>(base?: I): Consumer_Dialer {
    return Consumer_Dialer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer_Dialer>, I>>(object: I): Consumer_Dialer {
    const message = createBaseConsumer_Dialer();
    message.resultCategory = object.resultCategory ?? undefined;
    message.result = object.result ?? undefined;
    message.resultCategoryAgent = object.resultCategoryAgent ?? undefined;
    message.resultAgent = object.resultAgent ?? undefined;
    message.lastCallTry = object.lastCallTry ?? undefined;
    message.lastCallConnected = object.lastCallConnected ?? undefined;
    message.lastCallEnded = object.lastCallEnded ?? undefined;
    message.lastAddedToHopper = object.lastAddedToHopper ?? undefined;
    message.lastCallState = object.lastCallState ?? undefined;
    message.from = object.from ?? undefined;
    message.totalCalls = object.totalCalls ?? undefined;
    message.totalCallsInboundAnswered = object.totalCallsInboundAnswered ?? undefined;
    message.lastCallInboundAnswered = object.lastCallInboundAnswered ?? undefined;
    message.totalCallsInboundBillable = object.totalCallsInboundBillable ?? undefined;
    message.lastCallInboundBillable = object.lastCallInboundBillable ?? undefined;
    message.totalCallsInbound = object.totalCallsInbound ?? undefined;
    message.lastCallInbound = object.lastCallInbound ?? undefined;
    message.totalCallsOutbound = object.totalCallsOutbound ?? undefined;
    message.lastCallOutbound = object.lastCallOutbound ?? undefined;
    message.enrollmentCode = (object.enrollmentCode !== undefined && object.enrollmentCode !== null)
      ? EnrollmentCodeContainer.fromPartial(object.enrollmentCode)
      : undefined;
    message.incoming = (object.incoming !== undefined && object.incoming !== null)
      ? Consumer_Dialer_IncomingContainer.fromPartial(object.incoming)
      : undefined;
    message.tempLead = (object.tempLead !== undefined && object.tempLead !== null)
      ? Consumer_Dialer_TempLead.fromPartial(object.tempLead)
      : undefined;
    message.initialQueue = (object.initialQueue !== undefined && object.initialQueue !== null)
      ? ReferenceContainer.fromPartial(object.initialQueue)
      : undefined;
    return message;
  },
};

function createBaseConsumer_Dialer_TempLead(): Consumer_Dialer_TempLead {
  return { isTempLead: undefined, queue: undefined, createdAt: undefined };
}

export const Consumer_Dialer_TempLead = {
  encode(message: Consumer_Dialer_TempLead, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isTempLead !== undefined) {
      writer.uint32(8).bool(message.isTempLead);
    }
    if (message.queue !== undefined) {
      ReferenceContainer.encode(message.queue, writer.uint32(18).fork()).ldelim();
    }
    if (message.createdAt !== undefined) {
      writer.uint32(24).int64(message.createdAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer_Dialer_TempLead {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer_Dialer_TempLead();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isTempLead = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.queue = ReferenceContainer.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.createdAt = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer_Dialer_TempLead {
    return {
      isTempLead: isSet(object.isTempLead) ? Boolean(object.isTempLead) : undefined,
      queue: isSet(object.queue) ? ReferenceContainer.fromJSON(object.queue) : undefined,
      createdAt: isSet(object.createdAt) ? Number(object.createdAt) : undefined,
    };
  },

  toJSON(message: Consumer_Dialer_TempLead): unknown {
    const obj: any = {};
    message.isTempLead !== undefined && (obj.isTempLead = message.isTempLead);
    message.queue !== undefined && (obj.queue = message.queue ? ReferenceContainer.toJSON(message.queue) : undefined);
    message.createdAt !== undefined && (obj.createdAt = Math.round(message.createdAt));
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer_Dialer_TempLead>, I>>(base?: I): Consumer_Dialer_TempLead {
    return Consumer_Dialer_TempLead.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer_Dialer_TempLead>, I>>(object: I): Consumer_Dialer_TempLead {
    const message = createBaseConsumer_Dialer_TempLead();
    message.isTempLead = object.isTempLead ?? undefined;
    message.queue = (object.queue !== undefined && object.queue !== null)
      ? ReferenceContainer.fromPartial(object.queue)
      : undefined;
    message.createdAt = object.createdAt ?? undefined;
    return message;
  },
};

function createBaseConsumer_Dialer_IncomingContainer(): Consumer_Dialer_IncomingContainer {
  return { participants: {}, incomingStep1: undefined, flow: undefined, related: undefined };
}

export const Consumer_Dialer_IncomingContainer = {
  encode(message: Consumer_Dialer_IncomingContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.participants).forEach(([key, value]) => {
      Consumer_Dialer_IncomingContainer_ParticipantsEntry.encode({ key: key as any, value }, writer.uint32(10).fork())
        .ldelim();
    });
    if (message.incomingStep1 !== undefined) {
      ConsumerDialerFlowStep.encode(message.incomingStep1, writer.uint32(18).fork()).ldelim();
    }
    if (message.flow !== undefined) {
      Consumer_Dialer_IncomingContainer_DialerCallFlow.encode(message.flow, writer.uint32(26).fork()).ldelim();
    }
    if (message.related !== undefined) {
      Consumer_Dialer_IncomingContainer_RelatedContainer.encode(message.related, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer_Dialer_IncomingContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer_Dialer_IncomingContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = Consumer_Dialer_IncomingContainer_ParticipantsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.participants[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.incomingStep1 = ConsumerDialerFlowStep.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.flow = Consumer_Dialer_IncomingContainer_DialerCallFlow.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.related = Consumer_Dialer_IncomingContainer_RelatedContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer_Dialer_IncomingContainer {
    return {
      participants: isObject(object.participants)
        ? Object.entries(object.participants).reduce<{ [key: string]: Consumer_Dialer_IncomingContainer_Participant }>(
          (acc, [key, value]) => {
            acc[key] = Consumer_Dialer_IncomingContainer_Participant.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
      incomingStep1: isSet(object.incomingStep1) ? ConsumerDialerFlowStep.fromJSON(object.incomingStep1) : undefined,
      flow: isSet(object.flow) ? Consumer_Dialer_IncomingContainer_DialerCallFlow.fromJSON(object.flow) : undefined,
      related: isSet(object.related)
        ? Consumer_Dialer_IncomingContainer_RelatedContainer.fromJSON(object.related)
        : undefined,
    };
  },

  toJSON(message: Consumer_Dialer_IncomingContainer): unknown {
    const obj: any = {};
    obj.participants = {};
    if (message.participants) {
      Object.entries(message.participants).forEach(([k, v]) => {
        obj.participants[k] = Consumer_Dialer_IncomingContainer_Participant.toJSON(v);
      });
    }
    message.incomingStep1 !== undefined &&
      (obj.incomingStep1 = message.incomingStep1 ? ConsumerDialerFlowStep.toJSON(message.incomingStep1) : undefined);
    message.flow !== undefined &&
      (obj.flow = message.flow ? Consumer_Dialer_IncomingContainer_DialerCallFlow.toJSON(message.flow) : undefined);
    message.related !== undefined && (obj.related = message.related
      ? Consumer_Dialer_IncomingContainer_RelatedContainer.toJSON(message.related)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer_Dialer_IncomingContainer>, I>>(
    base?: I,
  ): Consumer_Dialer_IncomingContainer {
    return Consumer_Dialer_IncomingContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer_Dialer_IncomingContainer>, I>>(
    object: I,
  ): Consumer_Dialer_IncomingContainer {
    const message = createBaseConsumer_Dialer_IncomingContainer();
    message.participants = Object.entries(object.participants ?? {}).reduce<
      { [key: string]: Consumer_Dialer_IncomingContainer_Participant }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Consumer_Dialer_IncomingContainer_Participant.fromPartial(value);
      }
      return acc;
    }, {});
    message.incomingStep1 = (object.incomingStep1 !== undefined && object.incomingStep1 !== null)
      ? ConsumerDialerFlowStep.fromPartial(object.incomingStep1)
      : undefined;
    message.flow = (object.flow !== undefined && object.flow !== null)
      ? Consumer_Dialer_IncomingContainer_DialerCallFlow.fromPartial(object.flow)
      : undefined;
    message.related = (object.related !== undefined && object.related !== null)
      ? Consumer_Dialer_IncomingContainer_RelatedContainer.fromPartial(object.related)
      : undefined;
    return message;
  },
};

function createBaseConsumer_Dialer_IncomingContainer_ParticipantsEntry(): Consumer_Dialer_IncomingContainer_ParticipantsEntry {
  return { key: "", value: undefined };
}

export const Consumer_Dialer_IncomingContainer_ParticipantsEntry = {
  encode(
    message: Consumer_Dialer_IncomingContainer_ParticipantsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Consumer_Dialer_IncomingContainer_Participant.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer_Dialer_IncomingContainer_ParticipantsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer_Dialer_IncomingContainer_ParticipantsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Consumer_Dialer_IncomingContainer_Participant.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer_Dialer_IncomingContainer_ParticipantsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? Consumer_Dialer_IncomingContainer_Participant.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: Consumer_Dialer_IncomingContainer_ParticipantsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? Consumer_Dialer_IncomingContainer_Participant.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer_Dialer_IncomingContainer_ParticipantsEntry>, I>>(
    base?: I,
  ): Consumer_Dialer_IncomingContainer_ParticipantsEntry {
    return Consumer_Dialer_IncomingContainer_ParticipantsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer_Dialer_IncomingContainer_ParticipantsEntry>, I>>(
    object: I,
  ): Consumer_Dialer_IncomingContainer_ParticipantsEntry {
    const message = createBaseConsumer_Dialer_IncomingContainer_ParticipantsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? Consumer_Dialer_IncomingContainer_Participant.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseConsumer_Dialer_IncomingContainer_RelatedContainer(): Consumer_Dialer_IncomingContainer_RelatedContainer {
  return { calls: [] };
}

export const Consumer_Dialer_IncomingContainer_RelatedContainer = {
  encode(
    message: Consumer_Dialer_IncomingContainer_RelatedContainer,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.calls) {
      Consumer_Dialer_IncomingContainer_RelatedCall.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer_Dialer_IncomingContainer_RelatedContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer_Dialer_IncomingContainer_RelatedContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.calls.push(Consumer_Dialer_IncomingContainer_RelatedCall.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer_Dialer_IncomingContainer_RelatedContainer {
    return {
      calls: Array.isArray(object?.calls)
        ? object.calls.map((e: any) => Consumer_Dialer_IncomingContainer_RelatedCall.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Consumer_Dialer_IncomingContainer_RelatedContainer): unknown {
    const obj: any = {};
    if (message.calls) {
      obj.calls = message.calls.map((e) => e ? Consumer_Dialer_IncomingContainer_RelatedCall.toJSON(e) : undefined);
    } else {
      obj.calls = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer_Dialer_IncomingContainer_RelatedContainer>, I>>(
    base?: I,
  ): Consumer_Dialer_IncomingContainer_RelatedContainer {
    return Consumer_Dialer_IncomingContainer_RelatedContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer_Dialer_IncomingContainer_RelatedContainer>, I>>(
    object: I,
  ): Consumer_Dialer_IncomingContainer_RelatedContainer {
    const message = createBaseConsumer_Dialer_IncomingContainer_RelatedContainer();
    message.calls = object.calls?.map((e) => Consumer_Dialer_IncomingContainer_RelatedCall.fromPartial(e)) || [];
    return message;
  },
};

function createBaseConsumer_Dialer_IncomingContainer_RelatedCall(): Consumer_Dialer_IncomingContainer_RelatedCall {
  return {
    uuid: undefined,
    lead: undefined,
    agency: undefined,
    queue: undefined,
    isParent: undefined,
    isChild: undefined,
  };
}

export const Consumer_Dialer_IncomingContainer_RelatedCall = {
  encode(message: Consumer_Dialer_IncomingContainer_RelatedCall, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuid !== undefined) {
      writer.uint32(10).string(message.uuid);
    }
    if (message.lead !== undefined) {
      UserIdContainer.encode(message.lead, writer.uint32(18).fork()).ldelim();
    }
    if (message.agency !== undefined) {
      UserIdContainer.encode(message.agency, writer.uint32(26).fork()).ldelim();
    }
    if (message.queue !== undefined) {
      UserIdContainer.encode(message.queue, writer.uint32(34).fork()).ldelim();
    }
    if (message.isParent !== undefined) {
      writer.uint32(40).bool(message.isParent);
    }
    if (message.isChild !== undefined) {
      writer.uint32(48).bool(message.isChild);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer_Dialer_IncomingContainer_RelatedCall {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer_Dialer_IncomingContainer_RelatedCall();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lead = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.agency = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.queue = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isParent = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isChild = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer_Dialer_IncomingContainer_RelatedCall {
    return {
      uuid: isSet(object.uuid) ? String(object.uuid) : undefined,
      lead: isSet(object.lead) ? UserIdContainer.fromJSON(object.lead) : undefined,
      agency: isSet(object.agency) ? UserIdContainer.fromJSON(object.agency) : undefined,
      queue: isSet(object.queue) ? UserIdContainer.fromJSON(object.queue) : undefined,
      isParent: isSet(object.isParent) ? Boolean(object.isParent) : undefined,
      isChild: isSet(object.isChild) ? Boolean(object.isChild) : undefined,
    };
  },

  toJSON(message: Consumer_Dialer_IncomingContainer_RelatedCall): unknown {
    const obj: any = {};
    message.uuid !== undefined && (obj.uuid = message.uuid);
    message.lead !== undefined && (obj.lead = message.lead ? UserIdContainer.toJSON(message.lead) : undefined);
    message.agency !== undefined && (obj.agency = message.agency ? UserIdContainer.toJSON(message.agency) : undefined);
    message.queue !== undefined && (obj.queue = message.queue ? UserIdContainer.toJSON(message.queue) : undefined);
    message.isParent !== undefined && (obj.isParent = message.isParent);
    message.isChild !== undefined && (obj.isChild = message.isChild);
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer_Dialer_IncomingContainer_RelatedCall>, I>>(
    base?: I,
  ): Consumer_Dialer_IncomingContainer_RelatedCall {
    return Consumer_Dialer_IncomingContainer_RelatedCall.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer_Dialer_IncomingContainer_RelatedCall>, I>>(
    object: I,
  ): Consumer_Dialer_IncomingContainer_RelatedCall {
    const message = createBaseConsumer_Dialer_IncomingContainer_RelatedCall();
    message.uuid = object.uuid ?? undefined;
    message.lead = (object.lead !== undefined && object.lead !== null)
      ? UserIdContainer.fromPartial(object.lead)
      : undefined;
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? UserIdContainer.fromPartial(object.agency)
      : undefined;
    message.queue = (object.queue !== undefined && object.queue !== null)
      ? UserIdContainer.fromPartial(object.queue)
      : undefined;
    message.isParent = object.isParent ?? undefined;
    message.isChild = object.isChild ?? undefined;
    return message;
  },
};

function createBaseConsumer_Dialer_IncomingContainer_DialerCallFlow(): Consumer_Dialer_IncomingContainer_DialerCallFlow {
  return {
    isInitial: undefined,
    initialId: undefined,
    isPassthrough: undefined,
    passthroughId: undefined,
    isFinal: undefined,
    finalId: undefined,
    list: [],
  };
}

export const Consumer_Dialer_IncomingContainer_DialerCallFlow = {
  encode(
    message: Consumer_Dialer_IncomingContainer_DialerCallFlow,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.isInitial !== undefined) {
      writer.uint32(8).bool(message.isInitial);
    }
    if (message.initialId !== undefined) {
      writer.uint32(18).string(message.initialId);
    }
    if (message.isPassthrough !== undefined) {
      writer.uint32(24).bool(message.isPassthrough);
    }
    if (message.passthroughId !== undefined) {
      writer.uint32(34).string(message.passthroughId);
    }
    if (message.isFinal !== undefined) {
      writer.uint32(40).bool(message.isFinal);
    }
    if (message.finalId !== undefined) {
      writer.uint32(50).string(message.finalId);
    }
    for (const v of message.list) {
      ConsumerDialerFlowStep.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer_Dialer_IncomingContainer_DialerCallFlow {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer_Dialer_IncomingContainer_DialerCallFlow();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isInitial = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.initialId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isPassthrough = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.passthroughId = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isFinal = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.finalId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.list.push(ConsumerDialerFlowStep.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer_Dialer_IncomingContainer_DialerCallFlow {
    return {
      isInitial: isSet(object.isInitial) ? Boolean(object.isInitial) : undefined,
      initialId: isSet(object.initialId) ? String(object.initialId) : undefined,
      isPassthrough: isSet(object.isPassthrough) ? Boolean(object.isPassthrough) : undefined,
      passthroughId: isSet(object.passthroughId) ? String(object.passthroughId) : undefined,
      isFinal: isSet(object.isFinal) ? Boolean(object.isFinal) : undefined,
      finalId: isSet(object.finalId) ? String(object.finalId) : undefined,
      list: Array.isArray(object?.list) ? object.list.map((e: any) => ConsumerDialerFlowStep.fromJSON(e)) : [],
    };
  },

  toJSON(message: Consumer_Dialer_IncomingContainer_DialerCallFlow): unknown {
    const obj: any = {};
    message.isInitial !== undefined && (obj.isInitial = message.isInitial);
    message.initialId !== undefined && (obj.initialId = message.initialId);
    message.isPassthrough !== undefined && (obj.isPassthrough = message.isPassthrough);
    message.passthroughId !== undefined && (obj.passthroughId = message.passthroughId);
    message.isFinal !== undefined && (obj.isFinal = message.isFinal);
    message.finalId !== undefined && (obj.finalId = message.finalId);
    if (message.list) {
      obj.list = message.list.map((e) => e ? ConsumerDialerFlowStep.toJSON(e) : undefined);
    } else {
      obj.list = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer_Dialer_IncomingContainer_DialerCallFlow>, I>>(
    base?: I,
  ): Consumer_Dialer_IncomingContainer_DialerCallFlow {
    return Consumer_Dialer_IncomingContainer_DialerCallFlow.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer_Dialer_IncomingContainer_DialerCallFlow>, I>>(
    object: I,
  ): Consumer_Dialer_IncomingContainer_DialerCallFlow {
    const message = createBaseConsumer_Dialer_IncomingContainer_DialerCallFlow();
    message.isInitial = object.isInitial ?? undefined;
    message.initialId = object.initialId ?? undefined;
    message.isPassthrough = object.isPassthrough ?? undefined;
    message.passthroughId = object.passthroughId ?? undefined;
    message.isFinal = object.isFinal ?? undefined;
    message.finalId = object.finalId ?? undefined;
    message.list = object.list?.map((e) => ConsumerDialerFlowStep.fromPartial(e)) || [];
    return message;
  },
};

function createBaseConsumer_Dialer_IncomingContainer_Participant(): Consumer_Dialer_IncomingContainer_Participant {
  return {
    role: undefined,
    calledTime: undefined,
    joinedTime: undefined,
    leftTime: undefined,
    declinedTime: undefined,
    firstName: undefined,
    lastName: undefined,
    profileImage: undefined,
    type: undefined,
    callControlId: undefined,
  };
}

export const Consumer_Dialer_IncomingContainer_Participant = {
  encode(message: Consumer_Dialer_IncomingContainer_Participant, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.role !== undefined) {
      writer.uint32(10).string(message.role);
    }
    if (message.calledTime !== undefined) {
      writer.uint32(16).int64(message.calledTime);
    }
    if (message.joinedTime !== undefined) {
      writer.uint32(24).int64(message.joinedTime);
    }
    if (message.leftTime !== undefined) {
      writer.uint32(32).int64(message.leftTime);
    }
    if (message.declinedTime !== undefined) {
      writer.uint32(40).int64(message.declinedTime);
    }
    if (message.firstName !== undefined) {
      writer.uint32(50).string(message.firstName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(58).string(message.lastName);
    }
    if (message.profileImage !== undefined) {
      writer.uint32(66).string(message.profileImage);
    }
    if (message.type !== undefined) {
      writer.uint32(72).int32(message.type);
    }
    if (message.callControlId !== undefined) {
      writer.uint32(82).string(message.callControlId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer_Dialer_IncomingContainer_Participant {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer_Dialer_IncomingContainer_Participant();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.role = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.calledTime = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.joinedTime = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.leftTime = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.declinedTime = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.profileImage = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.callControlId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer_Dialer_IncomingContainer_Participant {
    return {
      role: isSet(object.role) ? String(object.role) : undefined,
      calledTime: isSet(object.calledTime) ? Number(object.calledTime) : undefined,
      joinedTime: isSet(object.joinedTime) ? Number(object.joinedTime) : undefined,
      leftTime: isSet(object.leftTime) ? Number(object.leftTime) : undefined,
      declinedTime: isSet(object.declinedTime) ? Number(object.declinedTime) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      profileImage: isSet(object.profileImage) ? String(object.profileImage) : undefined,
      type: isSet(object.type) ? consumerDialerFlowStep_TargetTypeFromJSON(object.type) : undefined,
      callControlId: isSet(object.callControlId) ? String(object.callControlId) : undefined,
    };
  },

  toJSON(message: Consumer_Dialer_IncomingContainer_Participant): unknown {
    const obj: any = {};
    message.role !== undefined && (obj.role = message.role);
    message.calledTime !== undefined && (obj.calledTime = Math.round(message.calledTime));
    message.joinedTime !== undefined && (obj.joinedTime = Math.round(message.joinedTime));
    message.leftTime !== undefined && (obj.leftTime = Math.round(message.leftTime));
    message.declinedTime !== undefined && (obj.declinedTime = Math.round(message.declinedTime));
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.profileImage !== undefined && (obj.profileImage = message.profileImage);
    message.type !== undefined &&
      (obj.type = message.type !== undefined ? consumerDialerFlowStep_TargetTypeToJSON(message.type) : undefined);
    message.callControlId !== undefined && (obj.callControlId = message.callControlId);
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer_Dialer_IncomingContainer_Participant>, I>>(
    base?: I,
  ): Consumer_Dialer_IncomingContainer_Participant {
    return Consumer_Dialer_IncomingContainer_Participant.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer_Dialer_IncomingContainer_Participant>, I>>(
    object: I,
  ): Consumer_Dialer_IncomingContainer_Participant {
    const message = createBaseConsumer_Dialer_IncomingContainer_Participant();
    message.role = object.role ?? undefined;
    message.calledTime = object.calledTime ?? undefined;
    message.joinedTime = object.joinedTime ?? undefined;
    message.leftTime = object.leftTime ?? undefined;
    message.declinedTime = object.declinedTime ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.profileImage = object.profileImage ?? undefined;
    message.type = object.type ?? undefined;
    message.callControlId = object.callControlId ?? undefined;
    return message;
  },
};

function createBaseConsumer_Agency(): Consumer_Agency {
  return { id: undefined, name: undefined };
}

export const Consumer_Agency = {
  encode(message: Consumer_Agency, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer_Agency {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer_Agency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer_Agency {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: Consumer_Agency): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer_Agency>, I>>(base?: I): Consumer_Agency {
    return Consumer_Agency.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer_Agency>, I>>(object: I): Consumer_Agency {
    const message = createBaseConsumer_Agency();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseConsumer_Campaign(): Consumer_Campaign {
  return { id: undefined, name: undefined };
}

export const Consumer_Campaign = {
  encode(message: Consumer_Campaign, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer_Campaign {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer_Campaign();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer_Campaign {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: Consumer_Campaign): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer_Campaign>, I>>(base?: I): Consumer_Campaign {
    return Consumer_Campaign.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer_Campaign>, I>>(object: I): Consumer_Campaign {
    const message = createBaseConsumer_Campaign();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseConsumer_LeadList(): Consumer_LeadList {
  return { id: undefined, name: undefined };
}

export const Consumer_LeadList = {
  encode(message: Consumer_LeadList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer_LeadList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer_LeadList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer_LeadList {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: Consumer_LeadList): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer_LeadList>, I>>(base?: I): Consumer_LeadList {
    return Consumer_LeadList.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer_LeadList>, I>>(object: I): Consumer_LeadList {
    const message = createBaseConsumer_LeadList();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseConsumer_Medicare(): Consumer_Medicare {
  return { number: undefined };
}

export const Consumer_Medicare = {
  encode(message: Consumer_Medicare, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.number !== undefined) {
      writer.uint32(10).string(message.number);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer_Medicare {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer_Medicare();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.number = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer_Medicare {
    return { number: isSet(object.number) ? String(object.number) : undefined };
  },

  toJSON(message: Consumer_Medicare): unknown {
    const obj: any = {};
    message.number !== undefined && (obj.number = message.number);
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer_Medicare>, I>>(base?: I): Consumer_Medicare {
    return Consumer_Medicare.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer_Medicare>, I>>(object: I): Consumer_Medicare {
    const message = createBaseConsumer_Medicare();
    message.number = object.number ?? undefined;
    return message;
  },
};

function createBaseBenefit(): Benefit {
  return { id: "", name: "", active: undefined };
}

export const Benefit = {
  encode(message: Benefit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.active !== undefined) {
      writer.uint32(24).bool(message.active);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Benefit {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBenefit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.active = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Benefit {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      active: isSet(object.active) ? Boolean(object.active) : undefined,
    };
  },

  toJSON(message: Benefit): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.active !== undefined && (obj.active = message.active);
    return obj;
  },

  create<I extends Exact<DeepPartial<Benefit>, I>>(base?: I): Benefit {
    return Benefit.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Benefit>, I>>(object: I): Benefit {
    const message = createBaseBenefit();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.active = object.active ?? undefined;
    return message;
  },
};

function createBaseLeadSourceDetails(): LeadSourceDetails {
  return { agent: undefined, queue: undefined };
}

export const LeadSourceDetails = {
  encode(message: LeadSourceDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.agent !== undefined) {
      UserIdContainer.encode(message.agent, writer.uint32(10).fork()).ldelim();
    }
    if (message.queue !== undefined) {
      ReferenceContainer.encode(message.queue, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LeadSourceDetails {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLeadSourceDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.agent = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.queue = ReferenceContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LeadSourceDetails {
    return {
      agent: isSet(object.agent) ? UserIdContainer.fromJSON(object.agent) : undefined,
      queue: isSet(object.queue) ? ReferenceContainer.fromJSON(object.queue) : undefined,
    };
  },

  toJSON(message: LeadSourceDetails): unknown {
    const obj: any = {};
    message.agent !== undefined && (obj.agent = message.agent ? UserIdContainer.toJSON(message.agent) : undefined);
    message.queue !== undefined && (obj.queue = message.queue ? ReferenceContainer.toJSON(message.queue) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<LeadSourceDetails>, I>>(base?: I): LeadSourceDetails {
    return LeadSourceDetails.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LeadSourceDetails>, I>>(object: I): LeadSourceDetails {
    const message = createBaseLeadSourceDetails();
    message.agent = (object.agent !== undefined && object.agent !== null)
      ? UserIdContainer.fromPartial(object.agent)
      : undefined;
    message.queue = (object.queue !== undefined && object.queue !== null)
      ? ReferenceContainer.fromPartial(object.queue)
      : undefined;
    return message;
  },
};

function createBaseConsumerDialerFlowStep(): ConsumerDialerFlowStep {
  return { queue: undefined, agency: undefined, type: undefined, crossAgencyUser: undefined };
}

export const ConsumerDialerFlowStep = {
  encode(message: ConsumerDialerFlowStep, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.queue !== undefined) {
      UserIdContainer.encode(message.queue, writer.uint32(10).fork()).ldelim();
    }
    if (message.agency !== undefined) {
      UserIdContainer.encode(message.agency, writer.uint32(18).fork()).ldelim();
    }
    if (message.type !== undefined) {
      writer.uint32(24).int32(message.type);
    }
    if (message.crossAgencyUser !== undefined) {
      writer.uint32(32).bool(message.crossAgencyUser);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConsumerDialerFlowStep {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumerDialerFlowStep();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.queue = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.agency = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.crossAgencyUser = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConsumerDialerFlowStep {
    return {
      queue: isSet(object.queue) ? UserIdContainer.fromJSON(object.queue) : undefined,
      agency: isSet(object.agency) ? UserIdContainer.fromJSON(object.agency) : undefined,
      type: isSet(object.type) ? consumerDialerFlowStep_TargetTypeFromJSON(object.type) : undefined,
      crossAgencyUser: isSet(object.crossAgencyUser) ? Boolean(object.crossAgencyUser) : undefined,
    };
  },

  toJSON(message: ConsumerDialerFlowStep): unknown {
    const obj: any = {};
    message.queue !== undefined && (obj.queue = message.queue ? UserIdContainer.toJSON(message.queue) : undefined);
    message.agency !== undefined && (obj.agency = message.agency ? UserIdContainer.toJSON(message.agency) : undefined);
    message.type !== undefined &&
      (obj.type = message.type !== undefined ? consumerDialerFlowStep_TargetTypeToJSON(message.type) : undefined);
    message.crossAgencyUser !== undefined && (obj.crossAgencyUser = message.crossAgencyUser);
    return obj;
  },

  create<I extends Exact<DeepPartial<ConsumerDialerFlowStep>, I>>(base?: I): ConsumerDialerFlowStep {
    return ConsumerDialerFlowStep.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ConsumerDialerFlowStep>, I>>(object: I): ConsumerDialerFlowStep {
    const message = createBaseConsumerDialerFlowStep();
    message.queue = (object.queue !== undefined && object.queue !== null)
      ? UserIdContainer.fromPartial(object.queue)
      : undefined;
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? UserIdContainer.fromPartial(object.agency)
      : undefined;
    message.type = object.type ?? undefined;
    message.crossAgencyUser = object.crossAgencyUser ?? undefined;
    return message;
  },
};

function createBaseLeadListMember(): LeadListMember {
  return { lists: [] };
}

export const LeadListMember = {
  encode(message: LeadListMember, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.lists) {
      LeadListMember_LeadListMemberItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LeadListMember {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLeadListMember();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.lists.push(LeadListMember_LeadListMemberItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LeadListMember {
    return {
      lists: Array.isArray(object?.lists)
        ? object.lists.map((e: any) => LeadListMember_LeadListMemberItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: LeadListMember): unknown {
    const obj: any = {};
    if (message.lists) {
      obj.lists = message.lists.map((e) => e ? LeadListMember_LeadListMemberItem.toJSON(e) : undefined);
    } else {
      obj.lists = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LeadListMember>, I>>(base?: I): LeadListMember {
    return LeadListMember.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LeadListMember>, I>>(object: I): LeadListMember {
    const message = createBaseLeadListMember();
    message.lists = object.lists?.map((e) => LeadListMember_LeadListMemberItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseLeadListMember_LeadListMemberItem(): LeadListMember_LeadListMemberItem {
  return { list: undefined, createdAt: undefined };
}

export const LeadListMember_LeadListMemberItem = {
  encode(message: LeadListMember_LeadListMemberItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.list !== undefined) {
      ReferenceContainer.encode(message.list, writer.uint32(10).fork()).ldelim();
    }
    if (message.createdAt !== undefined) {
      writer.uint32(16).int64(message.createdAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LeadListMember_LeadListMemberItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLeadListMember_LeadListMemberItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.list = ReferenceContainer.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.createdAt = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LeadListMember_LeadListMemberItem {
    return {
      list: isSet(object.list) ? ReferenceContainer.fromJSON(object.list) : undefined,
      createdAt: isSet(object.createdAt) ? Number(object.createdAt) : undefined,
    };
  },

  toJSON(message: LeadListMember_LeadListMemberItem): unknown {
    const obj: any = {};
    message.list !== undefined && (obj.list = message.list ? ReferenceContainer.toJSON(message.list) : undefined);
    message.createdAt !== undefined && (obj.createdAt = Math.round(message.createdAt));
    return obj;
  },

  create<I extends Exact<DeepPartial<LeadListMember_LeadListMemberItem>, I>>(
    base?: I,
  ): LeadListMember_LeadListMemberItem {
    return LeadListMember_LeadListMemberItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LeadListMember_LeadListMemberItem>, I>>(
    object: I,
  ): LeadListMember_LeadListMemberItem {
    const message = createBaseLeadListMember_LeadListMemberItem();
    message.list = (object.list !== undefined && object.list !== null)
      ? ReferenceContainer.fromPartial(object.list)
      : undefined;
    message.createdAt = object.createdAt ?? undefined;
    return message;
  },
};

function createBaseLeadPhoneAssignments(): LeadPhoneAssignments {
  return { leadList: undefined };
}

export const LeadPhoneAssignments = {
  encode(message: LeadPhoneAssignments, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.leadList !== undefined) {
      IdentifierRepeatable.encode(message.leadList, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LeadPhoneAssignments {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLeadPhoneAssignments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.leadList = IdentifierRepeatable.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LeadPhoneAssignments {
    return { leadList: isSet(object.leadList) ? IdentifierRepeatable.fromJSON(object.leadList) : undefined };
  },

  toJSON(message: LeadPhoneAssignments): unknown {
    const obj: any = {};
    message.leadList !== undefined &&
      (obj.leadList = message.leadList ? IdentifierRepeatable.toJSON(message.leadList) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<LeadPhoneAssignments>, I>>(base?: I): LeadPhoneAssignments {
    return LeadPhoneAssignments.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LeadPhoneAssignments>, I>>(object: I): LeadPhoneAssignments {
    const message = createBaseLeadPhoneAssignments();
    message.leadList = (object.leadList !== undefined && object.leadList !== null)
      ? IdentifierRepeatable.fromPartial(object.leadList)
      : undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

// If you get a compile-error about 'Constructor<Long> and ... have no overlap',
// add '--ts_proto_opt=esModuleInterop=true' as a flag when calling 'protoc'.
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
