/* eslint-disable */
// @ts-ignore
import * as Long from "long";
import * as _m0 from "protobufjs/minimal";
import { AccountBalances } from "./accounts";
import { ExecutiveUserContainer, SingleExecutiveContainer, TierLevelContainer } from "./executive";
import { Struct } from "./google/protobuf/struct";
import {
  DialerSettings,
  Features,
  IdentifierRepeatable,
  PermanentTagsContainer,
  SkillListContainer,
  StringListContainer,
  UserDisabled,
} from "./misc";
import {
  DialerRole,
  dialerRoleFromJSON,
  dialerRoleToJSON,
  UserRole,
  userRoleFromJSON,
  userRoleToJSON,
  UserStripeContainer,
  UserType,
  userTypeFromJSON,
  userTypeToJSON,
} from "./user-enum";

export const protobufPackage = "";

export enum PermissionLevel {
  PL_NONE = 0,
  PL_READ = 1,
  PL_WRITE = 2,
  UNRECOGNIZED = -1,
}

export function permissionLevelFromJSON(object: any): PermissionLevel {
  switch (object) {
    case 0:
    case "PL_NONE":
      return PermissionLevel.PL_NONE;
    case 1:
    case "PL_READ":
      return PermissionLevel.PL_READ;
    case 2:
    case "PL_WRITE":
      return PermissionLevel.PL_WRITE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PermissionLevel.UNRECOGNIZED;
  }
}

export function permissionLevelToJSON(object: PermissionLevel): string {
  switch (object) {
    case PermissionLevel.PL_NONE:
      return "PL_NONE";
    case PermissionLevel.PL_READ:
      return "PL_READ";
    case PermissionLevel.PL_WRITE:
      return "PL_WRITE";
    case PermissionLevel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface BaseUser {
  id?: string | undefined;
  userType?: UserType | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  email?: string | undefined;
  npn?: string | undefined;
  profileImage?: string | undefined;
  name?: string | undefined;
  userRole?: UserRole | undefined;
  dialerRole?: DialerRole | undefined;
  canImpersonate?: boolean | undefined;
  skill?: SkillListContainer | undefined;
  currentAgency?: BaseUser_DialerAgency | undefined;
  phone?: string | undefined;
  executives?: ExecutiveUserContainer | undefined;
  features?: Features | undefined;
  disabled?: UserDisabled | undefined;
  permanentTags?: PermanentTagsContainer | undefined;
  loa?: LoaContainer | undefined;
  dialerSettings?: DialerSettings | undefined;
  stripe?: UserStripeContainer | undefined;
  balances?: AccountBalances | undefined;
  writingId?:
    | WritingIdContainer
    | undefined;
  /** @deprecated */
  agencyId?:
    | string
    | undefined;
  /** @deprecated */
  skills?: { [key: string]: any } | undefined;
}

export interface BaseUser_DialerAgency {
  id?: string | undefined;
  name?: string | undefined;
}

export interface LoaContainer {
  id?: string | undefined;
}

export interface User {
  id?: string | undefined;
  type?: UserType | undefined;
  phone?: string | undefined;
  profileImage?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  npn?: string | undefined;
  userRole?: UserRole | undefined;
  canImpersonate?: boolean | undefined;
}

export interface User_Permission {
  active: boolean;
  permissions:
    | Permissions
    | undefined;
  /** @deprecated */
  roles?: User_Permission_Roles | undefined;
  user: BaseUser | undefined;
  invitedAt?: number | undefined;
  grantChildAccess?: boolean | undefined;
  grantedUserId?: string | undefined;
  grantedUserType?: UserType | undefined;
  hasAccessFromParent?: boolean | undefined;
  userClaimedPermission?: boolean | undefined;
  granterUserId?: string | undefined;
  granterUserType?: UserType | undefined;
  permanentTags?: PermanentTagsContainer | undefined;
}

export interface User_Permission_Roles {
  agent?: boolean | undefined;
  admin?: boolean | undefined;
  commissions?: User_Permission_Roles_CommissionRoles | undefined;
  dialer?: User_Permission_Roles_DialerRoles | undefined;
  reporting?: User_Permission_Roles_ReportingRoles | undefined;
  userManagement?: User_Permission_Roles_UserManagementRoles | undefined;
}

export interface User_Permission_Roles_CommissionRoles {
  admin?: boolean | undefined;
  viewer?: boolean | undefined;
}

export interface User_Permission_Roles_DialerRoles {
  admin?: boolean | undefined;
  viewer?: boolean | undefined;
  agent?: boolean | undefined;
  salesAgent?: boolean | undefined;
  screenerAgent?: boolean | undefined;
  callCostViewer?: boolean | undefined;
}

export interface User_Permission_Roles_ReportingRoles {
  viewer?: boolean | undefined;
}

export interface User_Permission_Roles_UserManagementRoles {
  admin?: boolean | undefined;
  viewer?: boolean | undefined;
}

export interface Permissions {
  all?: PermissionLevel | undefined;
  commissions?: CommissionPermissions | undefined;
  dialer?: DialerPermissions | undefined;
  qe?: QEPermissions | undefined;
  publisher?: PublisherPermissions | undefined;
  reporting?: ReportingPermissions | undefined;
  userManagement?: UserManagementPermissions | undefined;
}

export interface CommissionPermissions {
  access?: boolean | undefined;
  all?: PermissionLevel | undefined;
}

export interface DialerPermissions {
  access?: boolean | undefined;
  all?:
    | PermissionLevel
    | undefined;
  /** DCC - dialer call cost */
  callCost?:
    | PermissionLevel
    | undefined;
  /** DBC - dialer billable count */
  billable?:
    | PermissionLevel
    | undefined;
  /** DCO - dialer compliance */
  compliance?:
    | PermissionLevel
    | undefined;
  /** DAP - dialer agent performance */
  agentPerformance?:
    | PermissionLevel
    | undefined;
  /** DAN - dialer analysis */
  analysis?:
    | PermissionLevel
    | undefined;
  /** DAR - dialer advanced routing */
  advancedRouting?: PermissionLevel | undefined;
  dashboard?:
    | PermissionLevel
    | undefined;
  /** DSV - dialer supervise */
  supervise?:
    | PermissionLevel
    | undefined;
  /** DMN - dialer monitoring */
  monitoring?: PermissionLevel | undefined;
  campaign?:
    | PermissionLevel
    | undefined;
  /** DQ - dialer queue */
  queue?: PermissionLevel | undefined;
  number?:
    | PermissionLevel
    | undefined;
  /** DLD - dialer lead */
  lead?:
    | PermissionLevel
    | undefined;
  /** DLL - dialer lead list */
  leadList?: PermissionLevel | undefined;
  script?: PermissionLevel | undefined;
  callCap?: PermissionLevel | undefined;
  dispoStatus?: PermissionLevel | undefined;
  skills?: PermissionLevel | undefined;
  affiliates?: PermissionLevel | undefined;
  directToAgent?: boolean | undefined;
}

export interface QEPermissions {
  access?: boolean | undefined;
  all?: PermissionLevel | undefined;
}

export interface PublisherPermissions {
  access?: boolean | undefined;
  all?: PermissionLevel | undefined;
  clearNames?: PermissionLevel | undefined;
}

export interface ReportingPermissions {
  access?: boolean | undefined;
  all?: PermissionLevel | undefined;
}

export interface UserManagementPermissions {
  access?: boolean | undefined;
  all?: PermissionLevel | undefined;
}

export interface AccessContainer {
  write?: StringListContainer | undefined;
  read?: StringListContainer | undefined;
}

export interface PermissionSetContainer {
  permission: User_Permission | undefined;
  executive?: SingleExecutiveContainer | undefined;
  tierLevel?: TierLevelContainer | undefined;
  password?: string | undefined;
  isManagingUser?: boolean | undefined;
  sendInvitationMail?: boolean | undefined;
  executiveId?: string | undefined;
  tierLevelName?: string | undefined;
  createUserWithoutPermission?: boolean | undefined;
  granterUserId: string;
  granterUserType: UserType;
}

export interface WritingIdContainer {
  byCarrier: { [key: string]: IdentifierRepeatable };
  list?: IdentifierRepeatable | undefined;
}

export interface WritingIdContainer_ByCarrierEntry {
  key: string;
  value: IdentifierRepeatable | undefined;
}

function createBaseBaseUser(): BaseUser {
  return {
    id: undefined,
    userType: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    npn: undefined,
    profileImage: undefined,
    name: undefined,
    userRole: undefined,
    dialerRole: undefined,
    canImpersonate: undefined,
    skill: undefined,
    currentAgency: undefined,
    phone: undefined,
    executives: undefined,
    features: undefined,
    disabled: undefined,
    permanentTags: undefined,
    loa: undefined,
    dialerSettings: undefined,
    stripe: undefined,
    balances: undefined,
    writingId: undefined,
    agencyId: undefined,
    skills: undefined,
  };
}

export const BaseUser = {
  encode(message: BaseUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.userType !== undefined) {
      writer.uint32(16).int32(message.userType);
    }
    if (message.firstName !== undefined) {
      writer.uint32(26).string(message.firstName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(34).string(message.lastName);
    }
    if (message.email !== undefined) {
      writer.uint32(42).string(message.email);
    }
    if (message.npn !== undefined) {
      writer.uint32(50).string(message.npn);
    }
    if (message.profileImage !== undefined) {
      writer.uint32(58).string(message.profileImage);
    }
    if (message.name !== undefined) {
      writer.uint32(66).string(message.name);
    }
    if (message.userRole !== undefined) {
      writer.uint32(72).int32(message.userRole);
    }
    if (message.dialerRole !== undefined) {
      writer.uint32(80).int32(message.dialerRole);
    }
    if (message.canImpersonate !== undefined) {
      writer.uint32(88).bool(message.canImpersonate);
    }
    if (message.skill !== undefined) {
      SkillListContainer.encode(message.skill, writer.uint32(98).fork()).ldelim();
    }
    if (message.currentAgency !== undefined) {
      BaseUser_DialerAgency.encode(message.currentAgency, writer.uint32(106).fork()).ldelim();
    }
    if (message.phone !== undefined) {
      writer.uint32(114).string(message.phone);
    }
    if (message.executives !== undefined) {
      ExecutiveUserContainer.encode(message.executives, writer.uint32(122).fork()).ldelim();
    }
    if (message.features !== undefined) {
      Features.encode(message.features, writer.uint32(130).fork()).ldelim();
    }
    if (message.disabled !== undefined) {
      UserDisabled.encode(message.disabled, writer.uint32(138).fork()).ldelim();
    }
    if (message.permanentTags !== undefined) {
      PermanentTagsContainer.encode(message.permanentTags, writer.uint32(146).fork()).ldelim();
    }
    if (message.loa !== undefined) {
      LoaContainer.encode(message.loa, writer.uint32(154).fork()).ldelim();
    }
    if (message.dialerSettings !== undefined) {
      DialerSettings.encode(message.dialerSettings, writer.uint32(162).fork()).ldelim();
    }
    if (message.stripe !== undefined) {
      UserStripeContainer.encode(message.stripe, writer.uint32(170).fork()).ldelim();
    }
    if (message.balances !== undefined) {
      AccountBalances.encode(message.balances, writer.uint32(178).fork()).ldelim();
    }
    if (message.writingId !== undefined) {
      WritingIdContainer.encode(message.writingId, writer.uint32(186).fork()).ldelim();
    }
    if (message.agencyId !== undefined) {
      writer.uint32(786).string(message.agencyId);
    }
    if (message.skills !== undefined) {
      Struct.encode(Struct.wrap(message.skills), writer.uint32(794).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BaseUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.userType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.email = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.npn = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.profileImage = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.name = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.userRole = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.dialerRole = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.canImpersonate = reader.bool();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.skill = SkillListContainer.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.currentAgency = BaseUser_DialerAgency.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.phone = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.executives = ExecutiveUserContainer.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.features = Features.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.disabled = UserDisabled.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.permanentTags = PermanentTagsContainer.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.loa = LoaContainer.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.dialerSettings = DialerSettings.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.stripe = UserStripeContainer.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.balances = AccountBalances.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.writingId = WritingIdContainer.decode(reader, reader.uint32());
          continue;
        case 98:
          if (tag !== 786) {
            break;
          }

          message.agencyId = reader.string();
          continue;
        case 99:
          if (tag !== 794) {
            break;
          }

          message.skills = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BaseUser {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      userType: isSet(object.userType) ? userTypeFromJSON(object.userType) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      npn: isSet(object.npn) ? String(object.npn) : undefined,
      profileImage: isSet(object.profileImage) ? String(object.profileImage) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      userRole: isSet(object.userRole) ? userRoleFromJSON(object.userRole) : undefined,
      dialerRole: isSet(object.dialerRole) ? dialerRoleFromJSON(object.dialerRole) : undefined,
      canImpersonate: isSet(object.canImpersonate) ? Boolean(object.canImpersonate) : undefined,
      skill: isSet(object.skill) ? SkillListContainer.fromJSON(object.skill) : undefined,
      currentAgency: isSet(object.currentAgency) ? BaseUser_DialerAgency.fromJSON(object.currentAgency) : undefined,
      phone: isSet(object.phone) ? String(object.phone) : undefined,
      executives: isSet(object.executives) ? ExecutiveUserContainer.fromJSON(object.executives) : undefined,
      features: isSet(object.features) ? Features.fromJSON(object.features) : undefined,
      disabled: isSet(object.disabled) ? UserDisabled.fromJSON(object.disabled) : undefined,
      permanentTags: isSet(object.permanentTags) ? PermanentTagsContainer.fromJSON(object.permanentTags) : undefined,
      loa: isSet(object.loa) ? LoaContainer.fromJSON(object.loa) : undefined,
      dialerSettings: isSet(object.dialerSettings) ? DialerSettings.fromJSON(object.dialerSettings) : undefined,
      stripe: isSet(object.stripe) ? UserStripeContainer.fromJSON(object.stripe) : undefined,
      balances: isSet(object.balances) ? AccountBalances.fromJSON(object.balances) : undefined,
      writingId: isSet(object.writingId) ? WritingIdContainer.fromJSON(object.writingId) : undefined,
      agencyId: isSet(object.agencyId) ? String(object.agencyId) : undefined,
      skills: isObject(object.skills) ? object.skills : undefined,
    };
  },

  toJSON(message: BaseUser): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.userType !== undefined &&
      (obj.userType = message.userType !== undefined ? userTypeToJSON(message.userType) : undefined);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.email !== undefined && (obj.email = message.email);
    message.npn !== undefined && (obj.npn = message.npn);
    message.profileImage !== undefined && (obj.profileImage = message.profileImage);
    message.name !== undefined && (obj.name = message.name);
    message.userRole !== undefined &&
      (obj.userRole = message.userRole !== undefined ? userRoleToJSON(message.userRole) : undefined);
    message.dialerRole !== undefined &&
      (obj.dialerRole = message.dialerRole !== undefined ? dialerRoleToJSON(message.dialerRole) : undefined);
    message.canImpersonate !== undefined && (obj.canImpersonate = message.canImpersonate);
    message.skill !== undefined && (obj.skill = message.skill ? SkillListContainer.toJSON(message.skill) : undefined);
    message.currentAgency !== undefined &&
      (obj.currentAgency = message.currentAgency ? BaseUser_DialerAgency.toJSON(message.currentAgency) : undefined);
    message.phone !== undefined && (obj.phone = message.phone);
    message.executives !== undefined &&
      (obj.executives = message.executives ? ExecutiveUserContainer.toJSON(message.executives) : undefined);
    message.features !== undefined && (obj.features = message.features ? Features.toJSON(message.features) : undefined);
    message.disabled !== undefined &&
      (obj.disabled = message.disabled ? UserDisabled.toJSON(message.disabled) : undefined);
    message.permanentTags !== undefined &&
      (obj.permanentTags = message.permanentTags ? PermanentTagsContainer.toJSON(message.permanentTags) : undefined);
    message.loa !== undefined && (obj.loa = message.loa ? LoaContainer.toJSON(message.loa) : undefined);
    message.dialerSettings !== undefined &&
      (obj.dialerSettings = message.dialerSettings ? DialerSettings.toJSON(message.dialerSettings) : undefined);
    message.stripe !== undefined &&
      (obj.stripe = message.stripe ? UserStripeContainer.toJSON(message.stripe) : undefined);
    message.balances !== undefined &&
      (obj.balances = message.balances ? AccountBalances.toJSON(message.balances) : undefined);
    message.writingId !== undefined &&
      (obj.writingId = message.writingId ? WritingIdContainer.toJSON(message.writingId) : undefined);
    message.agencyId !== undefined && (obj.agencyId = message.agencyId);
    message.skills !== undefined && (obj.skills = message.skills);
    return obj;
  },

  create<I extends Exact<DeepPartial<BaseUser>, I>>(base?: I): BaseUser {
    return BaseUser.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BaseUser>, I>>(object: I): BaseUser {
    const message = createBaseBaseUser();
    message.id = object.id ?? undefined;
    message.userType = object.userType ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.email = object.email ?? undefined;
    message.npn = object.npn ?? undefined;
    message.profileImage = object.profileImage ?? undefined;
    message.name = object.name ?? undefined;
    message.userRole = object.userRole ?? undefined;
    message.dialerRole = object.dialerRole ?? undefined;
    message.canImpersonate = object.canImpersonate ?? undefined;
    message.skill = (object.skill !== undefined && object.skill !== null)
      ? SkillListContainer.fromPartial(object.skill)
      : undefined;
    message.currentAgency = (object.currentAgency !== undefined && object.currentAgency !== null)
      ? BaseUser_DialerAgency.fromPartial(object.currentAgency)
      : undefined;
    message.phone = object.phone ?? undefined;
    message.executives = (object.executives !== undefined && object.executives !== null)
      ? ExecutiveUserContainer.fromPartial(object.executives)
      : undefined;
    message.features = (object.features !== undefined && object.features !== null)
      ? Features.fromPartial(object.features)
      : undefined;
    message.disabled = (object.disabled !== undefined && object.disabled !== null)
      ? UserDisabled.fromPartial(object.disabled)
      : undefined;
    message.permanentTags = (object.permanentTags !== undefined && object.permanentTags !== null)
      ? PermanentTagsContainer.fromPartial(object.permanentTags)
      : undefined;
    message.loa = (object.loa !== undefined && object.loa !== null) ? LoaContainer.fromPartial(object.loa) : undefined;
    message.dialerSettings = (object.dialerSettings !== undefined && object.dialerSettings !== null)
      ? DialerSettings.fromPartial(object.dialerSettings)
      : undefined;
    message.stripe = (object.stripe !== undefined && object.stripe !== null)
      ? UserStripeContainer.fromPartial(object.stripe)
      : undefined;
    message.balances = (object.balances !== undefined && object.balances !== null)
      ? AccountBalances.fromPartial(object.balances)
      : undefined;
    message.writingId = (object.writingId !== undefined && object.writingId !== null)
      ? WritingIdContainer.fromPartial(object.writingId)
      : undefined;
    message.agencyId = object.agencyId ?? undefined;
    message.skills = object.skills ?? undefined;
    return message;
  },
};

function createBaseBaseUser_DialerAgency(): BaseUser_DialerAgency {
  return { id: undefined, name: undefined };
}

export const BaseUser_DialerAgency = {
  encode(message: BaseUser_DialerAgency, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BaseUser_DialerAgency {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBaseUser_DialerAgency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BaseUser_DialerAgency {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: BaseUser_DialerAgency): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<BaseUser_DialerAgency>, I>>(base?: I): BaseUser_DialerAgency {
    return BaseUser_DialerAgency.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BaseUser_DialerAgency>, I>>(object: I): BaseUser_DialerAgency {
    const message = createBaseBaseUser_DialerAgency();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseLoaContainer(): LoaContainer {
  return { id: undefined };
}

export const LoaContainer = {
  encode(message: LoaContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoaContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoaContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LoaContainer {
    return { id: isSet(object.id) ? String(object.id) : undefined };
  },

  toJSON(message: LoaContainer): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<LoaContainer>, I>>(base?: I): LoaContainer {
    return LoaContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LoaContainer>, I>>(object: I): LoaContainer {
    const message = createBaseLoaContainer();
    message.id = object.id ?? undefined;
    return message;
  },
};

function createBaseUser(): User {
  return {
    id: undefined,
    type: undefined,
    phone: undefined,
    profileImage: undefined,
    firstName: undefined,
    lastName: undefined,
    npn: undefined,
    userRole: undefined,
    canImpersonate: undefined,
  };
}

export const User = {
  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.type !== undefined) {
      writer.uint32(16).int32(message.type);
    }
    if (message.phone !== undefined) {
      writer.uint32(26).string(message.phone);
    }
    if (message.profileImage !== undefined) {
      writer.uint32(34).string(message.profileImage);
    }
    if (message.firstName !== undefined) {
      writer.uint32(42).string(message.firstName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(50).string(message.lastName);
    }
    if (message.npn !== undefined) {
      writer.uint32(58).string(message.npn);
    }
    if (message.userRole !== undefined) {
      writer.uint32(64).int32(message.userRole);
    }
    if (message.canImpersonate !== undefined) {
      writer.uint32(72).bool(message.canImpersonate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.phone = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.profileImage = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.npn = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.userRole = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.canImpersonate = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      type: isSet(object.type) ? userTypeFromJSON(object.type) : undefined,
      phone: isSet(object.phone) ? String(object.phone) : undefined,
      profileImage: isSet(object.profileImage) ? String(object.profileImage) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      npn: isSet(object.npn) ? String(object.npn) : undefined,
      userRole: isSet(object.userRole) ? userRoleFromJSON(object.userRole) : undefined,
      canImpersonate: isSet(object.canImpersonate) ? Boolean(object.canImpersonate) : undefined,
    };
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.type !== undefined && (obj.type = message.type !== undefined ? userTypeToJSON(message.type) : undefined);
    message.phone !== undefined && (obj.phone = message.phone);
    message.profileImage !== undefined && (obj.profileImage = message.profileImage);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.npn !== undefined && (obj.npn = message.npn);
    message.userRole !== undefined &&
      (obj.userRole = message.userRole !== undefined ? userRoleToJSON(message.userRole) : undefined);
    message.canImpersonate !== undefined && (obj.canImpersonate = message.canImpersonate);
    return obj;
  },

  create<I extends Exact<DeepPartial<User>, I>>(base?: I): User {
    return User.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<User>, I>>(object: I): User {
    const message = createBaseUser();
    message.id = object.id ?? undefined;
    message.type = object.type ?? undefined;
    message.phone = object.phone ?? undefined;
    message.profileImage = object.profileImage ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.npn = object.npn ?? undefined;
    message.userRole = object.userRole ?? undefined;
    message.canImpersonate = object.canImpersonate ?? undefined;
    return message;
  },
};

function createBaseUser_Permission(): User_Permission {
  return {
    active: false,
    permissions: undefined,
    roles: undefined,
    user: undefined,
    invitedAt: undefined,
    grantChildAccess: undefined,
    grantedUserId: undefined,
    grantedUserType: undefined,
    hasAccessFromParent: undefined,
    userClaimedPermission: undefined,
    granterUserId: undefined,
    granterUserType: undefined,
    permanentTags: undefined,
  };
}

export const User_Permission = {
  encode(message: User_Permission, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.active === true) {
      writer.uint32(8).bool(message.active);
    }
    if (message.permissions !== undefined) {
      Permissions.encode(message.permissions, writer.uint32(18).fork()).ldelim();
    }
    if (message.roles !== undefined) {
      User_Permission_Roles.encode(message.roles, writer.uint32(26).fork()).ldelim();
    }
    if (message.user !== undefined) {
      BaseUser.encode(message.user, writer.uint32(34).fork()).ldelim();
    }
    if (message.invitedAt !== undefined) {
      writer.uint32(40).int64(message.invitedAt);
    }
    if (message.grantChildAccess !== undefined) {
      writer.uint32(48).bool(message.grantChildAccess);
    }
    if (message.grantedUserId !== undefined) {
      writer.uint32(58).string(message.grantedUserId);
    }
    if (message.grantedUserType !== undefined) {
      writer.uint32(64).int32(message.grantedUserType);
    }
    if (message.hasAccessFromParent !== undefined) {
      writer.uint32(72).bool(message.hasAccessFromParent);
    }
    if (message.userClaimedPermission !== undefined) {
      writer.uint32(80).bool(message.userClaimedPermission);
    }
    if (message.granterUserId !== undefined) {
      writer.uint32(90).string(message.granterUserId);
    }
    if (message.granterUserType !== undefined) {
      writer.uint32(96).int32(message.granterUserType);
    }
    if (message.permanentTags !== undefined) {
      PermanentTagsContainer.encode(message.permanentTags, writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User_Permission {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser_Permission();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.active = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.permissions = Permissions.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.roles = User_Permission_Roles.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.user = BaseUser.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.invitedAt = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.grantChildAccess = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.grantedUserId = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.grantedUserType = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.hasAccessFromParent = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.userClaimedPermission = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.granterUserId = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.granterUserType = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.permanentTags = PermanentTagsContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User_Permission {
    return {
      active: isSet(object.active) ? Boolean(object.active) : false,
      permissions: isSet(object.permissions) ? Permissions.fromJSON(object.permissions) : undefined,
      roles: isSet(object.roles) ? User_Permission_Roles.fromJSON(object.roles) : undefined,
      user: isSet(object.user) ? BaseUser.fromJSON(object.user) : undefined,
      invitedAt: isSet(object.invitedAt) ? Number(object.invitedAt) : undefined,
      grantChildAccess: isSet(object.grantChildAccess) ? Boolean(object.grantChildAccess) : undefined,
      grantedUserId: isSet(object.grantedUserId) ? String(object.grantedUserId) : undefined,
      grantedUserType: isSet(object.grantedUserType) ? userTypeFromJSON(object.grantedUserType) : undefined,
      hasAccessFromParent: isSet(object.hasAccessFromParent) ? Boolean(object.hasAccessFromParent) : undefined,
      userClaimedPermission: isSet(object.userClaimedPermission) ? Boolean(object.userClaimedPermission) : undefined,
      granterUserId: isSet(object.granterUserId) ? String(object.granterUserId) : undefined,
      granterUserType: isSet(object.granterUserType) ? userTypeFromJSON(object.granterUserType) : undefined,
      permanentTags: isSet(object.permanentTags) ? PermanentTagsContainer.fromJSON(object.permanentTags) : undefined,
    };
  },

  toJSON(message: User_Permission): unknown {
    const obj: any = {};
    message.active !== undefined && (obj.active = message.active);
    message.permissions !== undefined &&
      (obj.permissions = message.permissions ? Permissions.toJSON(message.permissions) : undefined);
    message.roles !== undefined &&
      (obj.roles = message.roles ? User_Permission_Roles.toJSON(message.roles) : undefined);
    message.user !== undefined && (obj.user = message.user ? BaseUser.toJSON(message.user) : undefined);
    message.invitedAt !== undefined && (obj.invitedAt = Math.round(message.invitedAt));
    message.grantChildAccess !== undefined && (obj.grantChildAccess = message.grantChildAccess);
    message.grantedUserId !== undefined && (obj.grantedUserId = message.grantedUserId);
    message.grantedUserType !== undefined &&
      (obj.grantedUserType = message.grantedUserType !== undefined
        ? userTypeToJSON(message.grantedUserType)
        : undefined);
    message.hasAccessFromParent !== undefined && (obj.hasAccessFromParent = message.hasAccessFromParent);
    message.userClaimedPermission !== undefined && (obj.userClaimedPermission = message.userClaimedPermission);
    message.granterUserId !== undefined && (obj.granterUserId = message.granterUserId);
    message.granterUserType !== undefined &&
      (obj.granterUserType = message.granterUserType !== undefined
        ? userTypeToJSON(message.granterUserType)
        : undefined);
    message.permanentTags !== undefined &&
      (obj.permanentTags = message.permanentTags ? PermanentTagsContainer.toJSON(message.permanentTags) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<User_Permission>, I>>(base?: I): User_Permission {
    return User_Permission.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<User_Permission>, I>>(object: I): User_Permission {
    const message = createBaseUser_Permission();
    message.active = object.active ?? false;
    message.permissions = (object.permissions !== undefined && object.permissions !== null)
      ? Permissions.fromPartial(object.permissions)
      : undefined;
    message.roles = (object.roles !== undefined && object.roles !== null)
      ? User_Permission_Roles.fromPartial(object.roles)
      : undefined;
    message.user = (object.user !== undefined && object.user !== null) ? BaseUser.fromPartial(object.user) : undefined;
    message.invitedAt = object.invitedAt ?? undefined;
    message.grantChildAccess = object.grantChildAccess ?? undefined;
    message.grantedUserId = object.grantedUserId ?? undefined;
    message.grantedUserType = object.grantedUserType ?? undefined;
    message.hasAccessFromParent = object.hasAccessFromParent ?? undefined;
    message.userClaimedPermission = object.userClaimedPermission ?? undefined;
    message.granterUserId = object.granterUserId ?? undefined;
    message.granterUserType = object.granterUserType ?? undefined;
    message.permanentTags = (object.permanentTags !== undefined && object.permanentTags !== null)
      ? PermanentTagsContainer.fromPartial(object.permanentTags)
      : undefined;
    return message;
  },
};

function createBaseUser_Permission_Roles(): User_Permission_Roles {
  return {
    agent: undefined,
    admin: undefined,
    commissions: undefined,
    dialer: undefined,
    reporting: undefined,
    userManagement: undefined,
  };
}

export const User_Permission_Roles = {
  encode(message: User_Permission_Roles, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.agent !== undefined) {
      writer.uint32(8).bool(message.agent);
    }
    if (message.admin !== undefined) {
      writer.uint32(16).bool(message.admin);
    }
    if (message.commissions !== undefined) {
      User_Permission_Roles_CommissionRoles.encode(message.commissions, writer.uint32(26).fork()).ldelim();
    }
    if (message.dialer !== undefined) {
      User_Permission_Roles_DialerRoles.encode(message.dialer, writer.uint32(34).fork()).ldelim();
    }
    if (message.reporting !== undefined) {
      User_Permission_Roles_ReportingRoles.encode(message.reporting, writer.uint32(42).fork()).ldelim();
    }
    if (message.userManagement !== undefined) {
      User_Permission_Roles_UserManagementRoles.encode(message.userManagement, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User_Permission_Roles {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser_Permission_Roles();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.agent = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.admin = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.commissions = User_Permission_Roles_CommissionRoles.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.dialer = User_Permission_Roles_DialerRoles.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.reporting = User_Permission_Roles_ReportingRoles.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.userManagement = User_Permission_Roles_UserManagementRoles.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User_Permission_Roles {
    return {
      agent: isSet(object.agent) ? Boolean(object.agent) : undefined,
      admin: isSet(object.admin) ? Boolean(object.admin) : undefined,
      commissions: isSet(object.commissions)
        ? User_Permission_Roles_CommissionRoles.fromJSON(object.commissions)
        : undefined,
      dialer: isSet(object.dialer) ? User_Permission_Roles_DialerRoles.fromJSON(object.dialer) : undefined,
      reporting: isSet(object.reporting) ? User_Permission_Roles_ReportingRoles.fromJSON(object.reporting) : undefined,
      userManagement: isSet(object.userManagement)
        ? User_Permission_Roles_UserManagementRoles.fromJSON(object.userManagement)
        : undefined,
    };
  },

  toJSON(message: User_Permission_Roles): unknown {
    const obj: any = {};
    message.agent !== undefined && (obj.agent = message.agent);
    message.admin !== undefined && (obj.admin = message.admin);
    message.commissions !== undefined && (obj.commissions = message.commissions
      ? User_Permission_Roles_CommissionRoles.toJSON(message.commissions)
      : undefined);
    message.dialer !== undefined &&
      (obj.dialer = message.dialer ? User_Permission_Roles_DialerRoles.toJSON(message.dialer) : undefined);
    message.reporting !== undefined &&
      (obj.reporting = message.reporting ? User_Permission_Roles_ReportingRoles.toJSON(message.reporting) : undefined);
    message.userManagement !== undefined && (obj.userManagement = message.userManagement
      ? User_Permission_Roles_UserManagementRoles.toJSON(message.userManagement)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<User_Permission_Roles>, I>>(base?: I): User_Permission_Roles {
    return User_Permission_Roles.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<User_Permission_Roles>, I>>(object: I): User_Permission_Roles {
    const message = createBaseUser_Permission_Roles();
    message.agent = object.agent ?? undefined;
    message.admin = object.admin ?? undefined;
    message.commissions = (object.commissions !== undefined && object.commissions !== null)
      ? User_Permission_Roles_CommissionRoles.fromPartial(object.commissions)
      : undefined;
    message.dialer = (object.dialer !== undefined && object.dialer !== null)
      ? User_Permission_Roles_DialerRoles.fromPartial(object.dialer)
      : undefined;
    message.reporting = (object.reporting !== undefined && object.reporting !== null)
      ? User_Permission_Roles_ReportingRoles.fromPartial(object.reporting)
      : undefined;
    message.userManagement = (object.userManagement !== undefined && object.userManagement !== null)
      ? User_Permission_Roles_UserManagementRoles.fromPartial(object.userManagement)
      : undefined;
    return message;
  },
};

function createBaseUser_Permission_Roles_CommissionRoles(): User_Permission_Roles_CommissionRoles {
  return { admin: undefined, viewer: undefined };
}

export const User_Permission_Roles_CommissionRoles = {
  encode(message: User_Permission_Roles_CommissionRoles, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.admin !== undefined) {
      writer.uint32(8).bool(message.admin);
    }
    if (message.viewer !== undefined) {
      writer.uint32(16).bool(message.viewer);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User_Permission_Roles_CommissionRoles {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser_Permission_Roles_CommissionRoles();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.admin = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.viewer = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User_Permission_Roles_CommissionRoles {
    return {
      admin: isSet(object.admin) ? Boolean(object.admin) : undefined,
      viewer: isSet(object.viewer) ? Boolean(object.viewer) : undefined,
    };
  },

  toJSON(message: User_Permission_Roles_CommissionRoles): unknown {
    const obj: any = {};
    message.admin !== undefined && (obj.admin = message.admin);
    message.viewer !== undefined && (obj.viewer = message.viewer);
    return obj;
  },

  create<I extends Exact<DeepPartial<User_Permission_Roles_CommissionRoles>, I>>(
    base?: I,
  ): User_Permission_Roles_CommissionRoles {
    return User_Permission_Roles_CommissionRoles.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<User_Permission_Roles_CommissionRoles>, I>>(
    object: I,
  ): User_Permission_Roles_CommissionRoles {
    const message = createBaseUser_Permission_Roles_CommissionRoles();
    message.admin = object.admin ?? undefined;
    message.viewer = object.viewer ?? undefined;
    return message;
  },
};

function createBaseUser_Permission_Roles_DialerRoles(): User_Permission_Roles_DialerRoles {
  return {
    admin: undefined,
    viewer: undefined,
    agent: undefined,
    salesAgent: undefined,
    screenerAgent: undefined,
    callCostViewer: undefined,
  };
}

export const User_Permission_Roles_DialerRoles = {
  encode(message: User_Permission_Roles_DialerRoles, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.admin !== undefined) {
      writer.uint32(8).bool(message.admin);
    }
    if (message.viewer !== undefined) {
      writer.uint32(16).bool(message.viewer);
    }
    if (message.agent !== undefined) {
      writer.uint32(24).bool(message.agent);
    }
    if (message.salesAgent !== undefined) {
      writer.uint32(32).bool(message.salesAgent);
    }
    if (message.screenerAgent !== undefined) {
      writer.uint32(40).bool(message.screenerAgent);
    }
    if (message.callCostViewer !== undefined) {
      writer.uint32(48).bool(message.callCostViewer);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User_Permission_Roles_DialerRoles {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser_Permission_Roles_DialerRoles();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.admin = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.viewer = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.agent = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.salesAgent = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.screenerAgent = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.callCostViewer = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User_Permission_Roles_DialerRoles {
    return {
      admin: isSet(object.admin) ? Boolean(object.admin) : undefined,
      viewer: isSet(object.viewer) ? Boolean(object.viewer) : undefined,
      agent: isSet(object.agent) ? Boolean(object.agent) : undefined,
      salesAgent: isSet(object.salesAgent) ? Boolean(object.salesAgent) : undefined,
      screenerAgent: isSet(object.screenerAgent) ? Boolean(object.screenerAgent) : undefined,
      callCostViewer: isSet(object.callCostViewer) ? Boolean(object.callCostViewer) : undefined,
    };
  },

  toJSON(message: User_Permission_Roles_DialerRoles): unknown {
    const obj: any = {};
    message.admin !== undefined && (obj.admin = message.admin);
    message.viewer !== undefined && (obj.viewer = message.viewer);
    message.agent !== undefined && (obj.agent = message.agent);
    message.salesAgent !== undefined && (obj.salesAgent = message.salesAgent);
    message.screenerAgent !== undefined && (obj.screenerAgent = message.screenerAgent);
    message.callCostViewer !== undefined && (obj.callCostViewer = message.callCostViewer);
    return obj;
  },

  create<I extends Exact<DeepPartial<User_Permission_Roles_DialerRoles>, I>>(
    base?: I,
  ): User_Permission_Roles_DialerRoles {
    return User_Permission_Roles_DialerRoles.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<User_Permission_Roles_DialerRoles>, I>>(
    object: I,
  ): User_Permission_Roles_DialerRoles {
    const message = createBaseUser_Permission_Roles_DialerRoles();
    message.admin = object.admin ?? undefined;
    message.viewer = object.viewer ?? undefined;
    message.agent = object.agent ?? undefined;
    message.salesAgent = object.salesAgent ?? undefined;
    message.screenerAgent = object.screenerAgent ?? undefined;
    message.callCostViewer = object.callCostViewer ?? undefined;
    return message;
  },
};

function createBaseUser_Permission_Roles_ReportingRoles(): User_Permission_Roles_ReportingRoles {
  return { viewer: undefined };
}

export const User_Permission_Roles_ReportingRoles = {
  encode(message: User_Permission_Roles_ReportingRoles, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.viewer !== undefined) {
      writer.uint32(8).bool(message.viewer);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User_Permission_Roles_ReportingRoles {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser_Permission_Roles_ReportingRoles();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.viewer = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User_Permission_Roles_ReportingRoles {
    return { viewer: isSet(object.viewer) ? Boolean(object.viewer) : undefined };
  },

  toJSON(message: User_Permission_Roles_ReportingRoles): unknown {
    const obj: any = {};
    message.viewer !== undefined && (obj.viewer = message.viewer);
    return obj;
  },

  create<I extends Exact<DeepPartial<User_Permission_Roles_ReportingRoles>, I>>(
    base?: I,
  ): User_Permission_Roles_ReportingRoles {
    return User_Permission_Roles_ReportingRoles.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<User_Permission_Roles_ReportingRoles>, I>>(
    object: I,
  ): User_Permission_Roles_ReportingRoles {
    const message = createBaseUser_Permission_Roles_ReportingRoles();
    message.viewer = object.viewer ?? undefined;
    return message;
  },
};

function createBaseUser_Permission_Roles_UserManagementRoles(): User_Permission_Roles_UserManagementRoles {
  return { admin: undefined, viewer: undefined };
}

export const User_Permission_Roles_UserManagementRoles = {
  encode(message: User_Permission_Roles_UserManagementRoles, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.admin !== undefined) {
      writer.uint32(8).bool(message.admin);
    }
    if (message.viewer !== undefined) {
      writer.uint32(16).bool(message.viewer);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User_Permission_Roles_UserManagementRoles {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser_Permission_Roles_UserManagementRoles();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.admin = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.viewer = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User_Permission_Roles_UserManagementRoles {
    return {
      admin: isSet(object.admin) ? Boolean(object.admin) : undefined,
      viewer: isSet(object.viewer) ? Boolean(object.viewer) : undefined,
    };
  },

  toJSON(message: User_Permission_Roles_UserManagementRoles): unknown {
    const obj: any = {};
    message.admin !== undefined && (obj.admin = message.admin);
    message.viewer !== undefined && (obj.viewer = message.viewer);
    return obj;
  },

  create<I extends Exact<DeepPartial<User_Permission_Roles_UserManagementRoles>, I>>(
    base?: I,
  ): User_Permission_Roles_UserManagementRoles {
    return User_Permission_Roles_UserManagementRoles.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<User_Permission_Roles_UserManagementRoles>, I>>(
    object: I,
  ): User_Permission_Roles_UserManagementRoles {
    const message = createBaseUser_Permission_Roles_UserManagementRoles();
    message.admin = object.admin ?? undefined;
    message.viewer = object.viewer ?? undefined;
    return message;
  },
};

function createBasePermissions(): Permissions {
  return {
    all: undefined,
    commissions: undefined,
    dialer: undefined,
    qe: undefined,
    publisher: undefined,
    reporting: undefined,
    userManagement: undefined,
  };
}

export const Permissions = {
  encode(message: Permissions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.all !== undefined) {
      writer.uint32(8).int32(message.all);
    }
    if (message.commissions !== undefined) {
      CommissionPermissions.encode(message.commissions, writer.uint32(18).fork()).ldelim();
    }
    if (message.dialer !== undefined) {
      DialerPermissions.encode(message.dialer, writer.uint32(26).fork()).ldelim();
    }
    if (message.qe !== undefined) {
      QEPermissions.encode(message.qe, writer.uint32(34).fork()).ldelim();
    }
    if (message.publisher !== undefined) {
      PublisherPermissions.encode(message.publisher, writer.uint32(42).fork()).ldelim();
    }
    if (message.reporting !== undefined) {
      ReportingPermissions.encode(message.reporting, writer.uint32(50).fork()).ldelim();
    }
    if (message.userManagement !== undefined) {
      UserManagementPermissions.encode(message.userManagement, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Permissions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePermissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.all = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.commissions = CommissionPermissions.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.dialer = DialerPermissions.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.qe = QEPermissions.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.publisher = PublisherPermissions.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.reporting = ReportingPermissions.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.userManagement = UserManagementPermissions.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Permissions {
    return {
      all: isSet(object.all) ? permissionLevelFromJSON(object.all) : undefined,
      commissions: isSet(object.commissions) ? CommissionPermissions.fromJSON(object.commissions) : undefined,
      dialer: isSet(object.dialer) ? DialerPermissions.fromJSON(object.dialer) : undefined,
      qe: isSet(object.qe) ? QEPermissions.fromJSON(object.qe) : undefined,
      publisher: isSet(object.publisher) ? PublisherPermissions.fromJSON(object.publisher) : undefined,
      reporting: isSet(object.reporting) ? ReportingPermissions.fromJSON(object.reporting) : undefined,
      userManagement: isSet(object.userManagement)
        ? UserManagementPermissions.fromJSON(object.userManagement)
        : undefined,
    };
  },

  toJSON(message: Permissions): unknown {
    const obj: any = {};
    message.all !== undefined && (obj.all = message.all !== undefined ? permissionLevelToJSON(message.all) : undefined);
    message.commissions !== undefined &&
      (obj.commissions = message.commissions ? CommissionPermissions.toJSON(message.commissions) : undefined);
    message.dialer !== undefined &&
      (obj.dialer = message.dialer ? DialerPermissions.toJSON(message.dialer) : undefined);
    message.qe !== undefined && (obj.qe = message.qe ? QEPermissions.toJSON(message.qe) : undefined);
    message.publisher !== undefined &&
      (obj.publisher = message.publisher ? PublisherPermissions.toJSON(message.publisher) : undefined);
    message.reporting !== undefined &&
      (obj.reporting = message.reporting ? ReportingPermissions.toJSON(message.reporting) : undefined);
    message.userManagement !== undefined && (obj.userManagement = message.userManagement
      ? UserManagementPermissions.toJSON(message.userManagement)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Permissions>, I>>(base?: I): Permissions {
    return Permissions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Permissions>, I>>(object: I): Permissions {
    const message = createBasePermissions();
    message.all = object.all ?? undefined;
    message.commissions = (object.commissions !== undefined && object.commissions !== null)
      ? CommissionPermissions.fromPartial(object.commissions)
      : undefined;
    message.dialer = (object.dialer !== undefined && object.dialer !== null)
      ? DialerPermissions.fromPartial(object.dialer)
      : undefined;
    message.qe = (object.qe !== undefined && object.qe !== null) ? QEPermissions.fromPartial(object.qe) : undefined;
    message.publisher = (object.publisher !== undefined && object.publisher !== null)
      ? PublisherPermissions.fromPartial(object.publisher)
      : undefined;
    message.reporting = (object.reporting !== undefined && object.reporting !== null)
      ? ReportingPermissions.fromPartial(object.reporting)
      : undefined;
    message.userManagement = (object.userManagement !== undefined && object.userManagement !== null)
      ? UserManagementPermissions.fromPartial(object.userManagement)
      : undefined;
    return message;
  },
};

function createBaseCommissionPermissions(): CommissionPermissions {
  return { access: undefined, all: undefined };
}

export const CommissionPermissions = {
  encode(message: CommissionPermissions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.access !== undefined) {
      writer.uint32(8).bool(message.access);
    }
    if (message.all !== undefined) {
      writer.uint32(16).int32(message.all);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CommissionPermissions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommissionPermissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.access = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.all = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CommissionPermissions {
    return {
      access: isSet(object.access) ? Boolean(object.access) : undefined,
      all: isSet(object.all) ? permissionLevelFromJSON(object.all) : undefined,
    };
  },

  toJSON(message: CommissionPermissions): unknown {
    const obj: any = {};
    message.access !== undefined && (obj.access = message.access);
    message.all !== undefined && (obj.all = message.all !== undefined ? permissionLevelToJSON(message.all) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<CommissionPermissions>, I>>(base?: I): CommissionPermissions {
    return CommissionPermissions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CommissionPermissions>, I>>(object: I): CommissionPermissions {
    const message = createBaseCommissionPermissions();
    message.access = object.access ?? undefined;
    message.all = object.all ?? undefined;
    return message;
  },
};

function createBaseDialerPermissions(): DialerPermissions {
  return {
    access: undefined,
    all: undefined,
    callCost: undefined,
    billable: undefined,
    compliance: undefined,
    agentPerformance: undefined,
    analysis: undefined,
    advancedRouting: undefined,
    dashboard: undefined,
    supervise: undefined,
    monitoring: undefined,
    campaign: undefined,
    queue: undefined,
    number: undefined,
    lead: undefined,
    leadList: undefined,
    script: undefined,
    callCap: undefined,
    dispoStatus: undefined,
    skills: undefined,
    affiliates: undefined,
    directToAgent: undefined,
  };
}

export const DialerPermissions = {
  encode(message: DialerPermissions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.access !== undefined) {
      writer.uint32(8).bool(message.access);
    }
    if (message.all !== undefined) {
      writer.uint32(16).int32(message.all);
    }
    if (message.callCost !== undefined) {
      writer.uint32(24).int32(message.callCost);
    }
    if (message.billable !== undefined) {
      writer.uint32(32).int32(message.billable);
    }
    if (message.compliance !== undefined) {
      writer.uint32(40).int32(message.compliance);
    }
    if (message.agentPerformance !== undefined) {
      writer.uint32(48).int32(message.agentPerformance);
    }
    if (message.analysis !== undefined) {
      writer.uint32(56).int32(message.analysis);
    }
    if (message.advancedRouting !== undefined) {
      writer.uint32(64).int32(message.advancedRouting);
    }
    if (message.dashboard !== undefined) {
      writer.uint32(72).int32(message.dashboard);
    }
    if (message.supervise !== undefined) {
      writer.uint32(80).int32(message.supervise);
    }
    if (message.monitoring !== undefined) {
      writer.uint32(88).int32(message.monitoring);
    }
    if (message.campaign !== undefined) {
      writer.uint32(96).int32(message.campaign);
    }
    if (message.queue !== undefined) {
      writer.uint32(104).int32(message.queue);
    }
    if (message.number !== undefined) {
      writer.uint32(112).int32(message.number);
    }
    if (message.lead !== undefined) {
      writer.uint32(120).int32(message.lead);
    }
    if (message.leadList !== undefined) {
      writer.uint32(128).int32(message.leadList);
    }
    if (message.script !== undefined) {
      writer.uint32(136).int32(message.script);
    }
    if (message.callCap !== undefined) {
      writer.uint32(144).int32(message.callCap);
    }
    if (message.dispoStatus !== undefined) {
      writer.uint32(152).int32(message.dispoStatus);
    }
    if (message.skills !== undefined) {
      writer.uint32(160).int32(message.skills);
    }
    if (message.affiliates !== undefined) {
      writer.uint32(168).int32(message.affiliates);
    }
    if (message.directToAgent !== undefined) {
      writer.uint32(792).bool(message.directToAgent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerPermissions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerPermissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.access = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.all = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.callCost = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.billable = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.compliance = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.agentPerformance = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.analysis = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.advancedRouting = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.dashboard = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.supervise = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.monitoring = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.campaign = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.queue = reader.int32() as any;
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.number = reader.int32() as any;
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.lead = reader.int32() as any;
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.leadList = reader.int32() as any;
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.script = reader.int32() as any;
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.callCap = reader.int32() as any;
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.dispoStatus = reader.int32() as any;
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.skills = reader.int32() as any;
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.affiliates = reader.int32() as any;
          continue;
        case 99:
          if (tag !== 792) {
            break;
          }

          message.directToAgent = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerPermissions {
    return {
      access: isSet(object.access) ? Boolean(object.access) : undefined,
      all: isSet(object.all) ? permissionLevelFromJSON(object.all) : undefined,
      callCost: isSet(object.callCost) ? permissionLevelFromJSON(object.callCost) : undefined,
      billable: isSet(object.billable) ? permissionLevelFromJSON(object.billable) : undefined,
      compliance: isSet(object.compliance) ? permissionLevelFromJSON(object.compliance) : undefined,
      agentPerformance: isSet(object.agentPerformance) ? permissionLevelFromJSON(object.agentPerformance) : undefined,
      analysis: isSet(object.analysis) ? permissionLevelFromJSON(object.analysis) : undefined,
      advancedRouting: isSet(object.advancedRouting) ? permissionLevelFromJSON(object.advancedRouting) : undefined,
      dashboard: isSet(object.dashboard) ? permissionLevelFromJSON(object.dashboard) : undefined,
      supervise: isSet(object.supervise) ? permissionLevelFromJSON(object.supervise) : undefined,
      monitoring: isSet(object.monitoring) ? permissionLevelFromJSON(object.monitoring) : undefined,
      campaign: isSet(object.campaign) ? permissionLevelFromJSON(object.campaign) : undefined,
      queue: isSet(object.queue) ? permissionLevelFromJSON(object.queue) : undefined,
      number: isSet(object.number) ? permissionLevelFromJSON(object.number) : undefined,
      lead: isSet(object.lead) ? permissionLevelFromJSON(object.lead) : undefined,
      leadList: isSet(object.leadList) ? permissionLevelFromJSON(object.leadList) : undefined,
      script: isSet(object.script) ? permissionLevelFromJSON(object.script) : undefined,
      callCap: isSet(object.callCap) ? permissionLevelFromJSON(object.callCap) : undefined,
      dispoStatus: isSet(object.dispoStatus) ? permissionLevelFromJSON(object.dispoStatus) : undefined,
      skills: isSet(object.skills) ? permissionLevelFromJSON(object.skills) : undefined,
      affiliates: isSet(object.affiliates) ? permissionLevelFromJSON(object.affiliates) : undefined,
      directToAgent: isSet(object.directToAgent) ? Boolean(object.directToAgent) : undefined,
    };
  },

  toJSON(message: DialerPermissions): unknown {
    const obj: any = {};
    message.access !== undefined && (obj.access = message.access);
    message.all !== undefined && (obj.all = message.all !== undefined ? permissionLevelToJSON(message.all) : undefined);
    message.callCost !== undefined &&
      (obj.callCost = message.callCost !== undefined ? permissionLevelToJSON(message.callCost) : undefined);
    message.billable !== undefined &&
      (obj.billable = message.billable !== undefined ? permissionLevelToJSON(message.billable) : undefined);
    message.compliance !== undefined &&
      (obj.compliance = message.compliance !== undefined ? permissionLevelToJSON(message.compliance) : undefined);
    message.agentPerformance !== undefined && (obj.agentPerformance = message.agentPerformance !== undefined
      ? permissionLevelToJSON(message.agentPerformance)
      : undefined);
    message.analysis !== undefined &&
      (obj.analysis = message.analysis !== undefined ? permissionLevelToJSON(message.analysis) : undefined);
    message.advancedRouting !== undefined && (obj.advancedRouting = message.advancedRouting !== undefined
      ? permissionLevelToJSON(message.advancedRouting)
      : undefined);
    message.dashboard !== undefined &&
      (obj.dashboard = message.dashboard !== undefined ? permissionLevelToJSON(message.dashboard) : undefined);
    message.supervise !== undefined &&
      (obj.supervise = message.supervise !== undefined ? permissionLevelToJSON(message.supervise) : undefined);
    message.monitoring !== undefined &&
      (obj.monitoring = message.monitoring !== undefined ? permissionLevelToJSON(message.monitoring) : undefined);
    message.campaign !== undefined &&
      (obj.campaign = message.campaign !== undefined ? permissionLevelToJSON(message.campaign) : undefined);
    message.queue !== undefined &&
      (obj.queue = message.queue !== undefined ? permissionLevelToJSON(message.queue) : undefined);
    message.number !== undefined &&
      (obj.number = message.number !== undefined ? permissionLevelToJSON(message.number) : undefined);
    message.lead !== undefined &&
      (obj.lead = message.lead !== undefined ? permissionLevelToJSON(message.lead) : undefined);
    message.leadList !== undefined &&
      (obj.leadList = message.leadList !== undefined ? permissionLevelToJSON(message.leadList) : undefined);
    message.script !== undefined &&
      (obj.script = message.script !== undefined ? permissionLevelToJSON(message.script) : undefined);
    message.callCap !== undefined &&
      (obj.callCap = message.callCap !== undefined ? permissionLevelToJSON(message.callCap) : undefined);
    message.dispoStatus !== undefined &&
      (obj.dispoStatus = message.dispoStatus !== undefined ? permissionLevelToJSON(message.dispoStatus) : undefined);
    message.skills !== undefined &&
      (obj.skills = message.skills !== undefined ? permissionLevelToJSON(message.skills) : undefined);
    message.affiliates !== undefined &&
      (obj.affiliates = message.affiliates !== undefined ? permissionLevelToJSON(message.affiliates) : undefined);
    message.directToAgent !== undefined && (obj.directToAgent = message.directToAgent);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerPermissions>, I>>(base?: I): DialerPermissions {
    return DialerPermissions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerPermissions>, I>>(object: I): DialerPermissions {
    const message = createBaseDialerPermissions();
    message.access = object.access ?? undefined;
    message.all = object.all ?? undefined;
    message.callCost = object.callCost ?? undefined;
    message.billable = object.billable ?? undefined;
    message.compliance = object.compliance ?? undefined;
    message.agentPerformance = object.agentPerformance ?? undefined;
    message.analysis = object.analysis ?? undefined;
    message.advancedRouting = object.advancedRouting ?? undefined;
    message.dashboard = object.dashboard ?? undefined;
    message.supervise = object.supervise ?? undefined;
    message.monitoring = object.monitoring ?? undefined;
    message.campaign = object.campaign ?? undefined;
    message.queue = object.queue ?? undefined;
    message.number = object.number ?? undefined;
    message.lead = object.lead ?? undefined;
    message.leadList = object.leadList ?? undefined;
    message.script = object.script ?? undefined;
    message.callCap = object.callCap ?? undefined;
    message.dispoStatus = object.dispoStatus ?? undefined;
    message.skills = object.skills ?? undefined;
    message.affiliates = object.affiliates ?? undefined;
    message.directToAgent = object.directToAgent ?? undefined;
    return message;
  },
};

function createBaseQEPermissions(): QEPermissions {
  return { access: undefined, all: undefined };
}

export const QEPermissions = {
  encode(message: QEPermissions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.access !== undefined) {
      writer.uint32(8).bool(message.access);
    }
    if (message.all !== undefined) {
      writer.uint32(16).int32(message.all);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QEPermissions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQEPermissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.access = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.all = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QEPermissions {
    return {
      access: isSet(object.access) ? Boolean(object.access) : undefined,
      all: isSet(object.all) ? permissionLevelFromJSON(object.all) : undefined,
    };
  },

  toJSON(message: QEPermissions): unknown {
    const obj: any = {};
    message.access !== undefined && (obj.access = message.access);
    message.all !== undefined && (obj.all = message.all !== undefined ? permissionLevelToJSON(message.all) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<QEPermissions>, I>>(base?: I): QEPermissions {
    return QEPermissions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QEPermissions>, I>>(object: I): QEPermissions {
    const message = createBaseQEPermissions();
    message.access = object.access ?? undefined;
    message.all = object.all ?? undefined;
    return message;
  },
};

function createBasePublisherPermissions(): PublisherPermissions {
  return { access: undefined, all: undefined, clearNames: undefined };
}

export const PublisherPermissions = {
  encode(message: PublisherPermissions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.access !== undefined) {
      writer.uint32(8).bool(message.access);
    }
    if (message.all !== undefined) {
      writer.uint32(16).int32(message.all);
    }
    if (message.clearNames !== undefined) {
      writer.uint32(24).int32(message.clearNames);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PublisherPermissions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePublisherPermissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.access = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.all = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.clearNames = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PublisherPermissions {
    return {
      access: isSet(object.access) ? Boolean(object.access) : undefined,
      all: isSet(object.all) ? permissionLevelFromJSON(object.all) : undefined,
      clearNames: isSet(object.clearNames) ? permissionLevelFromJSON(object.clearNames) : undefined,
    };
  },

  toJSON(message: PublisherPermissions): unknown {
    const obj: any = {};
    message.access !== undefined && (obj.access = message.access);
    message.all !== undefined && (obj.all = message.all !== undefined ? permissionLevelToJSON(message.all) : undefined);
    message.clearNames !== undefined &&
      (obj.clearNames = message.clearNames !== undefined ? permissionLevelToJSON(message.clearNames) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PublisherPermissions>, I>>(base?: I): PublisherPermissions {
    return PublisherPermissions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PublisherPermissions>, I>>(object: I): PublisherPermissions {
    const message = createBasePublisherPermissions();
    message.access = object.access ?? undefined;
    message.all = object.all ?? undefined;
    message.clearNames = object.clearNames ?? undefined;
    return message;
  },
};

function createBaseReportingPermissions(): ReportingPermissions {
  return { access: undefined, all: undefined };
}

export const ReportingPermissions = {
  encode(message: ReportingPermissions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.access !== undefined) {
      writer.uint32(8).bool(message.access);
    }
    if (message.all !== undefined) {
      writer.uint32(16).int32(message.all);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportingPermissions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportingPermissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.access = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.all = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReportingPermissions {
    return {
      access: isSet(object.access) ? Boolean(object.access) : undefined,
      all: isSet(object.all) ? permissionLevelFromJSON(object.all) : undefined,
    };
  },

  toJSON(message: ReportingPermissions): unknown {
    const obj: any = {};
    message.access !== undefined && (obj.access = message.access);
    message.all !== undefined && (obj.all = message.all !== undefined ? permissionLevelToJSON(message.all) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ReportingPermissions>, I>>(base?: I): ReportingPermissions {
    return ReportingPermissions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ReportingPermissions>, I>>(object: I): ReportingPermissions {
    const message = createBaseReportingPermissions();
    message.access = object.access ?? undefined;
    message.all = object.all ?? undefined;
    return message;
  },
};

function createBaseUserManagementPermissions(): UserManagementPermissions {
  return { access: undefined, all: undefined };
}

export const UserManagementPermissions = {
  encode(message: UserManagementPermissions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.access !== undefined) {
      writer.uint32(8).bool(message.access);
    }
    if (message.all !== undefined) {
      writer.uint32(16).int32(message.all);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserManagementPermissions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserManagementPermissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.access = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.all = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserManagementPermissions {
    return {
      access: isSet(object.access) ? Boolean(object.access) : undefined,
      all: isSet(object.all) ? permissionLevelFromJSON(object.all) : undefined,
    };
  },

  toJSON(message: UserManagementPermissions): unknown {
    const obj: any = {};
    message.access !== undefined && (obj.access = message.access);
    message.all !== undefined && (obj.all = message.all !== undefined ? permissionLevelToJSON(message.all) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserManagementPermissions>, I>>(base?: I): UserManagementPermissions {
    return UserManagementPermissions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserManagementPermissions>, I>>(object: I): UserManagementPermissions {
    const message = createBaseUserManagementPermissions();
    message.access = object.access ?? undefined;
    message.all = object.all ?? undefined;
    return message;
  },
};

function createBaseAccessContainer(): AccessContainer {
  return { write: undefined, read: undefined };
}

export const AccessContainer = {
  encode(message: AccessContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.write !== undefined) {
      StringListContainer.encode(message.write, writer.uint32(10).fork()).ldelim();
    }
    if (message.read !== undefined) {
      StringListContainer.encode(message.read, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccessContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccessContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.write = StringListContainer.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.read = StringListContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccessContainer {
    return {
      write: isSet(object.write) ? StringListContainer.fromJSON(object.write) : undefined,
      read: isSet(object.read) ? StringListContainer.fromJSON(object.read) : undefined,
    };
  },

  toJSON(message: AccessContainer): unknown {
    const obj: any = {};
    message.write !== undefined && (obj.write = message.write ? StringListContainer.toJSON(message.write) : undefined);
    message.read !== undefined && (obj.read = message.read ? StringListContainer.toJSON(message.read) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<AccessContainer>, I>>(base?: I): AccessContainer {
    return AccessContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccessContainer>, I>>(object: I): AccessContainer {
    const message = createBaseAccessContainer();
    message.write = (object.write !== undefined && object.write !== null)
      ? StringListContainer.fromPartial(object.write)
      : undefined;
    message.read = (object.read !== undefined && object.read !== null)
      ? StringListContainer.fromPartial(object.read)
      : undefined;
    return message;
  },
};

function createBasePermissionSetContainer(): PermissionSetContainer {
  return {
    permission: undefined,
    executive: undefined,
    tierLevel: undefined,
    password: undefined,
    isManagingUser: undefined,
    sendInvitationMail: undefined,
    executiveId: undefined,
    tierLevelName: undefined,
    createUserWithoutPermission: undefined,
    granterUserId: "",
    granterUserType: 0,
  };
}

export const PermissionSetContainer = {
  encode(message: PermissionSetContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.permission !== undefined) {
      User_Permission.encode(message.permission, writer.uint32(10).fork()).ldelim();
    }
    if (message.executive !== undefined) {
      SingleExecutiveContainer.encode(message.executive, writer.uint32(18).fork()).ldelim();
    }
    if (message.tierLevel !== undefined) {
      TierLevelContainer.encode(message.tierLevel, writer.uint32(26).fork()).ldelim();
    }
    if (message.password !== undefined) {
      writer.uint32(34).string(message.password);
    }
    if (message.isManagingUser !== undefined) {
      writer.uint32(40).bool(message.isManagingUser);
    }
    if (message.sendInvitationMail !== undefined) {
      writer.uint32(48).bool(message.sendInvitationMail);
    }
    if (message.executiveId !== undefined) {
      writer.uint32(58).string(message.executiveId);
    }
    if (message.tierLevelName !== undefined) {
      writer.uint32(66).string(message.tierLevelName);
    }
    if (message.createUserWithoutPermission !== undefined) {
      writer.uint32(72).bool(message.createUserWithoutPermission);
    }
    if (message.granterUserId !== "") {
      writer.uint32(90).string(message.granterUserId);
    }
    if (message.granterUserType !== 0) {
      writer.uint32(96).int32(message.granterUserType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PermissionSetContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePermissionSetContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.permission = User_Permission.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.executive = SingleExecutiveContainer.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tierLevel = TierLevelContainer.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.password = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isManagingUser = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.sendInvitationMail = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.executiveId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.tierLevelName = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.createUserWithoutPermission = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.granterUserId = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.granterUserType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PermissionSetContainer {
    return {
      permission: isSet(object.permission) ? User_Permission.fromJSON(object.permission) : undefined,
      executive: isSet(object.executive) ? SingleExecutiveContainer.fromJSON(object.executive) : undefined,
      tierLevel: isSet(object.tierLevel) ? TierLevelContainer.fromJSON(object.tierLevel) : undefined,
      password: isSet(object.password) ? String(object.password) : undefined,
      isManagingUser: isSet(object.isManagingUser) ? Boolean(object.isManagingUser) : undefined,
      sendInvitationMail: isSet(object.sendInvitationMail) ? Boolean(object.sendInvitationMail) : undefined,
      executiveId: isSet(object.executiveId) ? String(object.executiveId) : undefined,
      tierLevelName: isSet(object.tierLevelName) ? String(object.tierLevelName) : undefined,
      createUserWithoutPermission: isSet(object.createUserWithoutPermission)
        ? Boolean(object.createUserWithoutPermission)
        : undefined,
      granterUserId: isSet(object.granterUserId) ? String(object.granterUserId) : "",
      granterUserType: isSet(object.granterUserType) ? userTypeFromJSON(object.granterUserType) : 0,
    };
  },

  toJSON(message: PermissionSetContainer): unknown {
    const obj: any = {};
    message.permission !== undefined &&
      (obj.permission = message.permission ? User_Permission.toJSON(message.permission) : undefined);
    message.executive !== undefined &&
      (obj.executive = message.executive ? SingleExecutiveContainer.toJSON(message.executive) : undefined);
    message.tierLevel !== undefined &&
      (obj.tierLevel = message.tierLevel ? TierLevelContainer.toJSON(message.tierLevel) : undefined);
    message.password !== undefined && (obj.password = message.password);
    message.isManagingUser !== undefined && (obj.isManagingUser = message.isManagingUser);
    message.sendInvitationMail !== undefined && (obj.sendInvitationMail = message.sendInvitationMail);
    message.executiveId !== undefined && (obj.executiveId = message.executiveId);
    message.tierLevelName !== undefined && (obj.tierLevelName = message.tierLevelName);
    message.createUserWithoutPermission !== undefined &&
      (obj.createUserWithoutPermission = message.createUserWithoutPermission);
    message.granterUserId !== undefined && (obj.granterUserId = message.granterUserId);
    message.granterUserType !== undefined && (obj.granterUserType = userTypeToJSON(message.granterUserType));
    return obj;
  },

  create<I extends Exact<DeepPartial<PermissionSetContainer>, I>>(base?: I): PermissionSetContainer {
    return PermissionSetContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PermissionSetContainer>, I>>(object: I): PermissionSetContainer {
    const message = createBasePermissionSetContainer();
    message.permission = (object.permission !== undefined && object.permission !== null)
      ? User_Permission.fromPartial(object.permission)
      : undefined;
    message.executive = (object.executive !== undefined && object.executive !== null)
      ? SingleExecutiveContainer.fromPartial(object.executive)
      : undefined;
    message.tierLevel = (object.tierLevel !== undefined && object.tierLevel !== null)
      ? TierLevelContainer.fromPartial(object.tierLevel)
      : undefined;
    message.password = object.password ?? undefined;
    message.isManagingUser = object.isManagingUser ?? undefined;
    message.sendInvitationMail = object.sendInvitationMail ?? undefined;
    message.executiveId = object.executiveId ?? undefined;
    message.tierLevelName = object.tierLevelName ?? undefined;
    message.createUserWithoutPermission = object.createUserWithoutPermission ?? undefined;
    message.granterUserId = object.granterUserId ?? "";
    message.granterUserType = object.granterUserType ?? 0;
    return message;
  },
};

function createBaseWritingIdContainer(): WritingIdContainer {
  return { byCarrier: {}, list: undefined };
}

export const WritingIdContainer = {
  encode(message: WritingIdContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.byCarrier).forEach(([key, value]) => {
      WritingIdContainer_ByCarrierEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    if (message.list !== undefined) {
      IdentifierRepeatable.encode(message.list, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WritingIdContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWritingIdContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = WritingIdContainer_ByCarrierEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.byCarrier[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.list = IdentifierRepeatable.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WritingIdContainer {
    return {
      byCarrier: isObject(object.byCarrier)
        ? Object.entries(object.byCarrier).reduce<{ [key: string]: IdentifierRepeatable }>((acc, [key, value]) => {
          acc[key] = IdentifierRepeatable.fromJSON(value);
          return acc;
        }, {})
        : {},
      list: isSet(object.list) ? IdentifierRepeatable.fromJSON(object.list) : undefined,
    };
  },

  toJSON(message: WritingIdContainer): unknown {
    const obj: any = {};
    obj.byCarrier = {};
    if (message.byCarrier) {
      Object.entries(message.byCarrier).forEach(([k, v]) => {
        obj.byCarrier[k] = IdentifierRepeatable.toJSON(v);
      });
    }
    message.list !== undefined && (obj.list = message.list ? IdentifierRepeatable.toJSON(message.list) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<WritingIdContainer>, I>>(base?: I): WritingIdContainer {
    return WritingIdContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WritingIdContainer>, I>>(object: I): WritingIdContainer {
    const message = createBaseWritingIdContainer();
    message.byCarrier = Object.entries(object.byCarrier ?? {}).reduce<{ [key: string]: IdentifierRepeatable }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = IdentifierRepeatable.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.list = (object.list !== undefined && object.list !== null)
      ? IdentifierRepeatable.fromPartial(object.list)
      : undefined;
    return message;
  },
};

function createBaseWritingIdContainer_ByCarrierEntry(): WritingIdContainer_ByCarrierEntry {
  return { key: "", value: undefined };
}

export const WritingIdContainer_ByCarrierEntry = {
  encode(message: WritingIdContainer_ByCarrierEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      IdentifierRepeatable.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WritingIdContainer_ByCarrierEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWritingIdContainer_ByCarrierEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = IdentifierRepeatable.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WritingIdContainer_ByCarrierEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? IdentifierRepeatable.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: WritingIdContainer_ByCarrierEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? IdentifierRepeatable.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<WritingIdContainer_ByCarrierEntry>, I>>(
    base?: I,
  ): WritingIdContainer_ByCarrierEntry {
    return WritingIdContainer_ByCarrierEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WritingIdContainer_ByCarrierEntry>, I>>(
    object: I,
  ): WritingIdContainer_ByCarrierEntry {
    const message = createBaseWritingIdContainer_ByCarrierEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? IdentifierRepeatable.fromPartial(object.value)
      : undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

// If you get a compile-error about 'Constructor<Long> and ... have no overlap',
// add '--ts_proto_opt=esModuleInterop=true' as a flag when calling 'protoc'.
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
