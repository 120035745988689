/* eslint-disable */
// @ts-ignore
import * as Long from "long";
import * as _m0 from "protobufjs/minimal";
import { AccountBalances } from "./accounts";
import { DialerDirectToAgentAgentSettings } from "./direct-to-agent";
import { ExecutiveUserContainer } from "./executive";
import { Struct } from "./google/protobuf/struct";
import {
  Address,
  AlternativesContainer,
  DialerSettings,
  DialerWebRtc,
  Features,
  IdentifierContainer,
  PermanentTagsContainer,
  UserDisabled,
} from "./misc";
import { WritingIdContainer } from "./user";
import {
  UserRole,
  userRoleFromJSON,
  userRoleToJSON,
  UserStripeContainer,
  UserType,
  userTypeFromJSON,
  userTypeToJSON,
} from "./user-enum";

export const protobufPackage = "";

export interface Agent {
  id?: string | undefined;
  firstName?: string | undefined;
  middleName?: string | undefined;
  lastName?: string | undefined;
  suffix?: string | undefined;
  npn?: string | undefined;
  ssn?: string | undefined;
  email?: string | undefined;
  phone?: string | undefined;
  partnerId?: string | undefined;
  profileImage?: string | undefined;
  agencyId?: string | undefined;
  canImpersonate?: boolean | undefined;
  setupDone?: boolean | undefined;
  broker?: Agent_Broker | undefined;
  currentAgency?: Agent_DialerAgency | undefined;
  dialerSession?: Agent_DialerSession | undefined;
  features?: Features | undefined;
  userRole?: UserRole | undefined;
  webrtc?: DialerWebRtc | undefined;
  skills?:
    | { [key: string]: any }
    | undefined;
  /** {HUM: 'asdas', UHC: 'ASDASDAS'} */
  writingId?: WritingIdContainer | undefined;
  tier?: string | undefined;
  executive?: Agent_Executive | undefined;
  address?: Address | undefined;
  executives?: ExecutiveUserContainer | undefined;
  disabled?: UserDisabled | undefined;
  permanentTags?: PermanentTagsContainer | undefined;
  loaParentAgency?: string | undefined;
  identifiers?: IdentifierContainer | undefined;
  phoneInvalid?: boolean | undefined;
  dialerSettings?: DialerSettings | undefined;
  errors?: { [key: string]: any } | undefined;
  isOpen?: boolean | undefined;
  totalErrors?: number | undefined;
  tempCreated?: Agent_TempCreatedContainer | undefined;
  directToAgent?: DialerDirectToAgentAgentSettings | undefined;
  userType?: UserType | undefined;
  stripe?: UserStripeContainer | undefined;
  alternatives?: AlternativesContainer | undefined;
  balances?: AccountBalances | undefined;
  dialerNotification?: DialerNotification | undefined;
}

export enum Agent_AgentType {
  CALL_CENTER = 0,
  UNRECOGNIZED = -1,
}

export function agent_AgentTypeFromJSON(object: any): Agent_AgentType {
  switch (object) {
    case 0:
    case "CALL_CENTER":
      return Agent_AgentType.CALL_CENTER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Agent_AgentType.UNRECOGNIZED;
  }
}

export function agent_AgentTypeToJSON(object: Agent_AgentType): string {
  switch (object) {
    case Agent_AgentType.CALL_CENTER:
      return "CALL_CENTER";
    case Agent_AgentType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Agent_TempCreatedContainer {
  isTempCreated?: boolean | undefined;
  npnField?: string | undefined;
  npnValue?: string | undefined;
  importerChannelId?: string | undefined;
}

export interface Agent_Executive {
  id?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  npn?: string | undefined;
  agencyId?: string | undefined;
}

export interface Agent_DialerAgency {
  id?: string | undefined;
  name?: string | undefined;
}

export interface Agent_DialerSession {
  id?: string | undefined;
  updatedAt?: number | undefined;
}

export interface Agent_Broker {
  npn?: string | undefined;
  tin?: string | undefined;
  address?: Address | undefined;
}

export interface DialerNotification {
  incomingCall?: DialerNotification_IncomingCall | undefined;
}

export interface DialerNotification_IncomingCall {
  lastCallAt?: number | undefined;
  leadId?: string | undefined;
  phone?: string | undefined;
  name?: string | undefined;
}

function createBaseAgent(): Agent {
  return {
    id: undefined,
    firstName: undefined,
    middleName: undefined,
    lastName: undefined,
    suffix: undefined,
    npn: undefined,
    ssn: undefined,
    email: undefined,
    phone: undefined,
    partnerId: undefined,
    profileImage: undefined,
    agencyId: undefined,
    canImpersonate: undefined,
    setupDone: undefined,
    broker: undefined,
    currentAgency: undefined,
    dialerSession: undefined,
    features: undefined,
    userRole: undefined,
    webrtc: undefined,
    skills: undefined,
    writingId: undefined,
    tier: undefined,
    executive: undefined,
    address: undefined,
    executives: undefined,
    disabled: undefined,
    permanentTags: undefined,
    loaParentAgency: undefined,
    identifiers: undefined,
    phoneInvalid: undefined,
    dialerSettings: undefined,
    errors: undefined,
    isOpen: undefined,
    totalErrors: undefined,
    tempCreated: undefined,
    directToAgent: undefined,
    userType: undefined,
    stripe: undefined,
    alternatives: undefined,
    balances: undefined,
    dialerNotification: undefined,
  };
}

export const Agent = {
  encode(message: Agent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.firstName !== undefined) {
      writer.uint32(18).string(message.firstName);
    }
    if (message.middleName !== undefined) {
      writer.uint32(26).string(message.middleName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(34).string(message.lastName);
    }
    if (message.suffix !== undefined) {
      writer.uint32(42).string(message.suffix);
    }
    if (message.npn !== undefined) {
      writer.uint32(50).string(message.npn);
    }
    if (message.ssn !== undefined) {
      writer.uint32(58).string(message.ssn);
    }
    if (message.email !== undefined) {
      writer.uint32(66).string(message.email);
    }
    if (message.phone !== undefined) {
      writer.uint32(74).string(message.phone);
    }
    if (message.partnerId !== undefined) {
      writer.uint32(82).string(message.partnerId);
    }
    if (message.profileImage !== undefined) {
      writer.uint32(90).string(message.profileImage);
    }
    if (message.agencyId !== undefined) {
      writer.uint32(98).string(message.agencyId);
    }
    if (message.canImpersonate !== undefined) {
      writer.uint32(104).bool(message.canImpersonate);
    }
    if (message.setupDone !== undefined) {
      writer.uint32(112).bool(message.setupDone);
    }
    if (message.broker !== undefined) {
      Agent_Broker.encode(message.broker, writer.uint32(122).fork()).ldelim();
    }
    if (message.currentAgency !== undefined) {
      Agent_DialerAgency.encode(message.currentAgency, writer.uint32(130).fork()).ldelim();
    }
    if (message.dialerSession !== undefined) {
      Agent_DialerSession.encode(message.dialerSession, writer.uint32(138).fork()).ldelim();
    }
    if (message.features !== undefined) {
      Features.encode(message.features, writer.uint32(146).fork()).ldelim();
    }
    if (message.userRole !== undefined) {
      writer.uint32(152).int32(message.userRole);
    }
    if (message.webrtc !== undefined) {
      DialerWebRtc.encode(message.webrtc, writer.uint32(162).fork()).ldelim();
    }
    if (message.skills !== undefined) {
      Struct.encode(Struct.wrap(message.skills), writer.uint32(170).fork()).ldelim();
    }
    if (message.writingId !== undefined) {
      WritingIdContainer.encode(message.writingId, writer.uint32(178).fork()).ldelim();
    }
    if (message.tier !== undefined) {
      writer.uint32(186).string(message.tier);
    }
    if (message.executive !== undefined) {
      Agent_Executive.encode(message.executive, writer.uint32(194).fork()).ldelim();
    }
    if (message.address !== undefined) {
      Address.encode(message.address, writer.uint32(202).fork()).ldelim();
    }
    if (message.executives !== undefined) {
      ExecutiveUserContainer.encode(message.executives, writer.uint32(210).fork()).ldelim();
    }
    if (message.disabled !== undefined) {
      UserDisabled.encode(message.disabled, writer.uint32(218).fork()).ldelim();
    }
    if (message.permanentTags !== undefined) {
      PermanentTagsContainer.encode(message.permanentTags, writer.uint32(226).fork()).ldelim();
    }
    if (message.loaParentAgency !== undefined) {
      writer.uint32(234).string(message.loaParentAgency);
    }
    if (message.identifiers !== undefined) {
      IdentifierContainer.encode(message.identifiers, writer.uint32(242).fork()).ldelim();
    }
    if (message.phoneInvalid !== undefined) {
      writer.uint32(248).bool(message.phoneInvalid);
    }
    if (message.dialerSettings !== undefined) {
      DialerSettings.encode(message.dialerSettings, writer.uint32(258).fork()).ldelim();
    }
    if (message.errors !== undefined) {
      Struct.encode(Struct.wrap(message.errors), writer.uint32(266).fork()).ldelim();
    }
    if (message.isOpen !== undefined) {
      writer.uint32(272).bool(message.isOpen);
    }
    if (message.totalErrors !== undefined) {
      writer.uint32(280).int32(message.totalErrors);
    }
    if (message.tempCreated !== undefined) {
      Agent_TempCreatedContainer.encode(message.tempCreated, writer.uint32(290).fork()).ldelim();
    }
    if (message.directToAgent !== undefined) {
      DialerDirectToAgentAgentSettings.encode(message.directToAgent, writer.uint32(298).fork()).ldelim();
    }
    if (message.userType !== undefined) {
      writer.uint32(304).int32(message.userType);
    }
    if (message.stripe !== undefined) {
      UserStripeContainer.encode(message.stripe, writer.uint32(314).fork()).ldelim();
    }
    if (message.alternatives !== undefined) {
      AlternativesContainer.encode(message.alternatives, writer.uint32(322).fork()).ldelim();
    }
    if (message.balances !== undefined) {
      AccountBalances.encode(message.balances, writer.uint32(330).fork()).ldelim();
    }
    if (message.dialerNotification !== undefined) {
      DialerNotification.encode(message.dialerNotification, writer.uint32(338).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Agent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAgent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.middleName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.suffix = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.npn = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.ssn = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.email = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.phone = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.partnerId = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.profileImage = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.agencyId = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.canImpersonate = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.setupDone = reader.bool();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.broker = Agent_Broker.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.currentAgency = Agent_DialerAgency.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.dialerSession = Agent_DialerSession.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.features = Features.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.userRole = reader.int32() as any;
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.webrtc = DialerWebRtc.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.skills = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.writingId = WritingIdContainer.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.tier = reader.string();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.executive = Agent_Executive.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.address = Address.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.executives = ExecutiveUserContainer.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.disabled = UserDisabled.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.permanentTags = PermanentTagsContainer.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.loaParentAgency = reader.string();
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.identifiers = IdentifierContainer.decode(reader, reader.uint32());
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.phoneInvalid = reader.bool();
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.dialerSettings = DialerSettings.decode(reader, reader.uint32());
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.errors = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 34:
          if (tag !== 272) {
            break;
          }

          message.isOpen = reader.bool();
          continue;
        case 35:
          if (tag !== 280) {
            break;
          }

          message.totalErrors = reader.int32();
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.tempCreated = Agent_TempCreatedContainer.decode(reader, reader.uint32());
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.directToAgent = DialerDirectToAgentAgentSettings.decode(reader, reader.uint32());
          continue;
        case 38:
          if (tag !== 304) {
            break;
          }

          message.userType = reader.int32() as any;
          continue;
        case 39:
          if (tag !== 314) {
            break;
          }

          message.stripe = UserStripeContainer.decode(reader, reader.uint32());
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.alternatives = AlternativesContainer.decode(reader, reader.uint32());
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.balances = AccountBalances.decode(reader, reader.uint32());
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.dialerNotification = DialerNotification.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Agent {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      middleName: isSet(object.middleName) ? String(object.middleName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      suffix: isSet(object.suffix) ? String(object.suffix) : undefined,
      npn: isSet(object.npn) ? String(object.npn) : undefined,
      ssn: isSet(object.ssn) ? String(object.ssn) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      phone: isSet(object.phone) ? String(object.phone) : undefined,
      partnerId: isSet(object.partnerId) ? String(object.partnerId) : undefined,
      profileImage: isSet(object.profileImage) ? String(object.profileImage) : undefined,
      agencyId: isSet(object.agencyId) ? String(object.agencyId) : undefined,
      canImpersonate: isSet(object.canImpersonate) ? Boolean(object.canImpersonate) : undefined,
      setupDone: isSet(object.setupDone) ? Boolean(object.setupDone) : undefined,
      broker: isSet(object.broker) ? Agent_Broker.fromJSON(object.broker) : undefined,
      currentAgency: isSet(object.currentAgency) ? Agent_DialerAgency.fromJSON(object.currentAgency) : undefined,
      dialerSession: isSet(object.dialerSession) ? Agent_DialerSession.fromJSON(object.dialerSession) : undefined,
      features: isSet(object.features) ? Features.fromJSON(object.features) : undefined,
      userRole: isSet(object.userRole) ? userRoleFromJSON(object.userRole) : undefined,
      webrtc: isSet(object.webrtc) ? DialerWebRtc.fromJSON(object.webrtc) : undefined,
      skills: isObject(object.skills) ? object.skills : undefined,
      writingId: isSet(object.writingId) ? WritingIdContainer.fromJSON(object.writingId) : undefined,
      tier: isSet(object.tier) ? String(object.tier) : undefined,
      executive: isSet(object.executive) ? Agent_Executive.fromJSON(object.executive) : undefined,
      address: isSet(object.address) ? Address.fromJSON(object.address) : undefined,
      executives: isSet(object.executives) ? ExecutiveUserContainer.fromJSON(object.executives) : undefined,
      disabled: isSet(object.disabled) ? UserDisabled.fromJSON(object.disabled) : undefined,
      permanentTags: isSet(object.permanentTags) ? PermanentTagsContainer.fromJSON(object.permanentTags) : undefined,
      loaParentAgency: isSet(object.loaParentAgency) ? String(object.loaParentAgency) : undefined,
      identifiers: isSet(object.identifiers) ? IdentifierContainer.fromJSON(object.identifiers) : undefined,
      phoneInvalid: isSet(object.phoneInvalid) ? Boolean(object.phoneInvalid) : undefined,
      dialerSettings: isSet(object.dialerSettings) ? DialerSettings.fromJSON(object.dialerSettings) : undefined,
      errors: isObject(object.errors) ? object.errors : undefined,
      isOpen: isSet(object.isOpen) ? Boolean(object.isOpen) : undefined,
      totalErrors: isSet(object.totalErrors) ? Number(object.totalErrors) : undefined,
      tempCreated: isSet(object.tempCreated) ? Agent_TempCreatedContainer.fromJSON(object.tempCreated) : undefined,
      directToAgent: isSet(object.directToAgent)
        ? DialerDirectToAgentAgentSettings.fromJSON(object.directToAgent)
        : undefined,
      userType: isSet(object.userType) ? userTypeFromJSON(object.userType) : undefined,
      stripe: isSet(object.stripe) ? UserStripeContainer.fromJSON(object.stripe) : undefined,
      alternatives: isSet(object.alternatives) ? AlternativesContainer.fromJSON(object.alternatives) : undefined,
      balances: isSet(object.balances) ? AccountBalances.fromJSON(object.balances) : undefined,
      dialerNotification: isSet(object.dialerNotification)
        ? DialerNotification.fromJSON(object.dialerNotification)
        : undefined,
    };
  },

  toJSON(message: Agent): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.middleName !== undefined && (obj.middleName = message.middleName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.suffix !== undefined && (obj.suffix = message.suffix);
    message.npn !== undefined && (obj.npn = message.npn);
    message.ssn !== undefined && (obj.ssn = message.ssn);
    message.email !== undefined && (obj.email = message.email);
    message.phone !== undefined && (obj.phone = message.phone);
    message.partnerId !== undefined && (obj.partnerId = message.partnerId);
    message.profileImage !== undefined && (obj.profileImage = message.profileImage);
    message.agencyId !== undefined && (obj.agencyId = message.agencyId);
    message.canImpersonate !== undefined && (obj.canImpersonate = message.canImpersonate);
    message.setupDone !== undefined && (obj.setupDone = message.setupDone);
    message.broker !== undefined && (obj.broker = message.broker ? Agent_Broker.toJSON(message.broker) : undefined);
    message.currentAgency !== undefined &&
      (obj.currentAgency = message.currentAgency ? Agent_DialerAgency.toJSON(message.currentAgency) : undefined);
    message.dialerSession !== undefined &&
      (obj.dialerSession = message.dialerSession ? Agent_DialerSession.toJSON(message.dialerSession) : undefined);
    message.features !== undefined && (obj.features = message.features ? Features.toJSON(message.features) : undefined);
    message.userRole !== undefined &&
      (obj.userRole = message.userRole !== undefined ? userRoleToJSON(message.userRole) : undefined);
    message.webrtc !== undefined && (obj.webrtc = message.webrtc ? DialerWebRtc.toJSON(message.webrtc) : undefined);
    message.skills !== undefined && (obj.skills = message.skills);
    message.writingId !== undefined &&
      (obj.writingId = message.writingId ? WritingIdContainer.toJSON(message.writingId) : undefined);
    message.tier !== undefined && (obj.tier = message.tier);
    message.executive !== undefined &&
      (obj.executive = message.executive ? Agent_Executive.toJSON(message.executive) : undefined);
    message.address !== undefined && (obj.address = message.address ? Address.toJSON(message.address) : undefined);
    message.executives !== undefined &&
      (obj.executives = message.executives ? ExecutiveUserContainer.toJSON(message.executives) : undefined);
    message.disabled !== undefined &&
      (obj.disabled = message.disabled ? UserDisabled.toJSON(message.disabled) : undefined);
    message.permanentTags !== undefined &&
      (obj.permanentTags = message.permanentTags ? PermanentTagsContainer.toJSON(message.permanentTags) : undefined);
    message.loaParentAgency !== undefined && (obj.loaParentAgency = message.loaParentAgency);
    message.identifiers !== undefined &&
      (obj.identifiers = message.identifiers ? IdentifierContainer.toJSON(message.identifiers) : undefined);
    message.phoneInvalid !== undefined && (obj.phoneInvalid = message.phoneInvalid);
    message.dialerSettings !== undefined &&
      (obj.dialerSettings = message.dialerSettings ? DialerSettings.toJSON(message.dialerSettings) : undefined);
    message.errors !== undefined && (obj.errors = message.errors);
    message.isOpen !== undefined && (obj.isOpen = message.isOpen);
    message.totalErrors !== undefined && (obj.totalErrors = Math.round(message.totalErrors));
    message.tempCreated !== undefined &&
      (obj.tempCreated = message.tempCreated ? Agent_TempCreatedContainer.toJSON(message.tempCreated) : undefined);
    message.directToAgent !== undefined && (obj.directToAgent = message.directToAgent
      ? DialerDirectToAgentAgentSettings.toJSON(message.directToAgent)
      : undefined);
    message.userType !== undefined &&
      (obj.userType = message.userType !== undefined ? userTypeToJSON(message.userType) : undefined);
    message.stripe !== undefined &&
      (obj.stripe = message.stripe ? UserStripeContainer.toJSON(message.stripe) : undefined);
    message.alternatives !== undefined &&
      (obj.alternatives = message.alternatives ? AlternativesContainer.toJSON(message.alternatives) : undefined);
    message.balances !== undefined &&
      (obj.balances = message.balances ? AccountBalances.toJSON(message.balances) : undefined);
    message.dialerNotification !== undefined && (obj.dialerNotification = message.dialerNotification
      ? DialerNotification.toJSON(message.dialerNotification)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Agent>, I>>(base?: I): Agent {
    return Agent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Agent>, I>>(object: I): Agent {
    const message = createBaseAgent();
    message.id = object.id ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.middleName = object.middleName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.suffix = object.suffix ?? undefined;
    message.npn = object.npn ?? undefined;
    message.ssn = object.ssn ?? undefined;
    message.email = object.email ?? undefined;
    message.phone = object.phone ?? undefined;
    message.partnerId = object.partnerId ?? undefined;
    message.profileImage = object.profileImage ?? undefined;
    message.agencyId = object.agencyId ?? undefined;
    message.canImpersonate = object.canImpersonate ?? undefined;
    message.setupDone = object.setupDone ?? undefined;
    message.broker = (object.broker !== undefined && object.broker !== null)
      ? Agent_Broker.fromPartial(object.broker)
      : undefined;
    message.currentAgency = (object.currentAgency !== undefined && object.currentAgency !== null)
      ? Agent_DialerAgency.fromPartial(object.currentAgency)
      : undefined;
    message.dialerSession = (object.dialerSession !== undefined && object.dialerSession !== null)
      ? Agent_DialerSession.fromPartial(object.dialerSession)
      : undefined;
    message.features = (object.features !== undefined && object.features !== null)
      ? Features.fromPartial(object.features)
      : undefined;
    message.userRole = object.userRole ?? undefined;
    message.webrtc = (object.webrtc !== undefined && object.webrtc !== null)
      ? DialerWebRtc.fromPartial(object.webrtc)
      : undefined;
    message.skills = object.skills ?? undefined;
    message.writingId = (object.writingId !== undefined && object.writingId !== null)
      ? WritingIdContainer.fromPartial(object.writingId)
      : undefined;
    message.tier = object.tier ?? undefined;
    message.executive = (object.executive !== undefined && object.executive !== null)
      ? Agent_Executive.fromPartial(object.executive)
      : undefined;
    message.address = (object.address !== undefined && object.address !== null)
      ? Address.fromPartial(object.address)
      : undefined;
    message.executives = (object.executives !== undefined && object.executives !== null)
      ? ExecutiveUserContainer.fromPartial(object.executives)
      : undefined;
    message.disabled = (object.disabled !== undefined && object.disabled !== null)
      ? UserDisabled.fromPartial(object.disabled)
      : undefined;
    message.permanentTags = (object.permanentTags !== undefined && object.permanentTags !== null)
      ? PermanentTagsContainer.fromPartial(object.permanentTags)
      : undefined;
    message.loaParentAgency = object.loaParentAgency ?? undefined;
    message.identifiers = (object.identifiers !== undefined && object.identifiers !== null)
      ? IdentifierContainer.fromPartial(object.identifiers)
      : undefined;
    message.phoneInvalid = object.phoneInvalid ?? undefined;
    message.dialerSettings = (object.dialerSettings !== undefined && object.dialerSettings !== null)
      ? DialerSettings.fromPartial(object.dialerSettings)
      : undefined;
    message.errors = object.errors ?? undefined;
    message.isOpen = object.isOpen ?? undefined;
    message.totalErrors = object.totalErrors ?? undefined;
    message.tempCreated = (object.tempCreated !== undefined && object.tempCreated !== null)
      ? Agent_TempCreatedContainer.fromPartial(object.tempCreated)
      : undefined;
    message.directToAgent = (object.directToAgent !== undefined && object.directToAgent !== null)
      ? DialerDirectToAgentAgentSettings.fromPartial(object.directToAgent)
      : undefined;
    message.userType = object.userType ?? undefined;
    message.stripe = (object.stripe !== undefined && object.stripe !== null)
      ? UserStripeContainer.fromPartial(object.stripe)
      : undefined;
    message.alternatives = (object.alternatives !== undefined && object.alternatives !== null)
      ? AlternativesContainer.fromPartial(object.alternatives)
      : undefined;
    message.balances = (object.balances !== undefined && object.balances !== null)
      ? AccountBalances.fromPartial(object.balances)
      : undefined;
    message.dialerNotification = (object.dialerNotification !== undefined && object.dialerNotification !== null)
      ? DialerNotification.fromPartial(object.dialerNotification)
      : undefined;
    return message;
  },
};

function createBaseAgent_TempCreatedContainer(): Agent_TempCreatedContainer {
  return { isTempCreated: undefined, npnField: undefined, npnValue: undefined, importerChannelId: undefined };
}

export const Agent_TempCreatedContainer = {
  encode(message: Agent_TempCreatedContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isTempCreated !== undefined) {
      writer.uint32(8).bool(message.isTempCreated);
    }
    if (message.npnField !== undefined) {
      writer.uint32(18).string(message.npnField);
    }
    if (message.npnValue !== undefined) {
      writer.uint32(26).string(message.npnValue);
    }
    if (message.importerChannelId !== undefined) {
      writer.uint32(34).string(message.importerChannelId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Agent_TempCreatedContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAgent_TempCreatedContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isTempCreated = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.npnField = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.npnValue = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.importerChannelId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Agent_TempCreatedContainer {
    return {
      isTempCreated: isSet(object.isTempCreated) ? Boolean(object.isTempCreated) : undefined,
      npnField: isSet(object.npnField) ? String(object.npnField) : undefined,
      npnValue: isSet(object.npnValue) ? String(object.npnValue) : undefined,
      importerChannelId: isSet(object.importerChannelId) ? String(object.importerChannelId) : undefined,
    };
  },

  toJSON(message: Agent_TempCreatedContainer): unknown {
    const obj: any = {};
    message.isTempCreated !== undefined && (obj.isTempCreated = message.isTempCreated);
    message.npnField !== undefined && (obj.npnField = message.npnField);
    message.npnValue !== undefined && (obj.npnValue = message.npnValue);
    message.importerChannelId !== undefined && (obj.importerChannelId = message.importerChannelId);
    return obj;
  },

  create<I extends Exact<DeepPartial<Agent_TempCreatedContainer>, I>>(base?: I): Agent_TempCreatedContainer {
    return Agent_TempCreatedContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Agent_TempCreatedContainer>, I>>(object: I): Agent_TempCreatedContainer {
    const message = createBaseAgent_TempCreatedContainer();
    message.isTempCreated = object.isTempCreated ?? undefined;
    message.npnField = object.npnField ?? undefined;
    message.npnValue = object.npnValue ?? undefined;
    message.importerChannelId = object.importerChannelId ?? undefined;
    return message;
  },
};

function createBaseAgent_Executive(): Agent_Executive {
  return { id: undefined, firstName: undefined, lastName: undefined, npn: undefined, agencyId: undefined };
}

export const Agent_Executive = {
  encode(message: Agent_Executive, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.firstName !== undefined) {
      writer.uint32(18).string(message.firstName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(26).string(message.lastName);
    }
    if (message.npn !== undefined) {
      writer.uint32(34).string(message.npn);
    }
    if (message.agencyId !== undefined) {
      writer.uint32(42).string(message.agencyId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Agent_Executive {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAgent_Executive();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.npn = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.agencyId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Agent_Executive {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      npn: isSet(object.npn) ? String(object.npn) : undefined,
      agencyId: isSet(object.agencyId) ? String(object.agencyId) : undefined,
    };
  },

  toJSON(message: Agent_Executive): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.npn !== undefined && (obj.npn = message.npn);
    message.agencyId !== undefined && (obj.agencyId = message.agencyId);
    return obj;
  },

  create<I extends Exact<DeepPartial<Agent_Executive>, I>>(base?: I): Agent_Executive {
    return Agent_Executive.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Agent_Executive>, I>>(object: I): Agent_Executive {
    const message = createBaseAgent_Executive();
    message.id = object.id ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.npn = object.npn ?? undefined;
    message.agencyId = object.agencyId ?? undefined;
    return message;
  },
};

function createBaseAgent_DialerAgency(): Agent_DialerAgency {
  return { id: undefined, name: undefined };
}

export const Agent_DialerAgency = {
  encode(message: Agent_DialerAgency, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Agent_DialerAgency {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAgent_DialerAgency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Agent_DialerAgency {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: Agent_DialerAgency): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<Agent_DialerAgency>, I>>(base?: I): Agent_DialerAgency {
    return Agent_DialerAgency.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Agent_DialerAgency>, I>>(object: I): Agent_DialerAgency {
    const message = createBaseAgent_DialerAgency();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseAgent_DialerSession(): Agent_DialerSession {
  return { id: undefined, updatedAt: undefined };
}

export const Agent_DialerSession = {
  encode(message: Agent_DialerSession, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(16).int64(message.updatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Agent_DialerSession {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAgent_DialerSession();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.updatedAt = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Agent_DialerSession {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      updatedAt: isSet(object.updatedAt) ? Number(object.updatedAt) : undefined,
    };
  },

  toJSON(message: Agent_DialerSession): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.updatedAt !== undefined && (obj.updatedAt = Math.round(message.updatedAt));
    return obj;
  },

  create<I extends Exact<DeepPartial<Agent_DialerSession>, I>>(base?: I): Agent_DialerSession {
    return Agent_DialerSession.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Agent_DialerSession>, I>>(object: I): Agent_DialerSession {
    const message = createBaseAgent_DialerSession();
    message.id = object.id ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    return message;
  },
};

function createBaseAgent_Broker(): Agent_Broker {
  return { npn: undefined, tin: undefined, address: undefined };
}

export const Agent_Broker = {
  encode(message: Agent_Broker, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.npn !== undefined) {
      writer.uint32(10).string(message.npn);
    }
    if (message.tin !== undefined) {
      writer.uint32(18).string(message.tin);
    }
    if (message.address !== undefined) {
      Address.encode(message.address, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Agent_Broker {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAgent_Broker();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.npn = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tin = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.address = Address.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Agent_Broker {
    return {
      npn: isSet(object.npn) ? String(object.npn) : undefined,
      tin: isSet(object.tin) ? String(object.tin) : undefined,
      address: isSet(object.address) ? Address.fromJSON(object.address) : undefined,
    };
  },

  toJSON(message: Agent_Broker): unknown {
    const obj: any = {};
    message.npn !== undefined && (obj.npn = message.npn);
    message.tin !== undefined && (obj.tin = message.tin);
    message.address !== undefined && (obj.address = message.address ? Address.toJSON(message.address) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Agent_Broker>, I>>(base?: I): Agent_Broker {
    return Agent_Broker.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Agent_Broker>, I>>(object: I): Agent_Broker {
    const message = createBaseAgent_Broker();
    message.npn = object.npn ?? undefined;
    message.tin = object.tin ?? undefined;
    message.address = (object.address !== undefined && object.address !== null)
      ? Address.fromPartial(object.address)
      : undefined;
    return message;
  },
};

function createBaseDialerNotification(): DialerNotification {
  return { incomingCall: undefined };
}

export const DialerNotification = {
  encode(message: DialerNotification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.incomingCall !== undefined) {
      DialerNotification_IncomingCall.encode(message.incomingCall, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerNotification {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerNotification();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.incomingCall = DialerNotification_IncomingCall.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerNotification {
    return {
      incomingCall: isSet(object.incomingCall)
        ? DialerNotification_IncomingCall.fromJSON(object.incomingCall)
        : undefined,
    };
  },

  toJSON(message: DialerNotification): unknown {
    const obj: any = {};
    message.incomingCall !== undefined && (obj.incomingCall = message.incomingCall
      ? DialerNotification_IncomingCall.toJSON(message.incomingCall)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerNotification>, I>>(base?: I): DialerNotification {
    return DialerNotification.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerNotification>, I>>(object: I): DialerNotification {
    const message = createBaseDialerNotification();
    message.incomingCall = (object.incomingCall !== undefined && object.incomingCall !== null)
      ? DialerNotification_IncomingCall.fromPartial(object.incomingCall)
      : undefined;
    return message;
  },
};

function createBaseDialerNotification_IncomingCall(): DialerNotification_IncomingCall {
  return { lastCallAt: undefined, leadId: undefined, phone: undefined, name: undefined };
}

export const DialerNotification_IncomingCall = {
  encode(message: DialerNotification_IncomingCall, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.lastCallAt !== undefined) {
      writer.uint32(8).int64(message.lastCallAt);
    }
    if (message.leadId !== undefined) {
      writer.uint32(18).string(message.leadId);
    }
    if (message.phone !== undefined) {
      writer.uint32(26).string(message.phone);
    }
    if (message.name !== undefined) {
      writer.uint32(34).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerNotification_IncomingCall {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerNotification_IncomingCall();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.lastCallAt = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.leadId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.phone = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerNotification_IncomingCall {
    return {
      lastCallAt: isSet(object.lastCallAt) ? Number(object.lastCallAt) : undefined,
      leadId: isSet(object.leadId) ? String(object.leadId) : undefined,
      phone: isSet(object.phone) ? String(object.phone) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: DialerNotification_IncomingCall): unknown {
    const obj: any = {};
    message.lastCallAt !== undefined && (obj.lastCallAt = Math.round(message.lastCallAt));
    message.leadId !== undefined && (obj.leadId = message.leadId);
    message.phone !== undefined && (obj.phone = message.phone);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerNotification_IncomingCall>, I>>(base?: I): DialerNotification_IncomingCall {
    return DialerNotification_IncomingCall.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerNotification_IncomingCall>, I>>(
    object: I,
  ): DialerNotification_IncomingCall {
    const message = createBaseDialerNotification_IncomingCall();
    message.lastCallAt = object.lastCallAt ?? undefined;
    message.leadId = object.leadId ?? undefined;
    message.phone = object.phone ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

// If you get a compile-error about 'Constructor<Long> and ... have no overlap',
// add '--ts_proto_opt=esModuleInterop=true' as a flag when calling 'protoc'.
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
